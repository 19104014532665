import LoadingButton from '@mui/lab/LoadingButton';

function ThemedLoadingButton({ children, ...rest }) {
    return (
        <LoadingButton color={'primary'} size="large" variant="contained" disableElevation {...rest} >
            {children}
        </LoadingButton>
    );
}

export default ThemedLoadingButton;