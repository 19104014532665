export const capitalise = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export const titleCase = (string) => {
    var splitStr = string.toLowerCase().split(' ');
    for (var i = 0; i < splitStr.length; i++) {
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].slice(1);
    }
    return splitStr.join(' '); 
}
