import Container from '@mui/material/Container';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';

const useStyles = makeStyles(theme => ({
    header: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
        fontSize:28
    },
    subheader: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        fontWeight:600,
        fontSize:'1.2rem'
    },
    tocLink:{
        textDecoration:'none',
        color:'blue'
    }
}));

function TermsAndConditions() {
    const theme = useTheme();
    const classes = useStyles(theme);
    return (
        <Container>
            <h4 className={classes.header}>Our Terms & Conditions</h4>
            <p className={classes.subheader}>Last updated April 12, 2023</p>
            <p className={classes.paragraph}>We are Zkootie("<strong>Company</strong>," "<strong>we</strong>," "<strong>us</strong>," or "<strong>our</strong>")</p>
            <p>We operate , as well as any other related products and services that refer or link to these legal terms (the "<strong>Legal Terms</strong>") (collectively, the "<strong>Services</strong>").</p>
            <p>You can contact us by email at contact@zkootie.com or by mail to Zkootie, 167-169 Great Portland Street, 5th Floor, London, London W1W 5PF, United Kingdom.</p>

            <p>These Legal Terms constitute a legally binding agreement made between you, whether personally or on behalf of an entity ("<strong>you</strong>"), and <strong>Zkootie</strong>, concerning your access to and use of the Services. You agree that by accessing the Services, you have read, understood, and agreed to be bound by all of these Legal Terms. IF YOU DO NOT AGREE WITH ALL OF THESE LEGAL TERMS, THEN YOU ARE EXPRESSLY PROHIBITED FROM USING THE SERVICES AND YOU MUST DISCONTINUE USE IMMEDIATELY. </p>
            <p>Supplemental terms and conditions or documents that may be posted on the Services from time to time are hereby expressly incorporated herein by reference. We reserve the right, in our sole discretion, to make changes or modifications to these Legal Terms at any time and for any reason. We will alert you about any changes by updating the "Last updated" date of these Legal Terms, and you waive any right to receive specific notice of each such change. It is your responsibility to periodically review these Legal Terms to stay informed of updates. You will be subject to, and will be deemed to have been made aware of and to have accepted, the changes in any revised Legal Terms by your continued use of the Services after the date such revised Legal Terms are posted.</p>
            <p>We recommend that you print a copy of these Legal Terms for your records. </p>

            <div><a data-custom-class="link" href="#contract" className={classes.tocLink}>1. ENTERING INTO A CONTRACT</a></div>
            <div><a data-custom-class="link" href="#your-responsibility" className={classes.tocLink}>2. YOUR RESPONSIBILITIES</a></div>
            <div><a data-custom-class="link" href="#charges-payments" className={classes.tocLink}>3. CHARGES & PAYMENT</a></div>
            <div><a data-custom-class="link" href="#ourlibel" className={classes.tocLink}>4. OUR LIABILITY TO YOU:</a></div>
            <div><a data-custom-class="link" href="#limitlibel" className={classes.tocLink}>5. HOW WE LIMIT OUR LIABILITY TO YOU</a></div>
            <div><a data-custom-class="link" href="#claim-comp" className={classes.tocLink}>6. HOW TO MAKE CLAIMS FOR COMPENSATION</a></div>
            <div><a data-custom-class="link" href="#lien" className={classes.tocLink}>7. RIGHT OF LIEN: OUR RIGHT TO HOLD ON TO YOUR PARCEL IF YOU OWE US MONEY</a></div>
            <div><a data-custom-class="link" href="#proof-of-delivery" className={classes.tocLink}>8. PROOF OF DELIVERY</a></div>
            <div><a data-custom-class="link" href="#terminate" className={classes.tocLink}>9. OUR RIGHT TO TERMINATE</a></div>
            <div><a data-custom-class="link" href="#eventsoutside" className={classes.tocLink}>10. EVENTS OUTSIDE OF OUR CONTROL</a></div>
            <div><a data-custom-class="link" href="#general" className={classes.tocLink}>11. GENERAL</a></div>

            <h4 className={classes.header} id='contract'>1. ENTERING INTO A CONTRACT</h4>
            <ul>
                <li>Your Order is an offer to purchase the Services from us. In making your Order, you agree to these terms and conditions.</li>
                <li>We will accept your Order if and when we start to perform the Services, at which point a contract will be formed between us, subject to these terms and conditions (the “<strong>Contract</strong>”).</li>
            </ul>

            <h4 className={classes.header} id='your-responsibility'>2. YOUR RESPONSIBILITIES</h4>
            <p>You must ensure that you don't:</p>
            <ul>
                <li>Send anything that is on the Prohibited Items list (see the “Goods that I can't send” section below)</li>
                <li>Send or try to send any Parcels that we do not accept for carriage</li>
                <li>Send any Goods which are illegal, the carriage of which is illegal, or the supply of such Goods to the Recipient is illegal.</li>
            </ul>
            <p>You must also:</p>
            <ul>
                <li>Ensure that the size and weight of your parcel matches the size and weight you declare the Parcel to be in your Order (for further detail please see our Parcel Size Guide which form part of these terms and conditions);</li>
                <li><strong>IMPORTANT:</strong> if we find that your Parcel is bigger and/or heavier, then we reserve the right to require you to pay Additional Expenses, being the correct Charges and an Administration Fee (please see Conditions 3.2, 3.3 and 3.4 below for more detail).</li>
                <li>Ensure that the item you wish to send is properly labelled with a Zkootie label showing the barcode. It must be well packaged and protected against knocks and bumps that it may encounter when travelling along our conveyor belts. Parcels must be packaged in accordance with our Packaging Guide.</li>
            </ul>


            <p className={classes.subheader}>Non-Compensated Items</p>
            <p>It is important that you check that the item you wish to send is not on our Non-Compensation Items list.</p>
            <p>These are items that we can deliver for you, but due to their nature, we can't guarantee that they will be Delivered undamaged or lost. This is because such “non-compensation items” might not survive the knocks and bumps they are likely to experience along their journey through our automated network.</p>
            <p><strong>We want to make it clear to you that we can't accept liability if such items are lost or damaged on the way.</strong> If you do choose to send your parcel with us, we strongly advise you to ensure they are thoroughly protected and packaged. <strong>You will not be entitled to compensation for any Non-Compensation items.</strong></p>

            <p className={classes.subheader}>Electrical or electronic equipment</p>
            <p>As we cannot test whether any electrical or electronic equipment that you provide to us in a Parcel is functioning properly before we deliver it for you, we cannot accept liability for any Damage or loss of data except where:</p>
            <ul>
                <li>you can show, to our reasonable satisfaction, that the Goods were fully functioning before they came into our physical possession; and</li>
                <li>such Damage to the Goods was caused by our negligence.</li>
            </ul>

            <p className={classes.subheader}>Goods that I can’t send</p>
            <p>It is extremely important that you ensure that you do not send anything which is on our Prohibited Items list.</p>
            <p><strong> If you do send something which is on this list, we will not accept any responsibility for any loss or damage to it, and we may require you to compensate us and any affected third parties if your parcel causes any damage, injury or loss. You will not be entitled to compensation for any Prohibited Items.</strong></p>
            <p>For further detail please see Conditions 4 and 5.</p>

            <h4 className={classes.header} id='charges-payments'>3. CHARGES & PAYMENT</h4>
            <ul>
                <li>You agree to pay the correct Charges before you submit your Order and it is your responsibility to ensure that you have paid the appropriate Charges for any Parcels that you supply to us.</li>
                <li>When we ship your Parcel, we will weigh and measure it. If, in your Order you have declared your Parcel to be lighter and/or smaller than it actually is, you will be liable to pay us the Additional Expenses and the Administration Fee.</li>
                <li>If you breach any of these terms and conditions (including without limitation the Parcel dimension and/or weight requirements) then we may charge you reasonable administration fees up to £10 (“Administration Fee”). Imposing the Administration Fee does not limit or restrict any other rights or remedies that we may have against you.</li>
                <li>Until you have paid the Additional Expenses and Administration Fee to us, we can retain possession of your Parcel and your Goods. If you fail to repay the Additional Expenses and/or Administration Fee to us within 14 days of our request for you to do so, we may dispose of the Goods.</li>
                <li>If you breach the Parcel dimension and/or weight requirements and we deliver the Parcel, we may impose the Administration Fee for arranging for the specialist delivery of the Parcel and up to £20 as an additional expense for the cost of delivery of the Parcel.</li>
            </ul>

            <p><strong>IMPORTANT:</strong> If you breach these terms and conditions, and such breach causes harm or damage to any person, our property or third-party property, or in the event that you are liable to pay us any Additional Expenses or Administration Fee, we reserve the right to require you to compensate us in full if we suffer any loss, damages, costs or expenses as a result.</p>
            <p>So, for example, if you send something which is on the Prohibited Items list and it hurts someone, or damages other people's parcels while on one of our vans, you will be required to compensate us and anyone affected for the damage caused. Other examples might include paint, which has leaked over other parcels, live animal feed (e.g. cockroaches) or flammable liquids which cause a fire and put our Parcel People at risk.</p>

            <h4 className={classes.header} id='ourlibel'>4. OUR LIABILITY TO YOU:</h4>
            <p>We do not accept liability for Loss or Damage or Late Delivery where:</p>
            <ul>
                <li>the Parcel involved is a Parcel that we do not accept for carriage</li>
                <li>the Goods or Parcels involved are Prohibited Items; and/or</li>
                <li>the Goods or Parcels involved are Non-Compensation Items.</li>
            </ul>

            <p>For any other Goods or Parcels we will only be liable to you for Loss or Damage or Late Delivery to the extent that it is caused by our negligence, in which case we will pay compensation to you subject to the limits set out in this Condition 4 and Condition 5 below (“<strong>Compensation</strong>”).</p>
            <ul>
                <li>The Compensation shall be the full extent of our liability to you for Loss or Damage or Late Delivery.</li>
                <li>We will not be liable to you nor pay you any Compensation for Loss or Damage that is caused by:</li>
                    <ul>
                        <li>any latent or inherent defect in or natural deterioration of the Goods;</li>
                        <li>our failure to honour "package orientation" graphics (e.g. "UP" arrows, or "THIS END UP" markings);</li>
                        <li>a Parcel being Inadequately Packaged;</li>
                        <li>any kind of fraudulence or dishonesty, including where someone misrepresents his authority to receive a Parcel on the Recipient's or your behalf;</li>
                        <li>any consequence of your sending alcoholic or other age-restricted products or potentially harmful or illegal products; or</li>
                        <li>your failure to pay the correct Charges.</li>
                    </ul>
            </ul>

            <p>We will not be liable to you nor pay you any Compensation for Late Delivery which is caused by any event which is outside our reasonable control, such as floods, fire, accident, strike, endemic or pandemic.</p>
            <p>We will also not be liable to you nor pay you any Compensation for Loss or Damage or Late Delivery if you have breached any of your responsibilities to us and that such breach has directly or indirectly caused or contributed to such Loss, Damage or Late Delivery.</p>
            <p>We provide a tracking facility to allow you to keep track of the Parcel. We do not guarantee the availability or accuracy of the tracking facility. The parcel tracking facility is provided on an “as is” basis and we can only make reasonable endeavours to ensure its accuracy and availability. You should therefore not rely on the tracking facility other than as a basic indication as to your Parcel’s location or progress.</p>
            <p>Nothing in these terms and conditions affects your statutory rights nor limits or excludes our liability for:</p>
            <ul>
                <li>death or personal injury resulting from our negligence;</li>
                <li>any damage or liability that you incur as a result of our fraud or fraudulent misrepresentation; or</li>
                <li>a breach of the Consumer Rights Act 2015 unless you are claiming for Business Loss.</li>
            </ul>


            <h4 className={classes.header} id='#limitlibel'>5. HOW WE LIMIT OUR LIABILITY TO YOU</h4>
            <p>Subject to Condition 5.5, unless you have taken out our Full Cover, the following limits apply to our liability to you under a Contract:</p>
            <p>Subject to Condition 5.5, our liability for each instance of Loss or Damage or anything else other than Late Delivery is limited to whichever is lesser of:</p>
            <ul>
                <li>£20; or</li>
                <li>the cost of repairing the damaged Goods, or the value of the lost or damaged Goods (as we shall reasonably determine up to a maximum of the price you paid or were paid for the Goods);</li>
            </ul>
            <p>and we will refund your Charges.</p>
            <p>Subject to Condition 5.5, our liability for Late Delivery is limited to refunding the Charges.</p>
            <p>Subject to Condition 5.5, when you submit an Order, you may take out our Full Cover. If you do so the limit of our liability to you for Loss or Damage will be varied to whichever is the lesser of:</p>
            <ul>
                <li>the amount of Full Cover you purchased; or</li>
                <li>the cost of repairing the damaged Goods, or the value of the lost or damaged Goods, (as we shall reasonably determine up to a maximum of the price you paid or were paid for the Goods);</li>
            </ul>
            <p>and we will refund your Charges.</p>
            <p>Where you submit an Order for a Postable Product, Zkootie liability for Loss or Damage or Late Delivery is limited to the Charges paid by you for the Postable Product. To avoid doubt, you will not be able to take out Full Cover for Parcels that are Postable Products.</p>
            <p>The amount of Full Cover which you have taken out for a Parcel, if at all, will be the extent of our liability to you for any Loss or Damage to your Parcel.</p>
            <p><strong>IT IS AT YOUR OWN RISK TO SEND ANY PARCEL THAT IS WORTH MORE THAN THE MAXIMUM LIMIT OF LIABILITY (SET OUT IN THIS CONDITION 5) AND TO CHOOSE THE CORRECT LEVEL OF COVER TO MATCH THE TOTAL VALUE OF YOUR ITEMS. SHOULD YOU FAIL TO DO SO YOU ACCEPT THE RISK THAT YOU MAY NOT RECEIVE THE FULL VALUE OF YOUR PARCEL OR GOODS IN THE EVENT THAT YOUR PARCEL IS LOST OR DAMAGED.</strong></p>




            <h4 className={classes.header} id='#claim-comp'>6. HOW TO MAKE CLAIMS FOR COMPENSATION</h4>
            <p>Unless you are using the Services in the capacity of a consumer, you must notify us in writing of any claim for Loss or Damage or Late Delivery within 28 days of the date of the relevant Order otherwise your claim will be rejected. As a consumer this clause 6.1 does not affect your statutory rights, but it is still important that you make your claim within this 28 day period as the sooner you make your claim, the easier it is for us to investigate.</p>
            <p>When we receive your claim for Compensation, we may require you to back up your claim by providing us with any relevant information about the relevant Parcel and or Goods including without limitation:</p>
            <ul>
                <li>proof of the Parcel's dispatch;</li>
                <li>photo evidence of any damage or other issues;</li>
                <li>estimates for the repair of the Goods;</li>
                <li>proof of the value of the Goods;</li>
                <li>the cost price of the Goods and related proof including receipts; and</li>
                <li>details of the weight, size, volume and nature of the Goods.</li>
            </ul>
            <p>We may make any investigations that we think are necessary to check out any claim.</p>
            <p>Any claim for Compensation must be made on a properly completed Claim Form which we will provide to you. You can contact us to make your claim by any method set out on our Contact Us page. You must return the properly completed Claim Form to us within 14 days of us sending it to you, otherwise we may reject your claim. For our consumer customers, this timeframe doesn’t apply but we would still encourage our consumer customers to submit the Claim Form within this period. Promptly submitted claims will increase the likelihood of a successful investigation and will help us avoid rejecting your claim.</p>


            <h4 className={classes.header} id='#lien'>7. RIGHT OF LIEN: OUR RIGHT TO HOLD ON TO YOUR PARCEL IF YOU OWE US MONEY</h4>
            <ul>
                <li>If you owe us money we will be entitled to hold onto your Parcel and Goods until you have paid us.</li>
                <li>If you still owe us any money more than 28 days from the date that we let you know that you owed us, we may at our discretion dispose of your Parcel and Goods and deduct from the amount that we receive in payment for them the amount that you owe us and the costs of the sale, after which we will let you have any balance remaining.</li>
            </ul>

            <h4 className={classes.header} id='#proof-of-delivery'>8. PROOF OF DELIVERY</h4>
            <p>You accept that the following records will be proof that a Parcel has been Delivered:</p>
            <ul>
                <li>the Recipient's signature (including HHT signature) for the Parcel if the Parcel has been Delivered to the Address;</li>
                <li>a Neighbour's signature (including HHT signature) for the Parcel if the Parcel has been Delivered to a Neighbour; or</li>
                <li>a Safe Place Photo;</li>
                <li>a drop card for the Parcel if the Parcel has been left in a Safe Place; or</li>
                <li>a safe place photo or courier “contactless” signature.</li>
            </ul>

            <h4 className={classes.header} id='#terminate'>9. OUR RIGHT TO TERMINATE</h4>
            <p>We may terminate a Contract and your Account as soon as we give notice to you and not owe you anything if:</p>
            <ul>
                <li>You breach any of these terms and conditions, for example, if we find that you have sent a Prohibited Item;</li>
                <li>we suspect you of fraud; or</li>
                <li>if you are abusive to any of our employees or contractors in any way.</li>
            </ul>

            <h4 className={classes.header} id='#eventsoutside'>10. EVENTS OUTSIDE OF OUR CONTROL</h4>
            <p>We will not be in breach of our obligations to you under a Contract or liable for any Loss or Damage or Late Delivery or anything else, if it is your fault, or results from something outside of our control, including (but not limited to), endemic, pandemic, bad weather, traffic congestion, mechanical breakdown, obstruction of public or private highways, or industrial action, or a failure by any of our suppliers.</p>

            <h4 className={classes.header} id='link'>11. GENERAL</h4>
            <ul>
                <li>We are not a common carrier and we will only provide the Services on these terms and conditions.</li>
                <li>When you submit your Order, you provide your personal information to us. The way we process this personal information is set out in our Privacy Policy. By providing this information to us you confirm that you are happy for us to process your personal information in this way.</li>
                <li>If you are not the owner of some or all of the Goods, you will be deemed to be the Agent of the owner of those Goods.</li>
                <li>We may subcontract the obligations that we owe to you. Our subcontractor will also be entitled to subcontract its obligations.</li>
                <li>If at any time we have a claim against you and do not pursue that claim quickly, that does not mean the claim cannot be pursued when we are ready.</li>
                <li>If a court says that part of a Contract is not enforceable in law that does not mean the rest of it is not.</li>
                <li>When you place an Order and we accept it, neither of us has relied on anything the other has said or done, i.e. the Contract says it all.</li>
                <li>If things go wrong and there is a dispute, English law will apply, and the English Courts will have exclusive jurisdiction over the dispute.</li>
                <li>Unless we agree in writing you cannot transfer your rights under a Contract to someone else.</li>
                <li>When these terms and conditions refer to a piece of legislation, they refer to the latest version of it in force.</li>
                <li>A Contract cannot be varied without our express written consent. Our subcontractors and Agents are not entitled to vary a Contract.</li>
                <li>Subject to Condition 11.13, by purchasing our Services you give us express consent to take Safe Place Photos and all rights in which will be owned by us. You also agree that any such Safe Place Photos may be used by us to prove that we made delivery to you. For the avoidance of doubt our Safe Place Photos will not feature images of identifiable people.</li>
                <li>We (or a subcontractor appointed by Zkootie) may obtain photographs of the person(s) receiving and sending Parcels at any parcel locker (or other Parcel collection and/or drop off location). The photographs may be used as evidence (in the event of a claim or dispute) that a Parcel was received (or sent) and/or to determine the Condition of a Parcel when it was received (or sent).</li>
            </ul>

        </Container>
    );
}

export default TermsAndConditions;
