import React, { useCallback, useContext, useEffect, useRef } from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import googleMapsStyles from './GoogleMapsStyles';
import { currentLocationLatLng, libraries } from '../../service/MapService';
import { GoogleMapViewContext } from '../../context/GoogleMapViewContext';

const center = {
    lat: 51.50075537764915,
    lng: -0.12462615966796875,
};
const mapContainerStyle = {
    width: '100%',
    height: '100%',
};

const options = {
    styles: googleMapsStyles,
    disableDefaultUI: true,
    zoomControl: true,
};

function CustomGoogleMapsContainer() {
    const mapRef = useRef();
    const { fromLatLng, setFromLatLng, toLatLng, setToLatLng, fromFieldFocussed, toFieldFocussed } =
        useContext(GoogleMapViewContext);

    const panTo = useCallback(({ lat, lng }) => {
        mapRef.current.panTo({ lat, lng });
        mapRef.current.setZoom(17);
    }, []);

    useEffect(() => {
        console.log(fromLatLng);
        if (toLatLng === {} && fromLatLng !== {} && fromLatLng !== null) {
            panTo(fromLatLng);
        }
    }, [fromLatLng]);

    useEffect(() => {
        console.log(toLatLng);
        if (fromLatLng === {} && toLatLng !== {} && toLatLng !== null) {
            panTo(fromLatLng);
        }
    }, [toLatLng]);

    // useCallback(()=>{
    //     if (zoomToMarker()){
    //         console.log("dddddddddddd")
    //         panTo({
    //             lat: 11,
    //             lng: 11,
    //         });
    //     }
    //
    //     if (zoomOutToIncludeBothMarkers()){
    //         console.log("xxxxxxxxxxx")
    //         panTo({
    //             lat: 22,
    //             lng: 22,
    //         });
    //     }
    //
    // }, [fromSelectedLocation, toSelectedLocation])

    // const zoomToMarker = () => {
    //     let status = true
    //     if ((!fromSelectedLocation && !toSelectedLocation) || (fromSelectedLocation && toSelectedLocation)) {
    //         status = false
    //     }
    //     return status
    // }
    //
    // const zoomOutToIncludeBothMarkers = () => {
    //     let status = false
    //     if (fromSelectedLocation && toSelectedLocation) {
    //         status = true
    //     }
    //     return status
    // }

    const onMapClick = useCallback(
        event => {
            // console.log(fromFieldFocussed);
            // console.log(toFieldFocussed);
            if (fromFieldFocussed) {
                setFromLatLng({ lat: event.latLng.lat(), lng: event.latLng.lng() });
            } else if (toFieldFocussed) {
                setToLatLng({ lat: event.latLng.lat(), lng: event.latLng.lng() });
            }
        },
        [fromFieldFocussed, toFieldFocussed],
    );

    const onMapLoad = useCallback(map => {
        mapRef.current = map;
        currentLocationLatLng().then(position => {
            panTo({
                lat: position.coords.latitude,
                lng: position.coords.longitude,
            });
        });
    }, []);

    return (
        <LoadScript libraries={libraries} googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
            <GoogleMap
                id="map"
                mapContainerStyle={mapContainerStyle}
                zoom={17}
                options={options}
                center={center}
                onLoad={onMapLoad}
                onClick={onMapClick}
            >
                {Object.keys(fromLatLng).length && <Marker position={{ lat: fromLatLng.lat, lng: fromLatLng.lng }} />}
                {Object.keys(toLatLng).length && <Marker position={{ lat: toLatLng.lat, lng: toLatLng.lng }} />}
            </GoogleMap>
        </LoadScript>
    );
}

export default CustomGoogleMapsContainer;
