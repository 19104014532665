import React, { useEffect } from 'react';
import Typography from '@mui/material/Typography';
import { useLocation } from 'react-router-dom';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';

const AboutUs = _ => {
    const { pathname, hash } = useLocation();

    useEffect(() => {
        if (!hash) window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <Container>
            <Typography
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '32px',
                    marginLeft: '5%',
                    marginRight: '5%',
                    textAlign: 'center',
                }}
                variant={'h4'}
            >
                Our mission is to accelerate the growth of businesses using sustainable transportation.
            </Typography>
            <Box sx={{ justifyContent: 'center', display: 'flex' }}>
                <Box
                    sx={{
                        width: '50px',
                        height: '2px',
                        backgroundColor: 'secondary.main',
                        marginTop: '32px',
                        marginBottom: '32px',
                    }}
                />
            </Box>

            <Typography lineHeight={1.5} align="justify">
                Online shopping continues to grow every year, a trend which has only accelerated by the COVID-19
                pandemic. While the increase in online sales is great for businesses it also presents new challenges.
                The major reason for shopping cart abandonment continues to be the issues related to{' '}
                <Link
                    href="https://www.barilliance.com/10-reasons-shopping-cart-abandonment"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    shipping
                </Link>
                . Customers don’t like to pay additional shipping costs but do still expect to receive their parcels
                within{' '}
                <Link
                    href="https://www.hollingsworthllc.com/how-late-deliveries-impact-customer-retention/"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    two days
                </Link>
                . The sellers have no choice but to either absorb the shipping costs; either by using external delivery
                providers or selling via leading online marketplaces and use their fulfilment services. Using third
                party courier services does not solve delivery speed or reliability issues as evidenced by the
                consistently low satisfaction ratings of various companies. This anxiety faced by customers has even
                been entered into a{' '}
                <Link
                    href="https://www.urbandictionary.com/define.php?term=pre-parcel%20anxiety"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    dictionary!
                </Link>{' '}
            </Typography>

            <Typography lineHeight={1.5} align="justify" sx={{ mt: '24px' }}>
                Zkootie is built to solve the issues of price, reliability, and speed. We use smart route planners to
                plot the most efficient delivery route to your end customer. This enables us to deliver parcels faster
                than other courier companies. We use state of the art technology in our mobile apps that means you and
                your customer can track the location of the parcel in real time and so that the parcel delivery anxiety
                goes away. Instead of cramming lots of parcels on one vehicle we use small groupings of parcels that are
                delivered in multiple slots by a number of delivery partners. This efficacy means that we can reduce our
                operating costs and pass the savings on to businesses.
            </Typography>
            {/* </div> */}
            <Box sx={{ justifyContent: 'center', display: 'flex' }}>
                <Box
                    sx={{
                        width: '50px',
                        height: '2px',
                        backgroundColor: 'secondary.main',
                        marginTop: '32px',
                        marginBottom: '32px',
                    }}
                />
            </Box>
        </Container>
    );
};
export default AboutUs;
