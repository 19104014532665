const parseURL = url => {
    let obj = {};
    let extractUrlArray = url.split('&');
    extractUrlArray.length > 0 &&
        extractUrlArray.forEach((value, index) => {
            let keys;
            let val;
            if (index === 0) {
                keys = value.split('?')[1].split('=')[0];
                val = value.split('?')[1].split('=')[1];
            } else {
                keys = value.split('=')[0];
                val = value.split('=')[1];
            }
            obj[keys] = val;
        });
    return obj;
};

export default parseURL;
