import React, { useContext, useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Alert from '@mui/material/Alert';
import { useHistory } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import { getApp } from 'firebase/app';
import { getFunctions, httpsCallable } from 'firebase/functions';

import ParcelPostcodeInputField from '../../../component/ParcelPostcodeInputField';
import { PackageContext } from '../../../context/PackageContext';
import { PaymentContext } from '../../../context/PaymentContext';
import { AuthContext } from '../../../context/AuthContext';
import { WEIGHT_OPTIONS,FUNCTION_STATUS,PACKAGE_OPTIONS } from '../../../AppConstants';
import ThemedLoadingButton from '../../../component/Buttons/ThemedLoadingButton';
import checkPostcodeFormat from '../../../utils/checkPostcodeFormat';
import firebaseConfig from '../../../firebaseConfig';

function SendHomeForm() {
    const history = useHistory();

    const [fromError, setFromError] = useState('');
    const [toError, setToError] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const app = getApp();
    const { region } = firebaseConfig;

    const { 
        fromSelectedPostcode,
        setFromSelectedPostcode,
        fromPostcodeBlur,
        setFromPostcodeBlur,
        toSelectedPostcode,
        setToSelectedPostcode,
        toPostcodeBlur,
        setToPostcodeBlur,
        weight,
        setWeight, 
        setPriceResult,
        setVolumeKey,
        setSelectedVolumeCost,
        setPickupDate,
        setPickupTime,
        setTotalPrice,
        setFromAddress,
        setToAddress,
    } = useContext(PackageContext);

    const { setParcelId } = useContext(PaymentContext);
    const { setParcelPaymentComplete } = useContext(AuthContext);


    useEffect(()=>{
        if (fromPostcodeBlur && fromSelectedPostcode !==''){
            if (!checkPostcodeFormat(fromSelectedPostcode)){
                setFromError('Invalid post code');
            }
        } else{
            setFromError('');
        }
    },[fromPostcodeBlur]);

    useEffect(()=>{
        if (toPostcodeBlur && toSelectedPostcode !==''){
            if (!checkPostcodeFormat(toSelectedPostcode)){
                setToError('Invalid post code');
            }
        } else{
            setToError('');
        }
    },[toPostcodeBlur]);

    
    const validateForm = () => {
        let status = true;
        if (fromSelectedPostcode === '') {
            setFromError('No pickup postcode provided');
            status = false;
        } else if(!checkPostcodeFormat(fromSelectedPostcode)){
            setFromError('Invalid post code');
            status=false;
        } else {
            setFromError('');
        }

        if (toSelectedPostcode === '') {
            setToError('No destination postcode provided');
            status = false;
        } else if(!checkPostcodeFormat(toSelectedPostcode)){
            setToError('Invalid post code');
            status=false;
        } else {
            setToError('');
        }
        return status;
    };

    const sendPackageHandler = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(false);
        if (validateForm()) {
            try{
                const functions = getFunctions(app,region);
                const getPrice = httpsCallable(functions, 'pricing-getPrice');
                const result = await getPrice({ 
                    from:fromSelectedPostcode, 
                    to:toSelectedPostcode, 
                    weight
                });     
                if (result?.data?.status===FUNCTION_STATUS.OK){
                    const {availablePackages,prices} = result.data;
                    const defaultVolumeKey=availablePackages[0];
                    setPriceResult(result.data);  
                    setVolumeKey(PACKAGE_OPTIONS[defaultVolumeKey].index);   
                    setSelectedVolumeCost(prices.volumeCharge[defaultVolumeKey]);  
                    // clear previous context values
                    setPickupDate('');
                    setPickupTime('');
                    setTotalPrice(0);
                    setFromAddress('');
                    setToAddress(''); 
                    setParcelId('');
                    setParcelPaymentComplete({parcelId:'',shouldAddUserToDB:false});
                    //clear flags
                    setLoading(false);
                    setError(false);

                    history.push('/send-package');
                } else {
                    setLoading(false);
                    setError(true);
                }
                

            } catch (error) {
                console.log(error);
                const {code, details, message}=error;
                switch(code){
                    case 'functions/not-found':
                        if (details.fromError) setFromError(message);
                        if (details.toError) setToError(message);
                        setError(false);
                        break;
                    default:
                        setError(true);
                        break;
                }
                setLoading(false);
            }
        } else {
            setLoading(false)
        }
    };

    return (
        <Stack
            direction="column"
            spacing={2}
            sx={{
                padding: {
                    xs: '32px 12px',
                    sm: '32px 12px',
                    md: '32px',
                    lg: '32px',
                    xl: '32px',
                },
            }}
        >
            <Stack order={'column'}>
                <FormControl fullWidth={true} variant="outlined">
                    <ParcelPostcodeInputField
                        label={'Pickup Postcode'}
                        errorText={fromError}
                        selectedPostcode={fromSelectedPostcode}
                        setSelectedPostcode={setFromSelectedPostcode}
                        setFieldBlur={setFromPostcodeBlur}
                    />
                </FormControl>
            </Stack>
            <Box sx={{ mt: '16px' }}>
                <FormControl fullWidth={true} variant="outlined">
                    <ParcelPostcodeInputField
                        label={'Destination Postcode'}
                        errorText={toError}
                        selectedPostcode={toSelectedPostcode}
                        setSelectedPostcode={setToSelectedPostcode}
                        setFieldBlur={setToPostcodeBlur}
                    />
                </FormControl>
            </Box>
            <Box
                sx={{
                    mt: '16px',
                    padding: '5px 0',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                }}
            >
                <Typography sx={{ userSelect: 'none', marginBottom: '12px' }}>Package Weight</Typography>
                <ButtonGroup size="small" fullWidth aria-label="large button group" sx={{ height: '56px' }}>
                    {WEIGHT_OPTIONS.map(data => {
                        return (
                            <Button
                                key={data.index}
                                color={'secondary'}
                                variant={'outlined'}
                                // color={weight === data.key ? "secondary" : "primary"}
                                sx={{
                                    backgroundColor: `${weight === data.index ? '#00BA4B' : '#FFFFFF'}`,
                                    '&:hover': {
                                        backgroundColor: `${weight === data.index ? '#00BA4B' : '#FFFFFF'}`,
                                    },
                                    color: `${weight === data.index ? '#FFFFFF' : '#000000'}`,
                                    fontWeight: `${weight === data.index ? '600' : '500'}`,
                                }}
                                onClick={() => setWeight(data.index)}
                            >
                                {data.key}
                            </Button>
                        );
                    })}
                </ButtonGroup>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    margin: '16px',
                }}
            >
                <ThemedLoadingButton 
                    onClick={sendPackageHandler}
                    loading={loading}
                >
                    Send now
                </ThemedLoadingButton>
            </Box>
            {error && <Alert onClose={() => setError(false)} severity="error">Something went wrong. Please try again.</Alert>}
        </Stack>
    );
}

export default SendHomeForm;
