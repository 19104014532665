import React from 'react';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';

import headerImage from '../../../images/about-business-header.jpg';

const useStyles = makeStyles(theme => ({
    headerTop: {
        backgroundImage: `url(${headerImage})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        minHeight: '60%',
        color: 'white',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        [theme.breakpoints.down('xs')]: {
            minHeight: '80%',
        },
    },
    overlay: {
        background: 'linear-gradient(to right, #512da8, #711e72)',
        position: 'absolute',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        opacity: 0.7,
        width: '100%',
        minHeight: '60%',
        [theme.breakpoints.down('xs')]: {
            minHeight: '80%',
        },
    },
    headerTitle: {
        // fontSize: 48,
        fontWeight: 500,
        display: 'flex',
        textAlign: 'center',
        marginLeft: theme.spacing(4),
        marginRight: theme.spacing(4),
        marginBottom: theme.spacing(2),
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            // fontSize: 40,
            marginLeft: theme.spacing(2),
            marginRight: theme.spacing(2),
            marginTop: theme.spacing(3),
        },
        [theme.breakpoints.down('sm')]: {
            // fontSize: 34,
            marginLeft: theme.spacing(2),
            marginRight: theme.spacing(2),
            marginTop: theme.spacing(3),
        },
    },
    headerText: {
        marginLeft: '15%',
        marginRight: '15%',
        marginBottom: theme.spacing(3),
        fontSize: 18,
        color: 'white',
        marginTop: theme.spacing(1),
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10%',
            marginRight: '10%',
        },
        [theme.breakpoints.down('xs')]: {
            marginLeft: '5%',
            marginRight: '5%',
        },
    },
}));

const TopHeading = () =>{
    const classes = useStyles();
    return(
        <section className={classes.headerTop}>
            <div className={classes.overlay}></div>
            <div style={{ position: 'absolute', width: '100%' }}>
                <Typography variant="h3" className={classes.headerTitle}>
                    Grow your business with us
                </Typography>
                <Typography
                    align="center"
                    color="inherit"
                    display="block"
                    variant=""
                    className={classes.headerText}
                >
                    Don't let misplaced, damaged or late deliveries impact
                    your sales
                </Typography>
            </div>
        </section>

        
        
    )
}

export default TopHeading;