import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

import AccodionFAQ from "../../component/AccodionFAQ";

const useStyles = makeStyles(theme => ({
    accordionWrapper: {
        backgroundColor: theme.palette.grey['100'],
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(5),
        // paddingLeft: '15%',
        // paddingRight: '15%',
    },
}));

const AboutFAQ =() =>{
    const classes = useStyles();
    return(
        <div className={classes.accordionWrapper}>
            <Container>
                <Typography variant='h5' sx={{textAlign:'center'}}>Help with everything else</Typography>
                <AccodionFAQ
                    question={'My account is locked, how can I unlock it?'}
                    answer={`Please go to the login page and select forgot password from the account options`}
                />

                <AccodionFAQ
                    question={'How do I add or remove my social media accounts'}
                    answer={`Before adding or removing your social media accounts, you will need to login first. You can then go to
                    account settings and link your social media account`}
                />


            </Container>
        </div>
    )
}

export default AboutFAQ;
