import React, { useContext, useReducer } from 'react';
import Stack from '@mui/material/Stack';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import { useHistory } from 'react-router-dom';

import ThemedLoadingButton from '../../../component/Buttons/ThemedLoadingButton';
import { TrackParcelContext } from '../../../context/TrackParcelContext';

function TrackHomeForm() {
    const history = useHistory();
    const [{ loading, error, errorMessgae}, setState] = useReducer((prev, next) => ({ ...prev, ...next }), {
        loading : false,
        error:  false,
        errorMessgae: ''
    });

    const { 
        parcelId,
        setParcelId,
        trackParcel,
    } = useContext(TrackParcelContext);

    const trackPackageHandler = async () => {
        // e.preventDefault();
        try{
            setState({loading:true, error:false, errorMessgae: ''});
            if (parcelId === null || parcelId.trim() === '') {
                setState({loading:false,error:true, errorMessgae:'Parcel number can not be left blank'});
                setParcelId('');
            } else{
                const res = await trackParcel(parcelId);
                if (res?.status !== 'OK'){
                    setState({
                        loading:false,
                        error:res?.error, 
                        errorMessgae:res?.message
                    });
                } else{
                    setState({loading:false,error:false, errorMessgae:''});
                    history.push('/track');
                }
            }
        } catch(error){
            setState({loading:false,error:true, errorMessgae:'Oh Snap! Something went wrong. Please try again.'});
        }
    }
    
    return (
        <Stack
            direction="column"
            spacing={2}
            sx={{
                padding: {
                    xs: '32px 12px',
                    sm: '32px 12px',
                    md: '41px',
                    lg: '41px',
                    xl: '41px',
                },
            }}
        >
            <FormControl sx={{ m: 1 }} variant="outlined">
                <TextField
                    label={'Parcel number'}
                    required
                    fullWidth={true} 
                    variant="outlined"
                    id="tracking-parcel-id" 
                    value={parcelId}
                    onChange={(e)=>setParcelId(e.target.value)}
                />
            </FormControl>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    margin: '16px',
                }}
            >
                <ThemedLoadingButton 
                    onClick={trackPackageHandler}
                    loading={loading}
                >
                    Find now
                </ThemedLoadingButton>
            </Box>
            {error && <Alert onClose={() => setState({error:false, errorMessgae:''})} severity="error">{errorMessgae}</Alert>}
        </Stack>
    );
}

export default TrackHomeForm;
