import { useState } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';
import Check from '@mui/icons-material/Check';
import { styled } from '@mui/material/styles';

import { ORDER_HISTORY_BUTTONS } from "../../AppConstants";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
  },
}));

const ParcelAgeSelectionButtons = ({selectedAge,getDataForAge}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [showAgeSelection, setShowAgeSelection] = useState(false);
    const open = Boolean(anchorEl);

    const toggleAgeSelection = e => {
      e.preventDefault();
      setShowAgeSelection(!showAgeSelection);
      setAnchorEl(e.currentTarget);
    };
    const handleClose = () => {
      setShowAgeSelection(!showAgeSelection);
      setAnchorEl(null);
    };

  return (
    <>
    <Button
        variant="contained"
        color="primary"
        onClick={toggleAgeSelection}
        endIcon={showAgeSelection? <KeyboardArrowUp/> : <KeyboardArrowDown/>}
        id="menu-button"
        aria-controls={open ? 'order-age-selection' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
    >
        Past
    </Button>
    <StyledMenu 
        id="order-age-selection"
        MenuListProps={{
            'aria-labelledby': 'menu-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
    >
        {ORDER_HISTORY_BUTTONS.map(histButtons => (
            <MenuItem key={histButtons.index} onClick={() => getDataForAge(histButtons.period)}>
                {selectedAge === histButtons.period ? <Check/>: ''} {histButtons.text}
            </MenuItem>
        ))}
    </StyledMenu>
    </>
  );
}
export default ParcelAgeSelectionButtons;