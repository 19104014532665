import { useContext, useState } from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';

import { PackageContext } from '../../context/PackageContext';
import { PaymentContext } from '../../context/PaymentContext';
import ParcelDetailsTab from './ParcelDetailsTab';
import ParcelAdditionalDetailsTab from './ParcelAdditionalDetailsTab';
import ParcelPayment from './ParcelPayment';
import CustomCard from '../../component/CustomCard';
import { PACKAGE_OPTIONS, WEIGHT_OPTIONS } from '../../AppConstants';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Box
            sx={{
                height: 'auto',
                width: '100%',
            }}
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Box>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function SendPackage() {
    const [value, setValue] = useState(0);
    const { fromAddress, toAddress, weight, volumeKey, selectedDeliveryType, pickupDate, pickupTime, priceResult, setTotalPrice,
        fromName, fromContactEmail, toName } = useContext(PackageContext);
    const theme = useTheme();
    const {volumeCharge, deliveryType, pickupTimes, pickupTimesCharge, currencySymbol}=priceResult.prices;
    const priceIndex = pickupTimes.indexOf(pickupTime) <0 ? 0 : pickupTimes.indexOf(pickupTime);
    const totalPrice = parseFloat(volumeCharge[volumeKey]) + parseFloat(deliveryType[selectedDeliveryType])
                       + parseFloat(pickupTimesCharge[priceIndex]);
    totalPrice.toFixed(2);

    const { parcelId } = useContext(PaymentContext);

    const allTab1DataPresent = volumeKey>=0 && selectedDeliveryType.trim() !=='' && pickupDate.trim() !== '' && pickupTime.trim() !=='' && fromAddress.trim() !=='' && toAddress.trim() !=='';
    const allTab2DataPresent = allTab1DataPresent && fromName.trim() !=='' && fromContactEmail.trim() !== '' && toName.trim() !=='' ;
    
    const handleChange = (newValue) => {
        setValue(newValue);
    };
    return (
        <>
            <Container sx={{ pt: '16px', width: '100%' }} disableGutters>
                <Grid container spacing={4} style={{ marginBottom: '64px' }}>
                    <Grid item xs={12} sm={12} md={8} lg={8} style={{ height: 'auto', width: '100%' }}>
                        {/*<CustomCard elevation={1}>*/}
                        <Tabs
                            style={{ height: 'auto', width: '100%' }}
                            value={value}
                            onChange={(e,value)=>handleChange(value)}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="fullWidth"
                            aria-label="full width tabs"
                        >
                            <Tab label={<Typography fontWeight={600}>Parcel Details</Typography>} {...a11yProps(0)} disabled={parcelId.trim() !==''}/>
                            <Tab label={<Typography fontWeight={600}>Additional Information</Typography>} {...a11yProps(1)} disabled={!allTab1DataPresent || parcelId.trim() !==''} />
                            <Tab label={<Typography fontWeight={600}>Payment</Typography>} disabled={!allTab2DataPresent || parcelId.trim() !==''} {...a11yProps(2)}
                            />
                        </Tabs>
                        <TabPanel value={value} index={0} dir={theme.direction}>
                            <ParcelDetailsTab />
                        </TabPanel>
                        <TabPanel value={value} index={1} dir={theme.direction}>
                            <ParcelAdditionalDetailsTab />
                        </TabPanel>
                        <TabPanel value={value} index={2} dir={theme.direction}>
                            <ParcelPayment />
                        </TabPanel>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <CustomCard
                            sx={{
                                position: 'sticky',
                                top: '80px',
                                margin: {
                                    xs: '32px',
                                    xm: '32px',
                                    md: '32px',
                                    lg: '0',
                                },
                            }}
                            elevation={3}
                        >
                            <Typography variant={'h6'} sx={{ fontWeight: 600 }}>
                                Your Parcel Information
                            </Typography>
                            <Divider sx={{ marginBottom: '12px' }} />

                            <Typography fontWeight={600}>From</Typography>
                                <Typography marginBottom={'12px'}>{fromAddress.trim() === '' ? 'No selection made' : fromAddress}</Typography>
                            <Typography fontWeight={600}>To</Typography>
                                <Typography marginBottom={'12px'}>{toAddress.trim() === '' ? 'No selection made' : toAddress}</Typography>
                            <Typography fontWeight={600}>Weight</Typography>
                                <Typography marginBottom={'12px'}>{`${WEIGHT_OPTIONS.filter(elm=>elm.index===weight)[0].key}`}</Typography>
                            <Typography fontWeight={600}>Dimensions</Typography>
                                <Typography marginBottom={'2px'}>{`${PACKAGE_OPTIONS.filter(elm=>elm.index===volumeKey)[0].key}`}</Typography>
                                <Typography marginBottom={'12px'}>{`${PACKAGE_OPTIONS.filter(elm=>elm.index===volumeKey)[0].dimension}`}</Typography>
                            <Typography fontWeight={600}>Delivery Type</Typography>
                                <Typography marginBottom={'12px'}>{ selectedDeliveryType ==='standard' ? 'Standard' : 'Peace of mind'}</Typography>
                            <Typography fontWeight={600}>Pickup Time</Typography>
                                <Typography marginBottom={'2px'}>{pickupDate.trim() ==='' ? 'Not pickup date selected' : pickupDate}</Typography>
                                <Typography marginBottom={'12px'}>{pickupTime.trim() ==='' ? 'Not pickup time selected' : pickupTime}</Typography>
                           
                            <Typography fontWeight={600}>Total Cost</Typography>
                            <Typography marginBottom={'12px'}>{`${currencySymbol}${totalPrice}`}</Typography>
                            <Button
                                variant='contained'
                                color='secondary'
                                onClick={() => {
                                    setTotalPrice(totalPrice);
                                    window.scrollTo(0, 0);
                                    if (value<=1) handleChange(value+1); 
                                }}
                                fullWidth
                                disabled={(value === 0 ? !allTab1DataPresent : !allTab2DataPresent)|| parcelId.trim()!==''}
                            >
                                {value === 0 ? `Continue with ${currencySymbol}${totalPrice}` : `Pay ${currencySymbol}${totalPrice}`}
                            </Button>
                        </CustomCard>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
}

export default SendPackage;
