//TODO: Cleanup required as per MUI 5
import React, {useEffect} from 'react';

import Container from '@mui/material/Container';
import CustomCard from "../../../component/CustomCard";
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';

import { LazyLoadImage } from 'react-lazy-load-image-component';
import {makeStyles} from '@mui/styles';
import {useLocation} from 'react-router-dom';

import { useTheme } from '@mui/material/styles';
// import clsx from 'clsx';

import EarnMoneyDrivingFAQ from "./EarnMoneyDrivingFAQ";
import EarnMoneyDropOffFAQ from "./EarnMoneyDropOffFAQ";
// import zkootieMoney from "../../../images/zkootie_money_900_x_760.jpg";
import zkootieMoney from "../../../images/zkootie_money_1500_x_1000.jpg";
import zkootieCollectMoney from "../../../images/about_earn_header_1500_x_1000.jpg"

const useStyles = makeStyles(theme => ({
    media: {
        maxWidth: '100%',
        maxHeight: '100%',
    },
}));

const AboutEarnMoney = props => {
    const classes = useStyles(props);
    const theme = useTheme();
    const isSm = useMediaQuery(theme.breakpoints.down('sm'));
    const { pathname, hash } = useLocation();
    const dropOffRef = React.useRef();
    const earnByDrivingRef = React.useRef();
    console.log(hash)

    useEffect(() => {
        // if (!hash) window.scrollTo(0, 0);
        if (hash === '#drop-off') window.scrollTo(0, dropOffRef.current.offsetTop)
        else if (hash === '#earn-by-driving') window.scrollTo(0, earnByDrivingRef.current.offsetTop)
    }, [pathname]);

    return (
        <section>
            <Container>
                <Grid container spacing={2}  sx={{ marginBottom: {xs:'8px',sm:'8px',md:'16px',lg:'32px'}, alignItems:'center' }}
                    id="earn-by-driving"
                    ref={earnByDrivingRef}
                >
                    <Grid item xs={12} sm={12} md={6} lg={6} >
                        <CustomCard>
                            <Typography variant={!isSm ? 'h4' : 'h4'} sx={{ marginBottom: {xs:'8px',sm:'8px',md:'16px',lg:'32px'} }}>
                                Earn money by driving for us
                            </Typography>
                            <Typography  sx={{ marginBottom: {xs:'8px',sm:'8px',md:'16px',lg:'32px'} }}>
                                Easy registration, deliver as little or as much as you want.
                            </Typography>
                        </CustomCard>
                        <EarnMoneyDrivingFAQ />
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <CustomCard>
                            <LazyLoadImage
                                effect="blur"
                                src={zkootieMoney}
                                className={classes.media}
                                alt={'Driving FAQ'}
                            />
                        </CustomCard>
                    </Grid>
                </Grid>

                <Grid container spacing={2}
                    sx={{
                        marginBottom: {xs:'8px',sm:'8px',md:'16px',lg:'32px'},
                        flexDirection:{ xs:'column-reverse', sm:'column-reverse', md:'row' },
                        alignItems:'center'
                    }}
                    id="drop-off"
                    ref={dropOffRef}
                >
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <CustomCard>
                            <LazyLoadImage
                                effect="blur"
                                src={zkootieCollectMoney}
                                className={classes.media}
                                alt={'Drop Off FAQ'}
                            />
                        </CustomCard>
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={6} >
                        <CustomCard>
                            <Typography variant={!isSm ? 'h4' : 'h4'} sx={{ marginBottom: {xs:'8px',sm:'8px',md:'16px',lg:'32px'} }}>
                                Become a drop off and collection point
                            </Typography>
                            <Typography  sx={{ marginBottom: {xs:'8px',sm:'8px',md:'16px',lg:'32px'} }}>
                                Open to commercial and residential properties, fast payments.
                            </Typography>
                        </CustomCard>
                        <EarnMoneyDropOffFAQ />
                    </Grid>
                </Grid>

            </Container>
        </section>
    );
};
export default AboutEarnMoney;
