import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';

import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import YouTubeIcon from '@mui/icons-material/YouTube';
import InstagramIcon from '@mui/icons-material/Instagram';

import GetItOnGooglePlay from '../../images/app-store-google.svg';
import DownloadFromAppStore from '../../images/app-store-apple.svg';
import { useHistory } from 'react-router-dom';

function ThemedLink({ children, ...rest }) {
    return (
        <Link href="" underline="none" color={'secondary.contrastText'} {...rest}>
            {children}
        </Link>
    );
}

function Footer() {
    const history = useHistory();

    return (
        <Box sx={{ backgroundColor: 'primary.main', color: 'white' }}>
            <Container sx={{ paddingTop: '64px' }}>
                <Box sx={{ display: 'flex' }}>
                    <Typography variant={'h5'}>
                        Zkootie
                    </Typography>
                </Box>
                <Box sx={{ display: 'flex', paddingTop: '30px' }}>
                    <Typography>
                        <ThemedLink onClick={() => history.push('/help')}>Visit Help Center</ThemedLink>
                    </Typography>
                </Box>

                <Box
                    sx={{
                        display: 'grid',
                        gridTemplateColumns: '1fr 1fr',
                        gridAutoFlow: 'row',
                        gridGap: '36px',
                        paddingTop: '64px',
                    }}
                >
                    <Box>
                        <Typography variant={'h6'} mb={'20px'}>
                            Company
                        </Typography>
                        <Typography mb={'16px'}>
                            <ThemedLink onClick={() => history.push('/about/us')}>About us</ThemedLink>
                        </Typography>
                        {/* <Typography mb={'16px'}>
                            <ThemedLink>Careers</ThemedLink>
                        </Typography> */}
                        <Typography mb={'16px'}>
                            <ThemedLink onClick={() => history.push('/terms')}>Terms & conditions</ThemedLink>
                        </Typography>
                        <Typography mb={'16px'}>
                            <ThemedLink onClick={() => history.push('/privacy')}>Privacy Policy</ThemedLink>
                        </Typography>
                    </Box>

                    <Box>
                        <Typography variant={'h6'} mb={'20px'}>
                            Products
                        </Typography>
                        <Typography mb={'16px'}>
                            <ThemedLink href='/about/business'>Ship with us</ThemedLink>
                        </Typography>
                        <Typography mb={'16px'}>
                            <ThemedLink href='/about/earn#drop-off'>Become a drop off & Collection point</ThemedLink>                    
                        </Typography>
                        <Typography mb={'16px'}>
                            <ThemedLink href='/about/earn#earn-by-driving'>Drive with us</ThemedLink>
                        </Typography>
                    </Box>
                </Box>

                <Box
                    sx={{
                        marginTop: '64px',
                        display: 'flex',
                        flexDirection: {
                            xs: 'column',
                            sm: 'column',
                            md: 'row',
                            lg: 'row',
                            xl: 'row',
                        },
                        alignContent: 'center',
                        justifyContent: {
                            xs: 'flex-start',
                            sm: 'flex-start',
                            md: 'space-between',
                            lg: 'space-between',
                            xl: 'space-between',
                        },
                    }}
                >
                    <Box>
                        <FacebookIcon
                            style={{ cursor: 'pointer' }}
                            aria-label={'Facebook'}
                            onClick={() => window.open('http://www.facebook.com')}
                            sx={{ marginBottom: '12px', marginRight: '48px' }}
                        />
                        <TwitterIcon
                            style={{ cursor: 'pointer' }}
                            aria-label={'Twitter'}
                            onClick={() => window.open('http://www.facebook.com')}
                            sx={{ marginBottom: '12px', marginRight: '48px' }}
                        />
                        <YouTubeIcon
                            style={{ cursor: 'pointer' }}
                            aria-label={'Youtube'}
                            onClick={() => window.open('http://www.facebook.com')}
                            sx={{ marginBottom: '12px', marginRight: '48px' }}
                        />
                        <InstagramIcon
                            style={{ cursor: 'pointer' }}
                            aria-label={'Instagram'}
                            onClick={() => window.open('http://www.facebook.com')}
                            sx={{ marginBottom: '12px', marginRight: '48px' }}
                        />
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'start',
                            height: '40px',
                            paddingTop: {
                                xs: '24px',
                                sm: '24px',
                                md: '0',
                                lg: '0',
                                xl: '0',
                            },
                        }}
                    >
                        <Card sx={{ maxWidth: '120', mr: '16px', backgroundColor: 'primary.main' }}>
                            <CardMedia
                                component={'img'}
                                alt={'Get it on Google Play'}
                                image={GetItOnGooglePlay}
                                height={'40'}
                                sx={{}}
                            />
                        </Card>
                        <Card sx={{ maxWidth: '120', backgroundColor: 'primary.main' }}>
                            <CardMedia
                                component={'img'}
                                alt={'Download from App store'}
                                image={DownloadFromAppStore}
                                height={'40'}
                            />
                        </Card>
                    </Box>
                </Box>
                <Box sx={{ paddingTop: '64px' }}></Box>
            </Container>
        </Box>
    );
}

export default Footer;
