import { useContext, useEffect, useReducer } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Link from '@mui/material/Link';
// import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import { checkValidEmail } from '../../utils';
import { NavContext } from '../../context/NavContext';
import { AuthContext } from '../../context/AuthContext';

function LoginForm({ switchToSignupForm, switchToForgotPasswordForm }) {
    const { setLoginModalOpen } = useContext(NavContext);
    const { loginWithEmailAndPassword, isAuthInProgress, isSocialAuthInProgress, authError, setAuthError, user } =
        useContext(AuthContext);

    const [{ emailOrPhone, password, rememberMe, errors , showPassword }, setState] = useReducer(
        (prev, next) => ({ ...prev, ...next }),
        {
            emailOrPhone: '',
            password: '',
            rememberMe: false,
            errors: {
                emailOrPhone:{isValid:true},
                password: '',
            },
        },
    );

    useEffect(() => {
        if (user !== null) {
            modalClose();
        }
    }, [user]);

    useEffect(() => {
        setAuthError({error: '', message: ''})
    }, []);

    const modalClose = () => {
        setLoginModalOpen(false);
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        const emailPhoneValidation = checkValidEmail(emailOrPhone);
        // only allow email signups
        if (emailPhoneValidation?.type !== 'email'){
            emailPhoneValidation.message = 'Invalid email address';
            emailPhoneValidation.isValid = false;
        }
        setState({
            errors: {
                emailOrPhone: emailPhoneValidation,
                // password: passwordValidation,
            },
        });

        if (emailOrPhone.trim() === '' || password.trim() === '') {
            setAuthError({ error: '', message: 'None of the fields can be empty' });
        } else if (emailPhoneValidation.isValid) {
            await loginWithEmailAndPassword(emailOrPhone, password, rememberMe);
        }
        // if (authError.message === '' && emailPhoneValidation.isValid) {
        //     modalClose();
        // }
    };

    const changeHandler = e => {
        if (e.target.name === 'rememberMe') {
            setState({ [e.target.name]: e.target.checked });
        } else {
            setState({ [e.target.name]: e.target.value });
        }
    };

    useEffect(() => {
        return () => {
            setAuthError({ error: '', message: '' });
        };
    }, []);

    return (
        <form>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    paddingBottom:{
                        xs:0,
                    }
                }}
            >
                <Typography>Sign in manually</Typography>
            </Box>
            <div>
                <TextField
                    margin="normal"
                    autoComplete={'email'}
                    required
                    fullWidth
                    name={'emailOrPhone'}
                    onChange={changeHandler}
                    error={!errors.emailOrPhone?.isValid || !!authError?.message}
                    variant="outlined"
                    label="Email Address"
                    helperText={!errors.emailOrPhone?.isValid 
                        ? errors.emailOrPhone?.message 
                        : authError?.message ? authError.message : ''//'Only include letters (a-z A-Z), numbers (0-9), underscore (_), dot (.) and one @ symbol'
                    }
                />
            </div>
            <div>
                <TextField
                    margin="normal"
                    autoComplete={'password'}
                    required
                    fullWidth
                    onChange={changeHandler}
                    type={showPassword ? 'text' : 'password'}
                    name={'password'}
                    variant="outlined"
                    // error={!!authError?.message}
                    label="Password"
                    InputProps={{
                        endAdornment:(
                                <InputAdornment position="end" sx={{bgcolor: 'background.default'}}>
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={()=>setState({showPassword:!showPassword})}
                                        onMouseDown={()=>setState({showPassword:!showPassword})}
                                        edge="end"
                                        >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                        )
                    }}
                />
            </div>
            <div>
                <FormControlLabel
                    control={
                        <Checkbox
                            color="default"
                            name="rememberMe"
                            onChange={changeHandler}
                            sx={{
                                color: 'secondary.main',
                            }}
                        />
                    }
                    label="Remember me"
                />
            </div>
            <Button
                margin="normal"
                variant="contained"
                size={'large'}
                color={'primary'}
                type={'submit'}
                onClick={onSubmit}
                disabled={isAuthInProgress || isSocialAuthInProgress !== ''}
                sx={{
                    mt: 2,
                }}
            >
                Sign In{' '}
                {isAuthInProgress && (
                    <Box
                        sx={{
                            paddingLeft: '16px',
                            display: 'flex',
                            justifyContent: 'middle',
                        }}
                    >
                        <CircularProgress size={'1.2rem'} color={'secondary'} />
                    </Box>
                )}
            </Button>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection:'row',
                    variant:"body2",
                    mb:2,
                    mt:2,
                    justifyContent: 'space-between',
                }}
            >
                <Typography sx={{display:'flex',flex:1}}>
                    <Link
                        href={'#'}
                        variant="button"
                        onClick={e => {
                            e.preventDefault();
                            switchToForgotPasswordForm();
                        }}
                    >
                        Forgot password?
                    </Link>
                </Typography>
                <Typography sx={{display:'flex',flex:1}}>
                    <Link
                        href={'#'}
                        variant="button"
                        onClick={e => {
                            e.preventDefault();
                            switchToSignupForm();
                        }}
                    >
                        New to Zkootie? Create an account
                    </Link>
                </Typography>
            </Box>
        </form>
    );
}

export default LoginForm;
