import React from 'react';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';

const useStyles = makeStyles(theme => ({
    iconContent: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        flex: 1,
    },
    iconContentIconFill: {
        height: 68,
        width: 68,
        borderRadius: 34,
        backgroundColor: 'white',
        color: theme.palette.secondary.main,
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        // boxShadow: '0 0 1.25rem rgba(108, 118, 134, 0.1)',
        boxShadow:'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '&:hover': {
            backgroundColor: theme.palette.secondary.main,
            color: 'white',
        },
        // marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            // marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
    },
    iconContentIcon: {
        fontSize: 20,
        [theme.breakpoints.down('md')]: {
            fontSize: 18,
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: 16,
        },
    },
    iconContentText: {
        // textAlign:'justify'
        // marginLeft: theme.spacing(4),
        // marginRight: theme.spacing(4),
        marginBottom: theme.spacing(4),
        [theme.breakpoints.down('sm')]: {
            // marginLeft: theme.spacing(2),
            // marginRight: theme.spacing(2),
            marginBottom: theme.spacing(2),
        },
    },
}));
const BusinessDetailsIcons = (props) =>{
    const classes = useStyles();
    const {IconName, heading, body } = props;
    return(
        <div className={classes.iconContent}>
            <div className={classes.iconContentIconFill}>
                <IconName className={classes.iconContentIcon} />
            </div>
            <Typography
                align="center"
                color="textPrimary"
                variant="h6"
                // className={classes.iconContentHeaderText}
            >
                {heading}
            </Typography>
            <p className={classes.iconContentText}>
                {body}
            </p>
            
        </div>
    )
}

export default BusinessDetailsIcons;