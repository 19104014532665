const firebaseCodeToMessage = code => {
    switch (code) {
        case 'auth/user-not-found':
            return 'No account found for this email';
        case 'auth/wrong-password':
            return 'Password provided was incorrect';
        case 'auth/invalid-email':
            return 'Invalid email address';
        case 'auth/popup-closed-by-user':
            return 'Window was closed by user before authentication happened';
        case 'auth/provider-already-linked':
            return 'Account already added';
        case 'auth/email-already-in-use':
            return 'Email already in use';
        case 'auth/credential-already-in-use':
            return 'Account is in use';
        case 'auth/weak-password':
            return 'Weak Password. Password should contain more than 8 characters with a mix of upper and lowercase letters, numbers and special characters.'
        case 'auth/requires-recent-login':
            return 'Your session has expired. Please logout and login again to update password.';
        default:
            return 'Something went wrong please try again';
    }
};

export default firebaseCodeToMessage;
