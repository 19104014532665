import { useContext, useState, useRef } from 'react';
import Alert from '@mui/material/Alert';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import LinearProgress from '@mui/material/LinearProgress';
import Modal from '@mui/material/Modal';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { getApp } from 'firebase/app';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import ReactCrop, { Crop, centerCrop, makeAspectCrop } from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import imageCompression from 'browser-image-compression';

import { AuthContext } from "../../context/AuthContext";
import ThemedLoadingButton from "../Buttons/ThemedLoadingButton";

const ProfilePicture = () => {
    
    const { user, userAttributes, updateUserProfilePic } = useContext(AuthContext);
    const [openModal, setOpenModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [progress, setProgress] = useState(0);
    const [uploadSuccess, setUploadSuccess] = useState(false);
    const [crop, setCrop] = useState(Crop);
    const [completedCrop, setCompletedCrop] = useState(null);
    const [imgSrc, setImgSrc] = useState('');
    const inputFileRef = useRef();
    const imgRef = useRef();
    const photoURL = userAttributes?.profile?.photoURL;
    const displayName = userAttributes?.profile?.displayName;
    // const { photoURL, displayName } = userAttributes?.profile;
    var name = displayName || 'Z';
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.down('sm'));
    const app = getApp();

    const onSelectFile = (e) => {
        setError(false);
        if (e.target.files && e.target.files.length > 0) {
            setOpenModal(true)
            setCrop(undefined) // Makes crop preview update between images.
            const reader = new FileReader()
            reader.addEventListener('load', () =>
            setImgSrc(reader.result?.toString() || ''),
          )
          reader.readAsDataURL(e.target.files[0]);
        //   setImageUrl(e.target.files[0])
        }
    }
    const onModalClose = () => {
        setOpenModal(false);
        inputFileRef.current.value = ''
    }
    const onImageLoad = (e) => {
        const { width, height } = e.currentTarget;
        setCrop(centerAspectCrop(width, height, 16/9)) 
    }
    const centerAspectCrop = (mediaWidth, mediaHeight, aspect) => {
        return centerCrop(
          makeAspectCrop(
            {
              unit: '%',
              width: 90,
            },
            aspect,
            mediaWidth,
            mediaHeight,
          ),
          mediaWidth,
          mediaHeight,
        )
    }

    function getCroppedImg(fileName) {
        const canvas = document.createElement('canvas');
        const scaleX = imgRef.current.naturalWidth / imgRef.current.width;
        const scaleY = imgRef.current.naturalHeight / imgRef.current.height;
        const pixelRatio = window.devicePixelRatio;
        
        canvas.width = Math.floor(completedCrop.width * scaleX * pixelRatio);
        canvas.height = Math.floor(completedCrop.height * scaleY * pixelRatio);
        
        const ctx = canvas.getContext('2d'); 
        ctx.scale(pixelRatio, pixelRatio);
        // ctx.imageSmoothingQuality = 'high';
        const cropX = completedCrop.x * scaleX;
        const cropY = completedCrop.y * scaleY;
        const centerX = imgRef.current.naturalWidth / 2;
        const centerY = imgRef.current.naturalHeight / 2;
        ctx.translate(-cropX, -cropY);
        ctx.translate(centerX, centerY);
        ctx.translate(-centerX, -centerY)
        
        ctx.drawImage(
            imgRef.current,
            0,//completedCrop.x,
            0,//completedCrop.y,
            imgRef.current.naturalWidth,//completedCrop.width,
            imgRef.current.naturalHeight,//completedCrop.height,
            0,
            0,
            imgRef.current.naturalWidth,//completedCrop.width,
            imgRef.current.naturalHeight,//completedCrop.height,
        );
       
        // As Base64 string
        // const base64Image = canvas.toDataURL('image/jpeg');
        // console.log(base64Image)
       
        // As a blob
        return new Promise((resolve, reject) => {
          canvas.toBlob(file => {
            file.name = fileName;
            resolve(file);
          }, 'image/jpeg');
        });
    }
      
    const uploadToFirebase = (file_name,file_full_name) =>{
        return new Promise(function (resolve, reject) {
            getCroppedImg(file_name).then((img) => {
                //compress the image
                const compressOptions={
                    maxSizeMB:0.1,
                    fileType:'image/jpeg',
                    maxWidthOrHeight:600
                }
                imageCompression(img,compressOptions).then(dataUrl => {
                    const storage = getStorage(app);
                    const storageRef = ref(storage,file_full_name);
                    const task= uploadBytesResumable(storageRef,dataUrl); 
                    
                    task.on('state_changed', 
                    (snapshot) => {
                        var pctUpload = (snapshot.bytesTransferred / snapshot.totalBytes)*100;
                        setProgress(pctUpload);
                        if (snapshot.state==='error'){
                            task.cancel();
                            reject();
                        }
                    },
                    (error) => {
                        console.log(error);
                        reject(error);
                    },
                    () => {
                        getDownloadURL(task.snapshot.ref).then(function(downloadURL) {
                            resolve(downloadURL);
                        })
                    }); 
                }).catch(error =>{
                    console.log(error);
                    reject(error)
                })
            }).catch(error => {
                console.log(error)
                reject(error);
            })
        });
    }
    const uploadImage = async () => {
        setLoading(true);
        setProgress(0);
        setUploadSuccess(false);
        onModalClose();
        const file_name = `profile_pic.jpg`;
        const uid = user.uid;
        const file_full_name=`/users/${uid}/${file_name}`;
        try{
            const downloadURL = await uploadToFirebase(file_name,file_full_name) ;
            // console.log(downloadURL)
            await updateUserProfilePic(downloadURL);
            setUploadSuccess(true);
            setError(false);
            setTimeout(() => {
                setUploadSuccess(false);
            }, 1500)
        } catch(error){
            console.log(error)
            setError(true);
            setTimeout(() => {
                setError(false);
            }, 1500)
        } finally{
            setLoading(false);
        }
    }
    return (
        <>
            <Stack
                direction={'column' }
                spacing={2}
                justifyContent={'space-around'}
                alignItems={'center'}
                sx={{mt:2,mb:2}}
            >
                { photoURL 
                    ? <Avatar alt="Avatar" src={photoURL} sx={{ width: 100, height: 100 }}/>
                    : <Avatar alt="Avatar" sx={{ width: 100, height: 100 }}>{name.charAt(0).toUpperCase()}</Avatar>
                }

                <ThemedLoadingButton color='secondary' component="label" disabled={loading}>
                    Change Photo
                    <input id="user-profile-pic" hidden accept="image/*" type="file" ref={inputFileRef} onInput={onSelectFile} />
                </ThemedLoadingButton>
                { loading && <Box sx={{ width: '100%' }}>
                    <LinearProgress variant="determinate" value={progress}/>
                </Box> }
                { error && <Alert onClose={() => setError(false)} severity="error">Something went wrong. Please try again.</Alert> }
                { uploadSuccess && <Alert severity="success">Uploaded</Alert> }
            </Stack>
            
            <Modal
                open={openModal}
                onClose={onModalClose}
                disableScrollLock={true}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    overflowY: 'scroll',
                }}
            >
                <Container component={'main'} maxWidth={isXs ? 'xs' : 'md'}>
                    <Paper
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            padding: '32px',
                            borderRadius: '5px',
                            height: 'auto',
                        }}
                    >
                        {!!imgSrc && <ReactCrop
                            crop={crop}
                            onChange={(_, percentCrop) => setCrop(percentCrop)}
                            onComplete={(c) => setCompletedCrop(c)}
                            // aspect={aspect}
                            >
                            <img
                                ref={imgRef}
                                alt="Crop me"
                                src={imgSrc}
                                onLoad={onImageLoad}
                            />
                            </ReactCrop>
                        }

                        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginTop: theme.spacing(2) }}>
                            <Button variant="contained" onClick={uploadImage}>Upload</Button>
                            <Button variant="outlined" onClick={onModalClose}>Cancel</Button>
                        </Box>
                    </Paper>
                </Container>
            </Modal>
        </>
    );
}

export default ProfilePicture;
