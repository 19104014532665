import React from 'react';
import { makeStyles } from '@mui/styles';
// import { useLocation } from 'react-router-dom';

import AccodionFAQ from "../../../component/AccodionFAQ";

const useStyles = makeStyles(theme => ({
    accordionWrapper: {
    },
}));

const EarnMoneyDropOffFAQ = () =>{
    const classes = useStyles();

    return(
        <div className={classes.accordionWrapper}>
            <AccodionFAQ
                question={'Who can join?'}
                answer={`Anyone with a spare space in their house or business can signup. The premise has to be accessible to the road traffic and on ground floor.`}
            />

            <AccodionFAQ
                question={'What will I be doing?'}
                answer={`As a pick up and collection point you will be receiving or handing out parcels to our customers. The parcels 
                will be collected or dropped off at your premises by our delivery partners.`}
            />

            <AccodionFAQ
                question={'Do I need special training or equipment in order to start?'}
                answer={`No you do not need any special training. The collection or pick up of any parcel
                requires you to scan a QR code. You will need a mobile phone running Android or iPhone. There are video tutorials to
                help you get started once your registration is complete.`}
            />

            <AccodionFAQ
                question={'How do I sign up?'}
                answer={`You need to download our app to sign up.`}
            />
        </div>
    )
}

export default EarnMoneyDropOffFAQ;