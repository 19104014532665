import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

import AccodionFAQ from "../../component/AccodionFAQ";

const useStyles = makeStyles(theme => ({
    accordionWrapper: {
        backgroundColor: theme.palette.grey['100'],
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(5),
        // paddingLeft: '15%',
        // paddingRight: '15%',
    },
}));

const EarnFAQ =() =>{
    const classes = useStyles();
    return(
        <div className={classes.accordionWrapper}>
            <Container>
                <Typography variant='h5' sx={{textAlign:'center'}}>Drive for us</Typography>
                <AccodionFAQ
                    question={'Who can join?'}
                    answer={`Anyone with a valid licence, no unspent driving convictions and access to a vehicle can signup.`}
                />

                <AccodionFAQ
                    question={'What vehicle do I need?'}
                    answer={`You need to have access to a moped, car or van. We will be adding more vehicle
                    classes such as small trucks and lorries soon.`}
                />

                <AccodionFAQ
                    question={'Do I need special training or equipment in order to start?'}
                    answer={`No you do not need any special training. Delivering with us is similar to using any mobile based 
                    navigation apps so a phone running Android or iPhone is required. There are video tutorials to
                    help you get started once your registration is complete.`}
                />

                <AccodionFAQ
                    question={'How do I start delivering?'}
                    answer={`After your registration is complete, you can go to the 'Drive' section of the app and look for delivery 
                    blocks available near you. Each block has the start time, the money you will earn and the time it will take 
                    to deliver it. You can accept the block you like and start delivering.`}
                />

                <Typography variant='h5' sx={{textAlign:'center',mt:3,mb:3}}>Being a drop-off and collection partner </Typography>
                <AccodionFAQ
                    question={'Who can join?'}
                    answer={`Anyone with a spare space in their house or business can signup. The premise has to be accessible to the road traffic and on ground floor.`}
                />

                <AccodionFAQ
                    question={'What will I be doing?'}
                    answer={`As a pick up and collection point you will be receiving or handing out parcels to our customers. The parcels 
                    will be collected or dropped off at your premises by our delivery partners.`}
                />

                <AccodionFAQ
                    question={'Do I need special training or equipment in order to start?'}
                    answer={`No you do not need any special training. The collection or pick up of any parcel
                    requires you to scan a QR code. You will need a mobile phone running Android or iPhone. There are video tutorials to
                    help you get started once your registration is complete.`}
                />

                <AccodionFAQ
                    question={'How do I sign up?'}
                    answer={`You need to download our app to sign up.`}
                />
            </Container>
        </div>
    )
}

export default EarnFAQ;