const checkPassword = password => {
    let response = {
        isValid: false, // is t
        message: `Password should contain more than 8 or more characters with a mix of upper and lowercase letters, numbers and special characters`, // outcome of the message
        sanitisedValue: password, // cleaned up value
    };

    if (!password) {
        response = {
            type: 'blank',
            isValid: false,
            message: 'The field cannot be left blank',
            sanitisedValue: password,
        };
    } else {
        if (
            password.match(/[a-z]/g) && // match lowercase
            password.match(/[A-Z]/g) && // match uppercase
            password.match(/[0-9]/g) && // match letters
            password.match(/[^a-zA-Z\d]/g) && // match special characters
            password.length >= 8
        ) {
            response = {
                type: 'password',
                isValid: true,
                message: '',
                sanitisedValue: password,
            };
        }
    }

    return response;
};

export default checkPassword;
