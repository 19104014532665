import { useState, useContext } from 'react';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { getAuth, deleteUser } from "firebase/auth";

import { AuthContext } from '../../context/AuthContext';
import ThemedLoadingButton from "../Buttons/ThemedLoadingButton";

const DeleteAccount = () => {
    const { signOut } = useContext(AuthContext);
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const auth = getAuth();
    const user = auth.currentUser;

    const handleOpen = () =>{
        setOpen(true);
        setErrorMessage('');
    }

    const handleClose = () => {
        setLoading(false);
        setOpen(false);
    };

    const handleDelete = async () =>{
        setLoading(true);
        setErrorMessage('');
        try {
            await deleteUser(user);
            await signOut();
        } catch(error){
            setLoading(false);
            switch(error.code){
                case 'auth/requires-recent-login':
                    setErrorMessage('Your session has expired. Please logout and login again to update password.');
                    break;
                default:
                    setErrorMessage('Unable to delete account. Please try again.');
                    break;
            }
        }

    }

    return (
        <>
        <FormControl fullWidth={true} variant="outlined">
            <FormLabel sx={{ fontWeight: 600, marginBottom:'8px' }}>Delete Account</FormLabel>
            <Button
                variant="outlined"
                color="error"
                onClick={handleOpen}
            >
                Delete Account
            </Button>
        </FormControl>
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {"Delete Account?"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Are you sure you want to delete your account? If you delete your account you will permanently lose your profile, orders and any payments.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <ThemedLoadingButton variant="contained" color="error" onClick={handleClose} autoFocus>
                    Cancel
                </ThemedLoadingButton>
                <ThemedLoadingButton variant="contained" color="disabled" onClick={handleDelete} loading={loading} disabled={loading}>
                    Delete
                </ThemedLoadingButton>
            </DialogActions>
            { errorMessage!=='' && <Alert onClose={()=>setErrorMessage('')} severity='error' sx={{m:'8px'}}>{errorMessage}</Alert>}
        </Dialog>
        </>
    );
}

export default DeleteAccount;
