import 'react-lazy-load-image-component/src/effects/blur.css';
import Grid from '@mui/material/Grid';
import HomeBottom from './HomeBottom';
import HomeTop from './HomeTop';

function Home() {
    return (
        <Grid>
            <HomeTop />
            <HomeBottom />
        </Grid>
    );
}

export default Home;
