import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import { Add as ExpandMoreIcon, Remove as RemoveIcon } from '@mui/icons-material';

const AccodionFAQ = (props) =>{
    const [openPanel, setOpenPanel] = React.useState(false);
    const {question, answer} = props;
    return(
        <Accordion
            onChange={() => setOpenPanel(!openPanel)}
            square={true}
            sx={{
                borderBottom:theme=> `1px solid ${theme.palette.text.secondary}`,
                boxShadow: 'none',
                backgroundColor: 'transparent',
            }}
        >
            <AccordionSummary
                expandIcon={
                    openPanel ? <RemoveIcon /> : <ExpandMoreIcon />
                }
            >
                <Typography >
                    {question}
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Typography>
                    {answer}
                </Typography>
            </AccordionDetails>
        </Accordion>
    )
}

export default AccodionFAQ;