import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
    hoverAnimation: {
        cursor: 'pointer',
        width: 'calc(100%)',
        paddingBottom: '6px',
        transition: 'all 500ms cubic-bezier(0.23, 1, 0.32, 1)',
        backgroundPosition: '0 bottom, 0 bottom',
        backgroundSize: '0% 6px, 100% 6px',
        backgroundImage:
            'linear-gradient(transparent calc(100% - 3px), #0240AD 1px),' +
            'linear-gradient(transparent calc(100% - 3px), #CBCBCB 1px)',
        backgroundRepeat: 'no-repeat',
        lineHeight: '32px',
        '&:hover': {
            backgroundSize: '100% 6px, 100% 6px',
        },
    },
}));

function HoverUnderlineTypography({ children, ...rest }) {
    const classes = useStyles();
    return (
        <div>
            <span className={classes.hoverAnimation} {...rest}>
                {children}
            </span>
        </div>
    );
}

export default HoverUnderlineTypography;
