import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

function createData(weight, royalmail, evri, zkootie) {
  return { weight, royalmail, evri, zkootie };
}

const rows = [
  createData('0 - 500 grams', '£5.15', '£2.99', '£2.49'),
  createData('0.5 - 2 kilos', '£5.15', '£4.44', '£4.00'),
  createData('2 - 5 kilos', '£6.65', '£5.96', '£5.50'),
  createData('5 - 15 kilos', '£10.15', '£9.28', '£8.49'),
];

const PricingTable = () => {
    return (
        <>
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 150 }} aria-label="simple table">
                <TableHead>
                    <TableRow selected>
                        <TableCell align="left" sx={{fontWeight:600}}>Weight</TableCell>
                        <TableCell align="center" sx={{fontWeight:600}}>Royal Mail</TableCell>
                        <TableCell align="center" sx={{fontWeight:600}}>Evri</TableCell>
                        <TableCell align="center" sx={{fontWeight:600}}>Zkootie</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row) => (
                        <TableRow
                            key={row.weight}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row" align="left">
                                {row.weight}
                            </TableCell>
                            <TableCell align="center">{row.royalmail}</TableCell>
                            <TableCell align="center">{row.evri}</TableCell>
                            <TableCell align="center" 
                                sx={{
                                    backgroundColor:theme=>theme.palette.secondary.light,
                                    border:0,
                                    fontWeight:600,
                                    color:'white'
                                }}
                            >
                                {row.zkootie}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
        <Typography variant='caption' sx={{marginTop:1}}>Prices confirmed on 07/04/2023</Typography>
        </>
        
  );
}

export default PricingTable;