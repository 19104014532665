import React from 'react';

import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

function PackageLocation(props) {
    const {
        fromAddressList,
        toAddressList,
        fromAddress,
        setFromAddress,
        toAddress,
        setToAddress,
    } = props;

    return (
        <Paper
            onClick={() => {
            }}
            sx={{
                // cursor: 'pointer',
                height: '100%',
                width: '100%',
                fontWeight: {
                    sm: 600,
                    md: 500,
                },
            }}
        >
            <Typography style={{ paddingTop:'16px', paddingLeft: '16px', paddingRight: '16px'}}>Your address</Typography>
            <Grid container spacing={2} style={{ padding: '16px' }}>
                <Grid 
                    item 
                    style={{ height: 'auto',justifyContent:'center',display:'flex',width:'100%' }}
                >
                    <FormControl variant="standard" style={{width:'100%'}} >
                        <InputLabel color='secondary'>Select an address</InputLabel>
                        <Select
                            value={fromAddress}
                            color='secondary'
                            onChange={(e)=>setFromAddress(e.target.value)}
                        >
                            {
                                fromAddressList.map(elm=> <MenuItem key={elm.address} value={elm.address}>{elm.address}</MenuItem>)
                            }
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>

            <Typography style={{ paddingTop:'16px', paddingLeft: '16px', paddingRight: '16px'}}>Delivery address</Typography>
            <Grid container spacing={2} style={{ padding: '16px' }}>
                <Grid 
                    item 
                    style={{ height: 'auto',justifyContent:'center',display:'flex',width:'100%' }}
                >
                    <FormControl variant="standard" style={{width:'100%'}} >
                    <InputLabel color='secondary'>Select an address</InputLabel>
                        <Select
                            value={toAddress}
                            color='secondary'
                            onChange={(e)=>setToAddress(e.target.value)}
                        >
                            {
                                toAddressList.map(elm=> <MenuItem key={elm.address} value={elm.address}>{elm.address}</MenuItem>)
                            }
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
        </Paper>
    );
}

export default PackageLocation;
