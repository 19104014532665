import { useContext, useState } from 'react';
import Alert from '@mui/material/Alert';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
// import MuiPhoneNumber from 'material-ui-phone-number';
import { useTheme } from '@mui/material/styles';

import { AuthContext } from '../../context/AuthContext';
import { dateIsValid } from "../../utils";
import {MONTHS_OF_YEAR} from "../../AppConstants";
import ThemedLoadingButton from "../Buttons/ThemedLoadingButton";

const ProfileSettings = ({showBackButton,onBackClick}) => {
    const theme = useTheme();
    const { user, dob, setDob, gender, setGender, userAttributes, updateUserProfile } = useContext(AuthContext);
    const { email} = user;
    const { profile } = userAttributes;
    const { displayName } = profile;
    const { date, month, year } = dob;
    
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [saved, setSaved] = useState(false);

    const [name, setName] = useState(displayName||'');
    const [errorName, setErrorName] = useState(false);
    const [nameResponse, setNameResponse] = useState('At least 3 characters'); 
    
    const [errorDOB, setErrorDOB] = useState(false);
    const [errorDOBtype, setErrorDOBtype] = useState('');
    const [dobResponse, setDobResponse] = useState('');

    const changedName = (name !== displayName);
    const changedDob = ((date !== profile?.dateOfBirth?.date) && date.trim() !== '') || ((month !== profile?.dateOfBirth?.month) && month.trim() !== '') || ((year !== profile?.dateOfBirth?.year) && year.trim() !== '');
    const changedGender = ((gender !== profile?.gender) && gender.trim() !== ''); 
    const changed = changedName || changedDob || changedGender;
    
    async function onSubmit(){
        setLoading(true);
        setError(false);
        setSaved(false);
        setErrorName(false);
        setErrorDOB(false);
        setErrorDOBtype('');
        // check name
        var validName=true;
        if (changedName){
            if (!name.trim().match(/\w\s\w/g) || name.trim().length<4){
                setErrorName(true);
                setNameResponse('Please enter full name');
                validName=false;
            } else {
                setErrorName(false);
                setNameResponse('At least 3 characters');
                validName=true;
            }
        }

        //check date of birth
        var validDate = true;
        if (changedDob){
            if (!Number.isInteger(Number(date))){  
                setErrorDOB(true);
                setDobResponse('Invalid date of birth');
                setErrorDOBtype('day');
                validDate=false;
            }
            else if (!Number.isInteger(Number(year))){  
                setErrorDOB(true);
                setDobResponse('Invalid date of birth');
                setErrorDOBtype('year');
                validDate=false;
            }
            else if (year.trim().length !==4){  
                setErrorDOB(true);
                setDobResponse('Please enter four digit year');
                setErrorDOBtype('year');
                validDate=false;
            }
            else if (['19','20'].findIndex(elm=> elm===year.trim().substring(0,2) )<0){ 
                setErrorDOB(true);
                setDobResponse('Year should start with 19 or 20');
                setErrorDOBtype('year');
                validDate=false;
            }
            else if (date.trim().length === ''){
                setErrorDOB(true);
                setDobResponse('Invalid date of birth');
                setErrorDOBtype('day');
                validDate=false;
            }
            else if (month.trim().length === ''){
                setErrorDOB(true);
                setDobResponse('Invalid date of birth');
                setErrorDOBtype('month');
                validDate=false;
            }
            else {
                validDate = dateIsValid(year,month,date);
                if (!validDate){
                    setErrorDOB(true);
                    setDobResponse('Invalid date of birth');
                    setErrorDOBtype('all');
                    validDate=false;
                } else {
                    setErrorDOB(false);
                    setErrorDOBtype('');
                }
            }
        } 
    
        if (validName && validDate){
            // console.log(validName,validDate);setLoading(false);
            try{
                // if (changedName){
                //     await updateUserNameAndPhone(name);
                // }
                if (changedName || changedDob || changedGender){
                    // only send to functions what has changed
                    const profile={
                        ...changedName && {displayName:name},
                        ...changedDob && {dateOfBirth:{date,month,year}},
                        ...changedGender && {gender}
                    }
                    const response=await updateUserProfile(profile);
                    setError(!response);
                }
                setSaved(true);
                setTimeout(() => {
                    setSaved(false);
                }, 1500)
            } catch (error){
                console.log(error);
                setError(true);
            } finally{
                setLoading(false);
            }
        }
        else {
            setLoading(false);
        }
    }
    return (
        <Stack
            direction="column"
            justifyContent="flex-start"
            alignItems="stretch"
            spacing={2}
            sx={{
                width:'100%'
            }}
        >
            {showBackButton && <Button variant="text" startIcon={<ArrowBackIcon />} sx={{justifyContent:'flex-start',maxWidth:'80px'}} onClick={onBackClick}> Back </Button>}
            <Typography variant={'h6'} sx={{ fontWeight: 600 }}>
                Edit Profile
            </Typography>
            <Divider />
            
            <FormControl fullWidth={true} variant="outlined">
                <Typography variant={'body1'} sx={{mb:theme=>theme.spacing(1)}}>Name</Typography>
                <TextField
                    name={'name'}
                    value={name}
                    onChange={e=>setName(e.target.value)}
                    autoComplete={'name'}
                    helperText={changedName ? nameResponse : ''}
                    error={errorName}
                    InputProps={{
                        sx:{
                               bgcolor: 'background.default',
                            }
                    }}
                />
            </FormControl>

            <FormControl fullWidth={true} variant="outlined">
                <Typography variant={'body1'} sx={{mb:theme=>theme.spacing(1)}}>Email Address</Typography>
                <TextField
                    name={'email'}
                    disabled={true}
                    value={email}
                    InputProps={{
                        sx:{
                               bgcolor: 'background.default',
                            }
                    }}
                />
            </FormControl>
            
            <Typography variant={'body1'} style={{marginBottom:theme.spacing(1)}}>Date of birth</Typography>
            <Grid container style={{marginTop:0}}>
                <Grid item xs={3}>
                    <FormControl fullWidth={true} variant="outlined">
                        <TextField
                            label={'Day'}
                            name={'email'}
                            value={date}
                            onChange={e=>setDob({...dob,date:e.target.value})}
                            error={errorDOBtype==='day'||errorDOBtype==='all'}
                            InputProps={{
                                sx:{
                                    bgcolor: 'background.default',
                                    }
                            }}
                            helperText={
                                errorDOB ? dobResponse : 
                                (date.trim() === '' && year.trim() === '' && month.trim() === '') ? 'This makes it easier for us to deliver age restricted items' : ''
                            }
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl fullWidth={true} variant="outlined" sx={{pl:1,pr:1}}>
                        <InputLabel color='secondary'>Month</InputLabel>
                        <Select
                            fullWidth
                            value={month}
                            onChange={e=>setDob({...dob,month:e.target.value})}
                            error={errorDOBtype==='month'||errorDOBtype==='all'}
                            inputProps={{
                                sx:{
                                    bgcolor: 'background.default',
                                    }
                            }}
                        >
                            {
                                MONTHS_OF_YEAR.map(elm=> <MenuItem key={elm.value} value={elm.value}>{elm.label}</MenuItem>)
                            }
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <FormControl fullWidth={true} variant="outlined">
                        <TextField
                            label={'Year'}
                            name={'email'}
                            value={year}
                            onChange={e=>setDob({...dob,year:e.target.value})}
                            error={errorDOBtype==='year'||errorDOBtype==='all'}
                            InputProps={{
                                sx:{
                                    bgcolor: 'background.default',
                                    }
                            }}
                        />
                    </FormControl>
                </Grid>
            </Grid>

            <FormControl fullWidth={true} variant="outlined">
                <Typography variant={'body1'} sx={{mb:theme=>theme.spacing(1)}}>Gender</Typography>
                <RadioGroup 
                    row
                    value={gender}
                    onChange={e=>setGender(e.target.value)}
                >
                    <FormControlLabel value="female" control={<Radio />} label="Female" />
                    <FormControlLabel value="male" control={<Radio />} label="Male" />
                    <FormControlLabel value="other" control={<Radio />} label="Other" />
                </RadioGroup>
            </FormControl>
        

            {/* <FormControl fullWidth={true} variant="outlined">
                <MuiPhoneNumber
                    defaultCountry={'gb'}
                    id="sender-phone" 
                    label="Phone Number" 
                    variant="outlined" 
                    InputProps={{
                        sx:{
                               bgcolor: 'background.default',
                            }
                    }}
                    value={fromContactPhone}
                    onChange={(value)=>setFromConactPhone(value)}
                    inputProps={{
                        name:"senderPhone" 
                    }}
                    fullWidth
                    disableAreaCodes={true}
                />
            </FormControl> */}
            
            <ThemedLoadingButton 
                loading={loading}
                disabled={!changed}
                style={{width:'100px',marginTop:'16px'}} 
                onClick={onSubmit}
            >
                Save
            </ThemedLoadingButton>
            {error && <Alert onClose={() => setError(false)} severity="error">Something went wrong. Please try again.</Alert>}
            {saved && <Alert onClose={() => setError(false)} severity="success">Updated!</Alert>}

        </Stack>
    );
}

export default ProfileSettings;
