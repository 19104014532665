const checkValidEmail = data => {
    let response = {
        type: null, //valid values - email if the field is email; phone for phone numbers and null if it is undetermined; blank - if no value
        isValid: false, // is the field valid
        message: `Invalid phone number or email`, // outcome of the message
        sanitisedValue: null, // cleaned up value
    };

    // check if its phone or email

    if (!data) {
        response = {
            type: 'blank',
            isValid: false,
            message: 'The field cannot be left blank',
            sanitisedValue: null,
        };
    } else {
        // remove spaces from data

        data = data.trim();
        let isPhone = false;
        let isEmail = false;
        let isValidPhone = false;
        let isValidEmail = false;
        let sanitisedValue = null;

        // Test 1 - Does it start with a '+' sign or 00

        let regularExpression = /^\+|^00/;
        isPhone = regularExpression.test(data);

        // Test 2 - Does it contain @

        regularExpression = /\S+@\S+\.\S+/;
        isEmail = regularExpression.test(data);

        // Check for the validity of Email - Email take priority in validation

        if (isEmail) {
            regularExpression = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

            // Character	    Description
            //  / .. /	        All regular expressions start and end with forward slashes.
            //  ^	            Matches the beginning of the string or line.
            //  \w+	            Matches one or more word characters including the underscore. Equivalent to [A-Za-z0-9_].
            //  [\.-]	        \ Indicates that the next character is special and not to be interpreted literally.
            //  .-              matches character . or -.
            //  ?	            Matches the previous character 0 or 1 time. Here previous character is [.-].
            //  \w+      	    Matches 1 or more word characters including the underscore. Equivalent to [A-Za-z0-9_].
            //  *	            Matches the previous character 0 or more times.
            //  ([.-]?\w+)*	    Matches 0 or more occurrences of [.-]?\w+.
            //  \w+([.-]?\w+)*	The sub-expression \w+([.-]?\w+)* is used to match the username in the email. It begins with at least one or more word characters including the underscore, equivalent to [A-Za-z0-9_]. , followed by . or - and . or - must follow by a word character (A-Za-z0-9_).
            //  @	It matches only @ character.
            //  \w+([.-]?\w+)*	It matches the domain name with the same pattern of user name described above..
            //  \.\w{2,3}	    It matches a . followed by two or three word characters, e.g., .edu, .org, .com, .uk, .us, .co etc.
            //  +	            The + sign specifies that the above sub-expression shall occur one or more times, e.g., .com, .co.us, .edu.uk etc.
            //  $	            Matches the end of the string or line.

            isValidEmail = regularExpression.test(data);

            if (isValidEmail) {
                response = {
                    type: 'email',
                    isValid: true,
                    message: '',
                    sanitisedValue: data,
                };
            } else {
                response = {
                    type: 'email',
                    isValid: false,
                    message: `Only letters (a-z A-Z), numbers (0-9), underscore (_), dot (.) and one @ symbol are allowed`,
                    sanitisedValue: null,
                };
            }
        }

        // If its a phone number check it only contains Digits
        else if (isPhone) {
            regularExpression = /^\+?\d+$|^00?\d+$/; // starts with + and contains digits (\d+$) or (|) starts with 00 and contains digits
            isValidPhone = regularExpression.test(data);

            if (isValidPhone) {
                sanitisedValue = data.replace(/^00/g, '+');
                response = {
                    type: 'phone',
                    isValid: true,
                    message: '',
                    sanitisedValue: sanitisedValue,
                };
            } else {
                response = {
                    type: 'phone',
                    isValid: false,
                    message: `A phone number should start with '+' or country code and can only contain numbers`,
                    sanitisedValue: null,
                };
            }
        }

        // Not  an email or phone
        else {
            // does it contain any letters
            regularExpression = /\D/;
            let containsLetters = regularExpression.test(data);

            if (!containsLetters) {
                response.message = `A phone number should start with '+' or country code and can only contain numbers`;
            }
        }
    }

    return response;
};

export default checkValidEmail;
