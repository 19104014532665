import { useContext, useState } from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';

import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';


import SettingsList from "../../component/Settings/SettingsList";
import ProfileSettings from "../../component/Settings/ProfileSettings";
import AccountSettings from "../../component/Settings/AccountSettings";
import { AuthContext } from '../../context/AuthContext';

const Settings = () => {
    const { user } = useContext(AuthContext);
    const theme = useTheme();
    const isMd = useMediaQuery(theme.breakpoints.down('md'));
    const [selectedSetting, setSelectedSetting] = useState(0);
    const [showList, setShowList] = useState(true);
    // const [name, setName] = useState(user?.displayName);
    // const [email, setEmail] = useState(user?.email);
    // const [phone, setPhone] = useState(user?.phoneNumber);

    const updateSettingList = (val) =>{
        setShowList(false);
        setSelectedSetting(val);
    }

    const renderSettings = () =>{
        if (isMd){
            if (showList) return <SettingsList selectedSetting={selectedSetting} setSelectedSetting={updateSettingList}/>;
            else {
                if (selectedSetting===0) return <ProfileSettings showBackButton={isMd} onBackClick={()=>setShowList(true)}/>; 
                else return <AccountSettings showBackButton={isMd} onBackClick={()=>setShowList(true)}/>;
            }
        } else {
            return(
                <>
                <SettingsList selectedSetting={selectedSetting} setSelectedSetting={setSelectedSetting}/>
                {selectedSetting===0 && <ProfileSettings showBackButton={isMd} onBackClick={()=>setShowList(true)}/>}    
                {selectedSetting!==0 && <AccountSettings showBackButton={isMd} onBackClick={()=>setShowList(true)}/>}
                </>
            )
        }
    }
    
    return (
        <Box 
            sx={{ 
                backgroundColor: theme => theme.palette.disabled.main, 
                minHeight: '60%',
            }}
        >
            <Container sx={{ p: '32px' }}>
                {user && <Stack 
                        spacing={5}
                        direction={{
                            xs: 'column',
                            sm: 'column',
                            md: 'row',
                            lg: 'row',
                            xl: 'row',
                        }}
                    >   
                    {renderSettings()}
                        {/* {(isMd && showBackButton) && <SettingsList selectedSetting={selectedSetting} setSelectedSetting={setSelectedSetting}/>}
                        {(isMd && !showBackButton) && <SettingsList selectedSetting={selectedSetting} setSelectedSetting={setSelectedSetting}/>}
                        
                        <SettingsList selectedSetting={selectedSetting} setSelectedSetting={setSelectedSetting}/>
                        {selectedSetting===0 && <ProfileSettings showBackButton={isMd} />}    
                        {selectedSetting!==0 && <AccountSettings showBackButton={isMd} />} */}
                    </Stack>
                }
            </Container>
        </Box>
    );
};

export default Settings;
