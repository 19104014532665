import React from 'react';

import { alpha } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import DoneIcon from '@mui/icons-material/Done';
// import { useTheme } from '@mui/material/styles';

// import Divider from '@mui/material/Divider';

function PackageType(props) {
    // const theme = useTheme()
    const {
        price,
        currencySymbol,
        deliveryType,
        selectedDeliveryType,
        setSelectedDeliveryType,
        // deliveryType,
        data: { id, title, text },
    } = props;

    let priceText = '';
    if (selectedDeliveryType === id) {
        priceText = `Selected (+ ${currencySymbol}${price.toFixed(2)})`;
    } else if (price < deliveryType[selectedDeliveryType]) {
        priceText = `- ${currencySymbol}${(deliveryType[selectedDeliveryType] - price).toFixed(2)}`;
    } else {
        priceText = `+ ${currencySymbol}${(price - deliveryType[selectedDeliveryType]).toFixed(2)}`;
    }

    return (
        <Paper
            onClick={() => {
                setSelectedDeliveryType(id);
            }}
            elevation={selectedDeliveryType === id ? 5 : 0}
            key={id}
            sx={{
                cursor: 'pointer',
                height: '100%',
                width: '100%',
                fontWeight: {
                    sm: 600,
                    md: 500,
                },
                backgroundColor: theme =>
                    `${selectedDeliveryType === id ? alpha(theme.palette.secondary.main, 0.08) : '#e3e3e3'}`,
                color: '#000000',
                border: theme =>
                    `${selectedDeliveryType === id ? `2px solid ${theme.palette.secondary.main}` : 'none'}`,
            }}
        >
            <Stack
                direction={{ xs: 'row', sm: 'row', md: 'column' }}
                spacing={2}
                justifyContent={'space-around'}
                alignItems={'center'}
                sx={{
                    height: {
                        xs: '120px',
                        sm: '120px',
                        md: '100%',
                        lg: '100%',
                    },
                    maxHeight: {
                        xs: '120px',
                        sm: '120px',
                        md: '100%',
                        lg: '100%',
                    },
                }}
            >
                <Typography
                    sx={{
                        fontWeight: 'bold',
                        paddingTop: '16px',
                    }}
                >
                    {title}
                </Typography>
                <Stack alignContent={'flex-start'} spacing={2} alignItems={'flex-start'} justifyContent={'flex-start'}>
                    {text.map(elm => (
                        <Stack flexDirection={'row'} key={elm.key}>
                            <DoneIcon color={'secondary'} sx={{ marginRight: '16px' }} />
                            <Typography>{elm.content}</Typography>
                        </Stack>
                    ))}
                </Stack>
            </Stack>
            <Button
                disableElevation={true}
                fullWidth
                color={'secondary'}
                sx={{
                    marginTop: '16px',
                    border: theme => `1px solid ${theme.palette.secondary.main}`,
                    fontWeight: selectedDeliveryType === id ? 600 : 500,
                    borderRadius: 0,
                    borderBottomLeftRadius: `${selectedDeliveryType !== id ? '5px' : '0px'}`,
                    borderBottomRightRadius: `${selectedDeliveryType !== id ? '5px' : '0px'}`,
                }}
                variant={'contained'}
            >
                {priceText}
            </Button>
            {/*</Box>*/}
            {/*</Stack>*/}
        </Paper>
        // </Box>
    );
}

export default PackageType;
