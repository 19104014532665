import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import CircularProgress from '@mui/material/CircularProgress';
import { useTheme } from '@mui/material/styles';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { getApp } from 'firebase/app';
import InfiniteScroll from 'react-infinite-scroll-component';

import ParcelAgeSelectionButtons from "./ParcelAgeSelectionButtons";
import ParcelList from "./ParcelList";
import { ORDER_HISTORY_BUTTONS } from "../../AppConstants";
import firebaseConfig from '../../firebaseConfig';

const MyParcels = () => {
    const theme = useTheme();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [selectedAge, setSelectedAge] = useState(ORDER_HISTORY_BUTTONS[0].period);
    const [canRequestMore, setCanrequestMore] = useState(true);
    const [myParcels, setMyParcles] = useState([]);
    const [last, setLast] = useState(null);

    const app = getApp();
    const { region } = firebaseConfig;
    const functions = getFunctions(app,region);

    useEffect(() => {
        getMyParcles(canRequestMore, selectedAge);
    }, []);

    const getDataForAge = age => {
        if (age !== selectedAge) {
            //requesting a fresh copy. Reset-state
            setLoading(true);
            setSelectedAge(age);
            setCanrequestMore(true);
            setMyParcles([]);
            getMyParcles(true, age);
            setLast(null);
        }
    };

    const getMyParcles = async (newCopy, age) => {
        const requestMore = newCopy ? newCopy : canRequestMore;
        const myParcels = httpsCallable(functions, 'db-myParcels');
        try {
            if (requestMore) {
                const response = await myParcels({
                    requestAdditionalData: canRequestMore,
                    last: last,
                    lastPeriod: age,
                });

                if (response.data.status !== 'OK') {
                    setError(true);
                } else {
                    if (response.data.documentData.length > 0) {
                        if (myParcels.length > 0) {
                            setMyParcles(myParcels => [...myParcels, ...response.data.documentData]);
                        } else {
                            setMyParcles(response.data.documentData);
                        }
                    }

                    setCanrequestMore(response.data.canRequestSnapShots);
                    setLast(response.data.last);
                }
            } else {
                console.log('Cannot request more data');
            }
        } catch (error) {
            console.log(error);
            setError(true);
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };

    

    if (loading) {
        return (
            <>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%',}}>
                    <CircularProgress size={40} color="primary" />
                </div>
            </>
        );
    }

    if (error) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%',}}>
                <Typography component="p" style={{ margin: 'auto', textAlign: 'center'}}>
                        Oh Snap! Something went wrong. Please try again.
                </Typography>
            </div>
        );
    }

    return (
        <Box 
            sx={{ 
                backgroundColor: theme => theme.palette.disabled.main, 
                minHeight: '60%',
            }}
        >
        <Paper
            elevation={0}
            sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-around',
                paddingTop: theme.spacing(3),
                marginBottom: theme.spacing(3),
                marginLeft: 'auto',
                marginRight: 'auto',
                maxWidth:600,   
                backgroundColor: theme => theme.palette.disabled.main,              
            }}
        >
            <Typography variant="h5">
                Your Parcels
            </Typography>
            <ParcelAgeSelectionButtons getDataForAge={getDataForAge} selectedAge={selectedAge}/>
        </Paper>
        
        <Divider />

        { (myParcels.length <= 0)  
            ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '60%',}}>
                <Typography component="p" style={{ margin: 'auto', textAlign: 'center'}}>
                    No data found. Try selecting a different time period.
                </Typography>
            </div>
            : <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start', minHeight: '60%',}}>
            <InfiniteScroll
                dataLength={myParcels.length}
                next={getMyParcles}
                hasMore={canRequestMore}
                loader={<CircularProgress size={40} color="primary" />}
                scrollThreshold={0.8}
                style={{height:'auto',paddingBottom:theme.spacing(3)}}
                
            >
                {myParcels.map(elm => <ParcelList key={elm.id} item={elm}/>)}
            </InfiniteScroll>
            </div>
        }
         </Box>
    )
}
export default MyParcels;