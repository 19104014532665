import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

import AccodionFAQ from "../../component/AccodionFAQ";

const useStyles = makeStyles(theme => ({
    accordionWrapper: {
        backgroundColor: theme.palette.grey['100'],
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(5),
        // paddingLeft: '15%',
        // paddingRight: '15%',
    },
}));

const ParcelFAQ =() =>{
    const classes = useStyles();
    return(
        <div className={classes.accordionWrapper}>
            <Container>
                <Typography variant='h5' sx={{textAlign:'center'}}>Sending a parcel</Typography>
                <AccodionFAQ
                    question={'How does the real-time tracking work?'}
                    answer={`Every parcel delivered by us is geo-tagged and
                            assigned a unique code. Whenever the parcel is
                            picked up by one of our delivery partners it
                            sends its location to our secure servers. The
                            location is constantly updated and is available
                            to both you and your customers. To see the
                            current location of a parcel, go to 'My Parcels'
                            in the menu and select the parcel. The location
                            will appear on the map.`}
                />

                <AccodionFAQ
                    question={'How do I know that my parcel was delivered?'}
                    answer={`Parcels are only delivered to the customer
                    provided address and left with the customer or a
                    member of the household. If special instructions
                    are provided to leave the parcel at a secure
                    location, we take pictures of the delivery and
                    capture the exact GPS coordinates to confirm the
                    parcel was delivered as instructed.`}
                />

                <AccodionFAQ
                    question={'What if the customer is not at home?'}
                    answer={`We can leave the parcel at a secure location or
                    with a neighbour if the customer has provided
                    this information in the delivery instructions.
                    Any undelivered parcels are left at a secure
                    collection point for the customer to collect.`}
                />

                <AccodionFAQ
                    question={'How can I reduce my delivery cost even further?'}
                    answer={`Using a collection point as the delivery address
                    can reduce parcel costs. We have a number of
                    collection points across the country for your
                    customer convenience`}
                />

                
            </Container>
        </div>
    )
}

export default ParcelFAQ;