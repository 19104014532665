import React, { useEffect, useReducer, useContext } from 'react';
import Alert from '@mui/material/Alert';
import { usePayPalScriptReducer, getScriptID, destroySDKScript, } from "@paypal/react-paypal-js";
import { useHistory } from 'react-router-dom';

import { PAYPAL_OPTIONS } from '../../AppConstants';
import Stepper from "../../component/Stepper"
import PayPalButtonWrapper from '../../component/PayPalButtonWrapper';
import { PackageContext } from '../../context/PackageContext';
import { PaymentContext } from '../../context/PaymentContext';
import { AuthContext } from '../../context/AuthContext';
import { NavContext } from '../../context/NavContext';
import { Typography } from '@mui/material';


function ParcelPayment() {
    const {
        fromName,
        fromContactEmail,
        toName,
        pickupDate,
        pickupTime,
    } = useContext(PackageContext);
    const {
        parcelId,
        setParcelId,
    } = useContext(PaymentContext);
    const {
        user,
        setParcelPaymentComplete,
    } = useContext(AuthContext);
    const {
        setLoginModalOpen,
    } = useContext(NavContext);

    const [{ isResolved }, dispatch] = usePayPalScriptReducer();
    const [{ error, errorMessage}, setState] = useReducer(
        (prev, next) => ({ ...prev, ...next }),
        {
            error:false,
            errorMessage:''
        },
    );
    const history = useHistory();


    useEffect(()=>{
        destroySDKScript(getScriptID(PAYPAL_OPTIONS));
        dispatch({
            type: "setLoadingStatus",
            value: "pending",
        });
    },[]);

    const addUserToDb = (e) =>{
        e.preventDefault();
        setParcelPaymentComplete({parcelId:parcelId,shouldAddUserToDB:true})
        setLoginModalOpen(true)
    }

    const noThanks = (e) =>{
        e.preventDefault();
        setParcelPaymentComplete({parcelId:'',shouldAddUserToDB:false});
        history.push('/');
    }

    if (parcelId !== '') return(
        <>
            <Typography variant={'h4'} sx={{textAlign:'center', marginTop:'8px'}}>Your order is complete!</Typography>
            <Typography sx={{textAlign:'center'}}>You will receive a confirmation email with order details soon.</Typography>
            <Typography sx={{textAlign:'center'}}>Your order number is: <strong>{parcelId}</strong> </Typography>
            <Stepper
                pickupDate={pickupDate}
                pickupTime={pickupTime}
                parcelId={parcelId}
                showLogin={user ? false : true}
                addUserToDb={addUserToDb}
                noThanks={noThanks}
            />
        </>
    )
    return (
        <>
            { error && <Alert severity="error" onClose={() => setState({error:false,errorMessage:''})} style={{ marginBottom: '16px' }}>{errorMessage}</Alert>}
            {isResolved && <div>  {/*style={{display:'flex', justifyContent:'center'}}>*/}
                    <PayPalButtonWrapper
                    setError={setState}
                    disabled={fromName.trim().length<=0||toName.trim().length<=0||fromContactEmail.trim().length<=0}
                    setPaymentId={setParcelId}
                    setParcelId={setParcelId}
                />
            </div>}
        </>
    );
}

export default ParcelPayment;
