import { useContext, useState } from 'react';

import Typography from '@mui/material/Typography';

import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { GoogleMapViewContext } from '../../../context/GoogleMapViewContext';
import CustomGoogleMapsContainer from '../CustomGoogleMapsContainer';
import SendHomeForm from './SendHomeForm';
// import ReturnHomeForm from './ReturnHomeForm';
import TrackHomeForm from './TrackHomeForm';

function HomeForm() {
    const theme = useTheme();
    const isMd = useMediaQuery(theme.breakpoints.down('md'));

    const { viewMap, setViewMap, updateToSelectedLocation, updateFromSelectedLocation } =
        useContext(GoogleMapViewContext);

    const [activeTab, setActiveTab] = useState(0);

    const clearSendForm = () => {
        updateToSelectedLocation('');
        updateFromSelectedLocation('');
    };

    return (
        <>
            {!isMd && <Box>{viewMap && <CustomGoogleMapsContainer />}</Box>}

            <Paper
                elevation={0}
                sx={{
                    display: 'flex',
                    // width: `${!isMd ? "50%" : "100%"}`,
                    backgroundColor: 'white',
                    flexDirection: 'column',
                    border: '0px',
                }}
            >
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                    <Box
                        sx={{
                            cursor: 'pointer',
                            padding: '28px',
                            borderBottom: `${activeTab === 0 ? '4px solid #00BA4B' : 0}`,
                        }}
                        onClick={() => setActiveTab(0)}
                    >
                        <Typography>Send</Typography>
                    </Box>

                    <Box
                        sx={{
                            cursor: 'pointer',
                            padding: '28px',
                            borderBottom: `${activeTab === 2 ? '4px solid #00BA4B' : 0}`,
                        }}
                        onClick={() => {
                            setActiveTab(2);
                            setViewMap(false);
                            clearSendForm();
                        }}
                    >
                        <Typography>Track</Typography>
                    </Box>

                    {/* <Box
                        sx={{
                            cursor: 'pointer',
                            padding: '28px',
                            borderBottom: `${activeTab === 1 ? '4px solid #00BA4B' : 0}`,
                        }}
                        onClick={() => {
                            setActiveTab(1);
                            setViewMap(false);
                            clearSendForm();
                        }}
                    >
                        <Typography>Return</Typography>
                    </Box> */}
                </Box>

                <Divider />

                {activeTab === 0 && <SendHomeForm />}
                {/* {activeTab === 1 && <ReturnHomeForm />} */}
                {activeTab === 2 && <TrackHomeForm />}
            </Paper>
        </>
    );
}

export default HomeForm;
