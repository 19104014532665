import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

export default function CustomCard(props) {
    let elevation = 0;
    if (props?.elevation) {
        elevation = props.elevation;
    }

    return (
        <Card sx={{ minWidth: '200px' }} variant="elevation" elevation={elevation} {...props}>
            <CardContent>{props.children}</CardContent>
        </Card>
    );
}
