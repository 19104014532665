import React, { createContext, useState } from 'react';

const PaymentContext = createContext();

const { Provider } = PaymentContext;

function PaymentProvider({ children }) {
    const [parcelId, setParcelId] = useState('');
    const value = {
        parcelId,
        setParcelId,
    };

    return <Provider value={value}>{children}</Provider>;
}

export { PaymentContext, PaymentProvider };
