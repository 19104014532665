import React from 'react';

import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { useMediaQuery } from '@mui/material';

import { convertDate } from "../../utils";

function PackagePickupTime(props) {
    const {
        pickupTimes,
        pickupTimesCharge,
        currencySymbol,
        pickupDate,
        setPickupDate,
        pickupTime,
        setPickupTime,
    } = props;


    const theme = useTheme();
    const isMd = useMediaQuery(theme.breakpoints.down('sm'));

    const dates=[1,2,3,4,5,6].map(elm=>{
        var newDate = new Date(); 
        newDate.setDate(newDate.getDate()+elm); 
        return {date:newDate, formatDate:convertDate(newDate)}
    });
    return (
        <Paper
            onClick={() => {
            }}
            sx={{
                // cursor: 'pointer',
                height: '100%',
                width: '100%',
                fontWeight: {
                    sm: 600,
                    md: 500,
                },
            }}
        >
            <Typography style={{ paddingLeft: '16px', paddingRight: '16px', paddingTop: '16px' }}>Pickup date</Typography>
            <Grid container spacing={2} style={{ padding: '16px' }}>
                {dates.map(({date,formatDate})=>{
                    return(
                        <Grid 
                            item 
                            key={date}
                            xs={12} 
                            sm={6} 
                            md={4}
                            lg={4} 
                            style={{ height: 'auto',justifyContent:'center',display:'flex', }}
                            >
                            <Button
                                disableElevation
                                variant={formatDate === pickupDate ? 'contained' : 'outlined'}
                                style={{ minWidth: isMd ? '200px' : '100%'}}
                                color={'secondary'}
                                onClick={()=>setPickupDate(formatDate)}
                            >
                                {formatDate}
                            </Button>
                        </Grid>
                    )
                })}
            </Grid>

            <Typography style={{ paddingLeft: '16px', paddingRight: '16px'}}>Pickup time</Typography>
            <Grid container spacing={2} style={{ padding: '16px' }}>
                {pickupTimes.map((elm,index)=>{
                    return(
                        <Grid 
                            item 
                            key={elm}
                            xs={12} 
                            sm={6} 
                            md={4}
                            lg={4} 
                            style={{ height: 'auto',justifyContent:'center',display:'flex', }}
                            >
                            <Button
                                disableElevation
                                variant={elm === pickupTime ? 'contained' : 'outlined'}
                                style={{ minWidth: isMd ? '200px' : '100%'}}
                                color={'secondary'}
                                onClick={()=>setPickupTime(elm)}
                            >
                                {`${elm} (+ ${currencySymbol}${pickupTimesCharge[index].toFixed(2)})`}
                            </Button>
                        </Grid>
                    )
                })}
            </Grid>
        </Paper>
    );
}

export default PackagePickupTime;
