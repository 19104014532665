import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { useState } from 'react';

import AboutFAQ from './AboutFAQ';
import EarnFAQ from './EarnFAQ';
import ParcelFAQ from './ParcelFAQ';

import parcelImage from "../../images/Who-We-Serve-Courier-Service-1080x675.jpeg";
import zkootieMoney from "../../images/zkootie_money_900_x_760.jpg";
import helpDesk from "../../images/help_desk_450_x_300.jpg";

const Help =() =>{
    const [showBack, setShowBack] = useState(false);
    const [displayFAQ, setDisplayFAQ] = useState('');

    const resetState = () =>{
        setShowBack(false);
        setDisplayFAQ('')
    }

    const showFAQ = (val) =>{
        setShowBack(true);
        setDisplayFAQ(val)
    }

    return(
        <>
        <Container sx={{mt:3}}>
            <Typography variant='h4' sx={{textAlign:'center'}}>Welcome to Zkootie Support</Typography>
            <Typography variant='subtitle1' sx={{textAlign:'center',mt:3,mb:3}}>How can we help?</Typography>
        </Container>
            { showBack 
            ? <>
                <Container sx={{mt:3}}>
                    <Button variant='text' startIcon={<ChevronLeftIcon />} size='large' onClick={resetState}>Back</Button>
                </Container>
                {displayFAQ === 'about' && <div>
                    <AboutFAQ />
                </div>}
                {displayFAQ === 'earn' && <div>
                    <EarnFAQ />
                </div>}
                {displayFAQ === 'parcel' && <div>
                    <ParcelFAQ />
                </div>}
            </>
            :<Container sx={{mt:3}}>
            <Grid container spacing={2} style={{ padding: '16px' }}>
                <Grid  item  md={4} sx={{width:'100%'}}>
                    <Card sx={{ }} onClick={()=>showFAQ('parcel')}>
                        <CardActionArea>
                            <CardMedia component="img" height="200" image={parcelImage} alt="parcels" />
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="div">
                                    Parcels
                                </Typography>
                                <Typography variant="body2" color="text.secondary" sx={{height:50}}>
                                    Help with sending or receiving parcels
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid  item  md={4} sx={{width:'100%'}}>
                    <Card sx={{ }} onClick={()=>showFAQ('earn')}>
                        <CardActionArea>
                            <CardMedia component="img" height="200" image={zkootieMoney} alt="Earning with Zkootie" />
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="div">
                                    Earning with Zkootie
                                </Typography>
                                <Typography variant="body2" color="text.secondary" sx={{height:50}}>
                                    Help with driving for Zkootie or becoming a drop-off and collection partner.
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid  item  md={4} sx={{width:'100%'}}>
                    <Card sx={{ }} onClick={()=>showFAQ('about')}>
                        <CardActionArea>
                            <CardMedia component="img" height="200" image={helpDesk} alt="help desk" />
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="div">
                                    Help Desk
                                </Typography>
                                <Typography variant="body2" color="text.secondary" sx={{height:50}}>
                                    Help with your account, billing or anything else.
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
            </Grid>
            </Container>
            }
        
        </>
    )
}

export default Help;