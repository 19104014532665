import React, { createContext, useEffect, useState } from 'react';
import {
    browserSessionPersistence,
    createUserWithEmailAndPassword,
    FacebookAuthProvider,
    getAuth,
    GoogleAuthProvider,
    indexedDBLocalPersistence,
    linkWithPopup,
    unlink,
    onAuthStateChanged,
    sendEmailVerification,
    sendPasswordResetEmail,
    setPersistence,
    signInWithEmailAndPassword,
    signInWithPopup,
    signOut,
    updateProfile,
    updatePassword,
} from 'firebase/auth';
import { 
    getFirestore, 
    doc, 
    getDoc, 
    onSnapshot, 
} from "firebase/firestore";
import { useHistory } from 'react-router-dom';
import firebaseCodeToMessage from '../utils/firebaseCodeToMessage';
import { getApp } from 'firebase/app';
import { getFunctions, httpsCallable } from 'firebase/functions';
import {actionCodeSettings} from '../config';
import firebaseConfig from '../firebaseConfig';

const AuthContext = createContext();
const { Provider } = AuthContext;

const googleAuthProvider = new GoogleAuthProvider();

const facebookAuthProvider = new FacebookAuthProvider();
facebookAuthProvider.addScope('public_profile');
facebookAuthProvider.addScope('email');

function AuthProvider(props) {
    const app = getApp();
    const auth = getAuth();
    const db = getFirestore(app);
    const { region } = firebaseConfig;
    const functions = getFunctions(app,region);
    const [user, setUser] = useState(null);
    const [dob, setDob] = useState({date:'',month:'',year:''});
    const [gender, setGender] = useState('');
    const [userAttributes, setUserAttributes] = useState({});
    const [authError, setAuthError] = useState({ error: '', message: '' });
    const [socialAuthError, setSocialAuthError] = useState({ error: '', message: '' });
    const [updatePasswordError, setUpdatePasswordError] = useState({ error: '', message: '' });
    const [isAuthInProgress, setIsAuthInProgress] = useState(false);
    const [isSocialAuthInProgress, setIsSocialAuthInProgress] = useState('');
    const [parcelPaymentComplete, setParcelPaymentComplete] = useState({parcelId:'',shouldAddUserToDB:false});
    const history = useHistory();

    useEffect(() => {
        const subscriber = onAuthStateChanged(auth, async (user) => {
            if (user) {
                try{
                    const docRef = doc(db,'users',user.uid);
                    const userAttributesSnapshot = await getDoc(docRef);//     firestore().collection('users').doc(user.uid).get();
                    if (userAttributesSnapshot.exists()){
                        const attbs=userAttributesSnapshot.data();
                        if (attbs?.profile){
                            setUserAttributes({
                                profile:attbs?.profile,
                            });
                            setDob({
                                date:attbs?.profile?.dateOfBirth?.date||'',
                                month:attbs?.profile?.dateOfBirth?.month||'',
                                year:attbs?.profile?.dateOfBirth?.year||''
                            });
                            setGender(attbs?.profile?.gender||'')
                        } else {
                            const profile={
                                displayName:user?.displayName,
                                photoURL:user?.photoURL,
                                email:user?.email
                            }
                            // console.log(profile)
                            if (user?.displayName) {
                                await updateUserProfile(profile);
                            }
                        }
                        
                    } else {
                        // console.log(user)
                        const profile={
                            displayName:user?.displayName,
                            photoURL:user?.photoURL,
                            email:user?.email
                        }
                        // console.log(profile)
                        if (user?.displayName) {
                            await updateUserProfile(profile);
                        }
                    }
                } catch(error){
                    console.log(error);
                }
            }
            setUser(user);
            setUpdatePasswordError({ error: '', message: '' });
            setAuthError({ error: '', message: '' });
        });
        return () => subscriber();
    // }, [isAuthInProgress,isSocialAuthInProgress]);
    },[]);

    useEffect(()=>{
        async function shouldAddUserToDB(){
            if (parcelPaymentComplete.shouldAddUserToDB){
                const updatedPraceluser  = httpsCallable(functions, 'payment-updateParcelUser');
                await updatedPraceluser({
                    parcelId: parcelPaymentComplete.parcelId,
                    user_id: user.uid,
                });
                setParcelPaymentComplete({
                    parcelId: '',
                    shouldAddUserToDB: false,
                })
            }
        }
        shouldAddUserToDB();

        if (user){
            const docRef = doc(db,'users',user.uid);
            const unsubscribe = onSnapshot(docRef,(doc)=>{
                console.log('snapshot')
                const userAttributes= doc.data();
                setUserAttributes({
                    profile:userAttributes?.profile,
                });
                setDob({
                    date:userAttributes?.profile?.dateOfBirth?.date||'',
                    month:userAttributes?.profile?.dateOfBirth?.month||'',
                    year:userAttributes?.profile?.dateOfBirth?.year||''
                });
                setGender(userAttributes?.profile?.gender||'')
            })

            return ()=>unsubscribe();
        } else {
            return ()=>{};
        }
    },[user]);

    // const updateUserNameAndPhone = async (name, phone='') => {
    //     if (name === null && phone === null) return;

    //     if (name !== null) {
    //         await updateProfile(auth.currentUser, { displayName: name });
    //         setUser(
    //             Object.assign({}, auth.currentUser, {
    //                 displayName: name,
    //             }),
    //         );
    //     }

        // if (phone!==null) await updatePhoneNumber(auth.currentUser, )
    // };

    const updateUserProfilePic = async (photoURL) => {
        if (photoURL === null) return;
        const profile={
            photoURL:photoURL
        }
        await updateUserProfile(profile);

        // await updateProfile(auth.currentUser, { photoURL: photoURL });
        // setUser(
        //     Object.assign({}, auth.currentUser, {
        //         photoURL: photoURL,
        //     }),
        // );
    };

    const updateUserProfile = async(profile) =>{
        try{
        const updateUserProfile = httpsCallable(functions, 'auth-updateUserProfile');
        const response=await updateUserProfile({profile:profile});
        if (response.data.status !== 'OK'){
            return false;
        }
        // console.log(profile)
        const newProfile={
            ...userAttributes.profile,...profile
        }
        // console.log(newProfile);
        setUserAttributes({profile:newProfile});
        return true;

        } catch(error){
            console.log(error);
            return false;
        }
    }

    const signUpWithUsernamePassword = async (name, email, password) => {
        setIsAuthInProgress(true);
        let returnValue = '';
        try{
            const userCredential = await createUserWithEmailAndPassword(auth, email, password)
            await updateProfile(userCredential.user, { displayName: name });
            const profile={
                displayName:name,
            }
            await updateUserProfile(profile);
            setUser(
                Object.assign({}, userCredential.user, {
                    displayName: name,
                }),
            );
            await sendEmailVerification(userCredential.user, actionCodeSettings);
        }
        catch(error){
                setAuthError({ error: error.code, message: firebaseCodeToMessage(error.code) });
                returnValue = error.code;
            }
        finally{
            setIsAuthInProgress(false)
        };

        return returnValue;
        // } catch (error) {
        //     setAuthError({ error: error.code, message: firebaseCodeToMessage(error.code) });
        // } finally {
        //     setIsAuthInProgress(false);
        // }
    };

    const sendVerificationEmail = async () => {
        setIsAuthInProgress(true);
        if (user.emailVerified) {
            setAuthError({ error: '', message: 'Email is already verified' });
        } else {
            try {
                await sendEmailVerification(auth.currentUser, actionCodeSettings);
            } catch (error) {
                setAuthError({ error: error.code, message: firebaseCodeToMessage(error.code) });
            } finally {
                setIsAuthInProgress(false);
            }
        }
    };

    const loginWithGoogle = async () => {
        setIsSocialAuthInProgress('GOOGLE');
        try {
            const u = await signInWithPopup(auth, googleAuthProvider);
            // const u = signInWithPopup( googleAuthProvider );
            setUser(u.user);
        } catch (error) {
            var message;
            if  (error.code==='auth/account-exists-with-different-credential'){
                message='A Zkootie account with this email already exists. Please login first and add Google as an additional login option from account settings.';
            } else if (error.code==='auth/invalid-credential'){
                message=`Unable to identify your credentials with Google. Try updating your Google password and make sure that your device's date and time are set correctly. If the issue persists try reinstalling the app or contact Zkootie.`;
            } else {
                message=firebaseCodeToMessage(error.code);
            }
            setSocialAuthError({ error: error.code, message });
        } finally {
            setIsSocialAuthInProgress('');
        }
    };

    const linkWithGoogle = async (link=true) => {
        setIsSocialAuthInProgress('GOOGLE');
        try {
            if (link) {
                const u = await linkWithPopup(auth.currentUser, googleAuthProvider);
                setUser(u.user);
            } else {
                await unlink(auth.currentUser, 'google.com');
            }
            return true;
        } catch (error) {
            console.log(error)
            setSocialAuthError({ error: error.code, message: firebaseCodeToMessage(error.code) });
            return false;
        } finally {
            setIsSocialAuthInProgress('');
        }
    };

    const linkWithFacebook = async (link=true) => {
        setIsSocialAuthInProgress('FACEBOOK');
        try {
            if (link) {
                const u = await linkWithPopup(auth.currentUser, facebookAuthProvider);
                setUser(u.user);
            } else {
                await unlink(auth.currentUser, 'facebook.com');
            }
            return true;
        } catch (error) {
            setSocialAuthError({ error: error.code, message: firebaseCodeToMessage(error.code) });
            return false;
        } finally {
            setIsSocialAuthInProgress('');
        }
    };

    const forgotPassword = async emailOrPhone => {
        setIsAuthInProgress(true);
        setAuthError({ error: '', message: '' });
        try {
            await sendPasswordResetEmail(auth, emailOrPhone, actionCodeSettings);
            return true;
        } catch (error) {
            setAuthError({ error: error.code, message: firebaseCodeToMessage(error.code) });
            return false;
        } finally {
            setIsAuthInProgress(false);
        }
    };

    const loginWithFacebook = async () => {
        setIsSocialAuthInProgress('FACEBOOK');
        try {
            const u = await signInWithPopup(auth, facebookAuthProvider);
            setUser(u.user);
        } catch (error) {
            var message;
            if  (error.code==='auth/account-exists-with-different-credential'){
                message='A Zkootie account with this email already exists. Please login first and add Facebook as an additional login option from account settings.';
            } else if (error.code==='auth/invalid-credential'){
                message=`Unable to identify your credentials with Facebook. Try updating your Facebook password and make sure that your device's date and time are set correctly. If the issue persists try reinstalling the app or contact Zkootie.`;
            } else {
                message=firebaseCodeToMessage(error.code);
            }
            setSocialAuthError({ error: error.code, message: message });
        } finally {
            setIsSocialAuthInProgress('');
        }
        // .then((u) => {
        //     setUser(u)
        //     history.push("/")
        // }).catch((error) => {
        //     setAuthError({error: error.code, message: firebaseCodeToMessage(error.code)})
        // }).finally(() => {
        //     setIsAuthInProgress(false)
        // })
    };

    const loginWithEmailAndPassword = async (email, password, rememberMe) => {
        setIsAuthInProgress(true);
        try {
            await setPersistence(auth, rememberMe ? indexedDBLocalPersistence : browserSessionPersistence);
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            setUser(userCredential.user);
        } catch (error) {
            await setAuthError({ error: error.code, message: firebaseCodeToMessage(error.code) });
        } finally {
            setIsAuthInProgress(false);
        }
        //     .then((userCredential) => {
        //     history.push("/")
        // }).catch((error) => {
        //     setAuthError({error: error.code, message: firebaseCodeToMessage(error.code)})
        // }).finally(() => {
        //     setIsAuthInProgress(false)
        // })
    };

    const signOutUser = () => {
        setIsAuthInProgress(true);
        setParcelPaymentComplete({parcelId:'',shouldAddUserToDB:false});
        signOut(auth)
            .then(() => {
                setUser(null);
                history.push('/');
            })
            .catch(error => {
                setAuthError({ error: error.code, message: firebaseCodeToMessage(error.code) });
            })
            .finally(() => {
                setIsAuthInProgress(false);
            });
    };

    const updateUserPassword = async (password) =>{
        setUpdatePasswordError({ error: '', message: '' });
        try{
            await updatePassword(user, password);
            return true;
        } catch(error){
            setUpdatePasswordError({ error: error.code, message: firebaseCodeToMessage(error.code) });
            return false;
        }
    }

    return (
        <Provider
            value={{
                user,
                userAttributes,
                authError,
                setAuthError,
                socialAuthError,
                isSocialAuthInProgress,
                isAuthInProgress,
                forgotPassword,
                signOut: signOutUser,
                loginWithEmailAndPassword,
                loginWithGoogle,
                loginWithFacebook,
                linkWithGoogle,
                linkWithFacebook,
                signUpWithUsernamePassword,
                sendVerificationEmail,
                // updateUserNameAndPhone,
                updateUserProfilePic,
                dob, 
                setDob,
                gender, 
                setGender,
                updateUserProfile,
                updatePasswordError,
                updateUserPassword,
                parcelPaymentComplete, 
                setParcelPaymentComplete
            }}
        >
            {props.children}
        </Provider>
    );
}

export { AuthContext, AuthProvider };
