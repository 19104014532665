import React from 'react';
import { makeStyles } from '@mui/styles';
// import { useLocation } from 'react-router-dom';

import AccodionFAQ from "../../../component/AccodionFAQ";

const useStyles = makeStyles(theme => ({
    accordionWrapper: {
        // backgroundColor: theme.palette.grey['100'],
        // paddingTop: theme.spacing(3),
        // paddingBottom: theme.spacing(5),
    },
}));

const EarnMoneyDrivingFAQ = () =>{
    const classes = useStyles();

    return(
        <div className={classes.accordionWrapper}>
            <AccodionFAQ
                question={'Who can join?'}
                answer={`Anyone with a valid licence, no unspent driving convictions and access to a vehicle can signup.`}
            />

            <AccodionFAQ
                question={'What vehicle do I need?'}
                answer={`You need to have access to a moped, car or van. We will be adding more vehicle
                classes such as small trucks and lorries soon.`}
            />

            <AccodionFAQ
                question={'Do I need special training or equipment in order to start?'}
                answer={`No you do not need any special training. Delivering with us is similar to using any mobile based 
                navigation apps so a phone running Android or iPhone is required. There are video tutorials to
                help you get started once your registration is complete.`}
            />

            <AccodionFAQ
                question={'How do I start delivering?'}
                answer={`After your registration is complete, you can go to the 'Drive' section of the app and look for delivery 
                blocks available near you. Each block has the start time, the money you will earn and the time it will take 
                to deliver it. You can accept the block you like and start delivering.`}
            />
        </div>
    )
}

export default EarnMoneyDrivingFAQ;