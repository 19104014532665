import { useReducer, useContext } from 'react';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Switch from '@mui/material/Switch';
// import { useTheme } from '@mui/material/styles';
import { BsGoogle as GoogleIcon } from 'react-icons/bs';
import { BsFacebook as FacebookIcon } from 'react-icons/bs';

import ThemedLoadingButton from "../Buttons/ThemedLoadingButton";
import { AuthContext } from '../../context/AuthContext';

const SocialMediaConnect = () => {
    const { user, linkWithGoogle, linkWithFacebook, socialAuthError } = useContext(AuthContext);
    // console.log(socialAuthError)
    // const theme = useTheme();
    var signinMethods=user?.providerData ? user?.providerData.map(providers=>providers.providerId) : [];
    const [state, setState] = useReducer(
        (prev, curr) => ({ ...prev, ...curr }),
        {
            loadingGoogle:false,
            errorGoogle:false,
            errorMessageGoogle:'',
            hasGoogleSignup:signinMethods.indexOf('google.com') >=0,
            successMessageGoogle:'',

            loadingFacebook:true,
            errorFacebook:false,
            errorMessageFacebook:'',
            hasFacebookSignup:signinMethods.indexOf('facebook.com') >=0,
            successMessageFacebook:'',
        },
    );
    const {loadingGoogle,errorGoogle,errorMessageGoogle,hasGoogleSignup, successMessageGoogle,
        loadingFacebooK,errorFacebook,errorMessageFacebook,hasFacebookSignup,successMessageFacebook  } = state;

    const addRemoveGoogle = async (e) =>{
        e.preventDefault();
        const add = e.target.checked;
        setState({
            loadingGoogle:true,
            errorGoogle:false,
            errorMessageGoogle:'',
            hasGoogleSignup:!hasGoogleSignup,
            successMessageGoogle:'',
        });
        try{
            if (add){
                let res = await linkWithGoogle();
                if (res){
                    setState({successMessageGoogle:'Connected!'});
                    setTimeout(() => {
                        setState({successMessageGoogle:''})
                    }, 1500);
                } else{
                    setState({errorGoogle:true,hasGoogleSignup:!add});
                }
            } else {
                // before removing Google check that other log in providers exist
                if (signinMethods.length<=1){
                    setState({
                        errorGoogle:true,
                        errorMessageGoogle:'Unable to disconnect Google as this is your only login option. If you do not want to use Google then you can either connect your Facebook account or update password to use your email and password as the future login option. If you want to delete your account then use the Delete Account button instead.',
                        hasGoogleSignup:!add
                    });
                } else {
                    let res = await linkWithGoogle(false);
                    if (res){
                        setState({successMessageGoogle:'Disconnected!'})
                        setTimeout(() => {
                            setState({successMessageGoogle:''})
                        }, 1500)
                    } else{
                        setState({errorGoogle:true,hasGoogleSignup:!add});
                        
                    }
                }
            }
        }catch(error){
            console.log(error);
            setState({
                errorGoogle:true,
                errorMessageGoogle:'Something went wrong. Please try again!',
                hasGoogleSignup:!add
            });
        } finally{
            setState({
                loadingGoogle:false,
            });
        }  
    }

    const addRemoveFacebook = async (e) =>{
        e.preventDefault();
        const add = e.target.checked;
        setState({
            loadingFacebook:true,
            errorFacebook:false,
            errorMessageFacebook:'',
            hasFacebookSignup:!hasFacebookSignup,
            successMessageFacebook:'',
        });
        try{
            if (add){
                let res = await linkWithFacebook();
                if (res){
                    setState({successMessageFacebook:'Connected!'});
                    setTimeout(() => {
                        setState({successMessageFacebook:''})
                    }, 1500);
                } else{
                    setState({errorFacebook:true,hasFacebookSignup:!add});
                }
            } else {
                // before removing Facebook check that other log in providers exist
                if (signinMethods.length<=1){
                    setState({
                        errorFacebook:true,
                        errorMessageFacebook:'Unable to disconnect Facebook as this is your only login option. If you do not want to use Facebook then you can either connect your Google account or update password to use your email and password as the future login option. If you want to delete your account then use the Delete Account button instead.',
                        hasFacebookSignup:!add
                    });
                } else {
                    let res = await linkWithFacebook(false);
                    if (res){
                        setState({successMessageFacebook:'Disconnected!'})
                        setTimeout(() => {
                            setState({successMessageFacebook:''})
                        }, 1500)
                    } else{
                        setState({errorFacebook:true,hasFacebookSignup:!add});
                        
                    }
                }
            }
        }catch(error){
            console.log(error);
            setState({
                errorFacebook:true,
                errorMessageFacebook:'Something went wrong. Please try again!',
                hasFacebookSignup:!add
            });
        } finally{
            setState({
                loadingFacebooK:false,
            });
        }  
    }

    return (
        <FormControl fullWidth={true} variant="outlined">
            <FormLabel sx={{ fontWeight: 600, marginBottom:'8px' }}>Connect Accounts</FormLabel>
                <Box 
                    sx={{
                        flexDirection:'row',
                        alignItems:'center',
                        justifyContent:'center',
                        mb: '8px',
                    }}
                >
                    <ThemedLoadingButton
                        sx={{
                            width:'200px',
                            color:'background.default',
                            bgcolor: 'google.main',
                            borderColor:'google.main',
                        }}
                        disableRipple
                        variant="outlined"
                        startIcon={<GoogleIcon />}
                        color="inherit"
                        disabled={loadingGoogle || loadingFacebooK}
                        loading={loadingGoogle}
                    >
                        Google
                    </ThemedLoadingButton>
                    <Switch checked={hasGoogleSignup} color={'primary'} onChange={addRemoveGoogle} disabled={loadingGoogle || loadingFacebooK} />
                </Box>
                {successMessageGoogle !== '' && <Alert onClose={()=>setState({successMessageGoogle:''})} severity="success">{successMessageGoogle}</Alert>}
                {errorGoogle && <Alert onClose={()=>setState({errorGoogle:false})} severity="error" >{errorMessageGoogle!== '' ? errorMessageGoogle : socialAuthError.message}</Alert>}

                <Box 
                    sx={{
                        flexDirection:'row',
                        alignItems:'center',
                        justifyContent:'center',
                        mt: '8px',
                        mb: '8px',
                    }}
                >
                    <ThemedLoadingButton
                        sx={{
                            width:'200px',
                            color:'background.default',
                            bgcolor: 'facebook.main',
                            borderColor:'facebook.main',
                        }}
                        disableRipple
                        variant="outlined"
                        startIcon={<FacebookIcon />}
                        color="inherit"
                        disabled={loadingGoogle || loadingFacebooK}
                        loading={loadingFacebooK}
                    >
                        Facebook
                    </ThemedLoadingButton>
                    <Switch checked={hasFacebookSignup} color={'primary'} onChange={addRemoveFacebook} disabled={loadingGoogle || loadingFacebooK} />
                </Box>
                {successMessageFacebook !== '' && <Alert onClose={()=>setState({successMessageFacebook:''})} severity="success">{successMessageFacebook}</Alert>}
                {errorFacebook && <Alert onClose={()=>setState({errorFacebook:false})} severity="error" >{errorMessageFacebook!== '' ? errorMessageFacebook : socialAuthError.message}</Alert>}

        </FormControl>
    );
}

export default SocialMediaConnect;
