import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { getApp } from 'firebase/app';
import { getFunctions } from 'firebase/functions';
import { httpsCallable } from 'firebase/functions';
import { useState } from 'react';

import firebaseConfig from '../../firebaseConfig';

function DataSubjectAccessRequest(){
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [personOrAgent, setPersonOrAgent] = useState(null);
    const [jurisdiction, setJurisdiction] = useState('');
    const [submissionReason, setSubmissionReason] = useState('');
    const [additioanlInfo, setAddditioanlInfo] = useState('');
    const [checked0, setChecked0] = useState('');
    const [checked1, setChecked1] = useState('');
    const [checked2, setChecked2] = useState('');    
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    
    const app = getApp();
    const { region } = firebaseConfig;

    const onSubmit = async () => {
        setLoading(true);
        setError(false);
        setSuccess(false);
        try{
            const functions = getFunctions(app,region);
            const dataSubjectRequest = httpsCallable(functions, 'csp-dataSubjectRequest');
            const result = await dataSubjectRequest({ 
                name,email,personOrAgent,jurisdiction,submissionReason,additioanlInfo
            });

            if (result.data.status === 'OK'){
                setSuccess(true);
            } else {
                setError(true);
            }
        } catch{
            setError(true);
        } finally{
            setLoading(false);
        }
    }

    return(
        <Container>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection:'column',
                    alignItems:'center',
                    mt:theme=>theme.spacing(2),
                    mb:theme=>theme.spacing(2)
                }}
            >
                <Typography variant='h4'>Data Subject Access Request Form</Typography>
                <Typography variant='caption'>Please fill in the information below. The data protection officer will be notified of your request within 24 hours, and will need an appropriate amount of time to respond.</Typography>
            </Box>

            <FormControl fullWidth={true} variant="outlined" sx={{mb:theme=>theme.spacing(2)}}>
                <Typography variant={'h6'} sx={{mb:theme=>theme.spacing(1)}}>Your name</Typography>
                <TextField
                    name={'name'}
                    value={name}
                    onChange={e=>setName(e.target.value)}
                    autoComplete={'name'}
                    placeholder={'Enter your name'}
                    InputProps={{
                        sx:{
                               bgcolor: 'background.default',
                            }
                    }}
                />
            </FormControl>

            <FormControl fullWidth={true} variant="outlined" sx={{mb:theme=>theme.spacing(2)}}>
                <Typography variant={'h6'} sx={{mb:theme=>theme.spacing(1)}}>What email address do you use to access the above website / app?</Typography>
                <TextField
                    name={'email'}
                    value={email}
                    onChange={e=>setEmail(e.target.value)}
                    autoComplete={'name'}
                    placeholder={'Enter your email address'}
                    InputProps={{
                        sx:{
                               bgcolor: 'background.default',
                            }
                    }}
                />
            </FormControl>

            <FormControl fullWidth={true} variant="outlined" sx={{mb:theme=>theme.spacing(2)}}>
                <Typography variant={'h6'} sx={{mb:theme=>theme.spacing(1)}}>You are submitting this request as</Typography>
                <RadioGroup 
                    column
                    value={personOrAgent}
                    onChange={e=>setPersonOrAgent(e.target.value)}
                >
                    <FormControlLabel value="person" control={<Radio />} label="The person, or the parent / guardian of the person, whose name appears above." />
                    <FormControlLabel value="agent" control={<Radio />} label="An agent authorized by the consumer to make this request on their behalf." />
                </RadioGroup>
            </FormControl>

            <FormControl fullWidth={true} variant="outlined" sx={{mb:theme=>theme.spacing(2)}}>
                <Typography variant={'h6'} sx={{mb:theme=>theme.spacing(1)}}>Under the rights of which law are you making this request?</Typography>
                <RadioGroup 
                    column
                    value={jurisdiction}
                    onChange={e=>setJurisdiction(e.target.value)}
                >
                    <FormControlLabel value="CCPA" control={<Radio />} label="CCPA" />
                    <FormControlLabel value="agent" control={<Radio />} label="GDPR" />
                    <FormControlLabel value="Other" control={<Radio />} label="Other" />
                </RadioGroup>
            </FormControl>

            <FormControl fullWidth={true} variant="outlined" sx={{mb:theme=>theme.spacing(2)}}>
                <Typography variant={'h6'} sx={{mb:theme=>theme.spacing(1)}}>I am submitting a request to ___________</Typography>
                <RadioGroup 
                    column
                    value={submissionReason}
                    onChange={e=>setSubmissionReason(e.target.value)}
                >
                    <FormControlLabel value="Know what information is being collected from me" control={<Radio />} label="Know what information is being collected from me" />
                    <FormControlLabel value="Have my information deleted" control={<Radio />} label="Have my information deleted" />
                    <FormControlLabel value="Access my personal information" control={<Radio />} label="Access my personal information" />
                    <FormControlLabel value="Fix inaccurate information" control={<Radio />} label="Fix inaccurate information" />
                    <FormControlLabel value="Receive a copy of my personal information" control={<Radio />} label="Receive a copy of my personal information" />
                    <FormControlLabel value="Opt out of having my data shared for cross-context behavioral advertising" control={<Radio />} label="Opt out of having my data shared for cross-context behavioral advertising" />
                    <FormControlLabel value="Limit the use and disclosure of my sensitive personal information" control={<Radio />} label="Limit the use and disclosure of my sensitive personal information" />
                    <FormControlLabel value="Other (please specify in the comment box below)" control={<Radio />} label="Other (please specify in the comment box below)" />
                </RadioGroup>
            </FormControl>

            <FormControl fullWidth={true} variant="outlined" sx={{mb:theme=>theme.spacing(2)}}>
                <Typography variant={'h6'} sx={{mb:theme=>theme.spacing(1)}}>Please leave details regarding your action request or question.</Typography>
                <TextField
                    multiline
                    minRows={3}
                    name={'emadditioanlInfoail'}
                    value={additioanlInfo}
                    onChange={e=>setAddditioanlInfo(e.target.value)}
                    autoComplete={'name'}
                    placeholder={'Additional details'}
                    InputProps={{
                        sx:{
                               bgcolor: 'background.default',
                            }
                    }}
                />
            </FormControl>

            <FormControl fullWidth={true} variant="outlined" sx={{mb:theme=>theme.spacing(2)}}>
                <Typography variant={'h6'} sx={{mb:theme=>theme.spacing(1)}}>I confirm that:</Typography>
                <FormControlLabel control={<Checkbox checked={checked0} onChange={e=>setChecked0(e.target.checked)}/>} label="Under penalty of perjury, I declare all the above information to be true and accurate." />
                <FormControlLabel control={<Checkbox checked={checked1} onChange={e=>setChecked1(e.target.checked)}/>} label="I understand that the deletion or restriction of my personal data is irreversible and may result in the termination of services with Zkootie." />
                <FormControlLabel control={<Checkbox checked={checked2} onChange={e=>setChecked2(e.target.checked)}/>} label="I understand that I will be required to validate my request by email, and I may be contacted in order to complete the request." />
            </FormControl>

            <Button
                variant='contained'
                disabled={name.trim() === '' || email.trim() === '' || !personOrAgent || !jurisdiction || !submissionReason || !checked0 || !checked1 || !checked2}
                sx={{
                    mb:3
                }}
                onClick={onSubmit}
                loading={loading}
            >
                Submit
            </Button>

            {error && <Alert onClose={() => setError(false)} severity={"error"} sx={{mb:2}}>Something went wrong. Please try again.</Alert>}
            {success && <Alert onClose={() => setSuccess(false)} severity={"success"} sx={{mb:2}}>Request submitted successfully.</Alert>}

        </Container>
    )
}

export default DataSubjectAccessRequest;