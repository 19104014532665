import { useReducer } from 'react';
import Alert from '@mui/material/Alert';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { getAuth, sendEmailVerification } from 'firebase/auth';

import ThemedLoadingButton from "../Buttons/ThemedLoadingButton";
import ResetPassword from "./ResetPassword";
import SocialMediaConnect from "./SocialMediaConnect";
import DeleteAccount from "./DeleteAccount";
import {actionCodeSettings} from '../../config';

const AccountSettings = ({showBackButton,onBackClick}) => {
    const auth = getAuth();
    const [state, setState] = useReducer(
        (prev, curr) => ({ ...prev, ...curr }),
        {
            sendVerEmailLoading:false,
            sendVerEmailError:false,
            showResetEmailMessage:'',
        },
    );
    const {sendVerEmailLoading, sendVerEmailError, showResetEmailMessage } = state;

    const sendVerificationEmail = async () =>{
        try {
            setState({
                sendVerEmailLoading:true,
                sendVerEmailError:false,
                showResetEmailMessage:''
            })
            await sendEmailVerification(auth.currentUser, actionCodeSettings);
            setState({
                showResetEmailMessage:'Email Sent!'
            });
            setTimeout(() => {
                setState({showResetEmailMessage:''});
            }, 5000);
        } catch (error){
            console.log(error);
            setState({
                sendVerEmailError:true,
                showResetEmailMessage:'Something went wrong!'
            });
        } finally{
            setState({
                sendVerEmailLoading:false,
            });
        }
    }

    return (
        <Stack
            direction="column"
            justifyContent="flex-start"
            alignItems="stretch"
            spacing={2}
            sx={{
                width:'100%'
            }}
        >
            {showBackButton && <Button variant="text" startIcon={<ArrowBackIcon />} sx={{justifyContent:'flex-start',maxWidth:'80px'}} onClick={onBackClick}> Back </Button>}
            <Typography variant={'h6'} sx={{ fontWeight: 600 }}>
                Your Account
            </Typography>
            <Divider />

            <ResetPassword />

            <SocialMediaConnect />

            <FormControl fullWidth={true} variant="outlined">
                <FormLabel sx={{ fontWeight: 600, marginBottom:'8px' }}>Send verification email</FormLabel>
                <ThemedLoadingButton
                    style={{width:'200px'}}
                    disabled={auth.currentUser?.emailVerified || sendVerEmailLoading}
                    onClick={sendVerificationEmail}
                >
                    Send Email
                </ThemedLoadingButton>
                {sendVerEmailError && <Alert sx={{mt:1}}onClose={() => setState({sendVerEmailError:false})} severity="error">{showResetEmailMessage}</Alert>}
                {!sendVerEmailError && showResetEmailMessage.trim()!=='' && <Alert sx={{mt:1}} onClose={() => setState({sendVerEmailError:false, showResetEmailMessage:''})} severity="success">{showResetEmailMessage}</Alert>}
            </FormControl>

            <DeleteAccount />

        </Stack>
    );
}

export default AccountSettings;
