import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { alpha } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import CloseIcon from '@mui/icons-material/Close';
import Link from '@mui/material/Link';
import { useHistory } from 'react-router-dom';

function VerifyEmailNotification({ setNotificationEnabled }) {
    const history = useHistory();
    const handleSettingsLinkClick = () => {
        setNotificationEnabled(false);
        history.push('/settings');
    };
    const handleCloseNotification = () => {
        setNotificationEnabled(false);
    };
    return (
        <Box sx={{ backgroundColor: theme => alpha(theme.palette.secondary.main, 0.08) }}>
            <Container sx={{ paddingTop: '16px', paddingBottom: '16px' }}>
                <Stack direction={'column'} spacing={2}>
                    <Stack direction={'row'} justifyContent={'space-between'}>
                        <Typography variant={'h6'} sx={{ fontWeight: 'bold' }} align="center">
                            You need to verify your email
                        </Typography>
                        <Box>
                            <CloseIcon sx={{ cursor: 'pointer' }} onClick={handleCloseNotification} />
                        </Box>
                    </Stack>
                    <Stack direction={'column'} spacing={1}>
                        <Typography align="left">
                            Please click on the link that was sent to your email account to verify your email and
                            continue with the registration process.{' '}
                        </Typography>

                        <Typography align={'left'}>
                            If the email was lost or you did not receive it, then you can request another email by going
                            to your{' '}
                            <Link href="#" onClick={handleSettingsLinkClick}>
                                Account settings
                            </Link>
                            .
                        </Typography>
                    </Stack>
                </Stack>
            </Container>
        </Box>
    );
}

export default VerifyEmailNotification;
