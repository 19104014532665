import { useContext, useEffect, useReducer } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import { checkPassword, checkValidEmail, firebaseCodeToMessage } from '../../utils';
import { AuthContext } from '../../context/AuthContext';
import { NavContext } from '../../context/NavContext';

function SignUpForm({ switchToLoginForm, switchToForgotPasswordForm }) {
    const { signUpWithUsernamePassword, isAuthInProgress, setAuthError } = useContext(AuthContext);
    const { setLoginModalOpen } = useContext(NavContext);

    const [{ name, emailOrPhone, password, confirmPassword, errors, showPassword }, setState] = useReducer(
        (prev, next) => ({ ...prev, ...next }),
        {
            emailOrPhone: '',
            password: '',
            name: '',
            confirmPassword: '',
            errors: {
                name:{isValid:true},
                emailOrPhone:{isValid:true},
                password: {isValid:true},
                confirmPassword: {isValid:true},
            },
        },
    );

    useEffect(() => {
        setAuthError({error: '', message: ''})
    }, []);


    const onSubmit = async () => {
        //validate inputs
        let nameValidation={isValid:true};
        let emailPhoneValidation;
        let passwordValidation={isValid:true};
        let confirmPasswordValidation={isValid:true};

        // check name
        if (!name.trim().match(/\w\s\w/g) || name.trim().length<4){
            nameValidation={
                message:'Please enter full name',
                isValid:false
            }
        }
        
        if (emailOrPhone.trim() === ''){
            emailPhoneValidation={
                message:'Email can not be left empty',
                isValid:false
            }
        } else{
            emailPhoneValidation = checkValidEmail(emailOrPhone);
            // only allow email signups
            if (emailPhoneValidation?.type !== 'email'){
                emailPhoneValidation.message = 'Invalid email address';
                emailPhoneValidation.isValid = false;
            }
        }

        if (password.trim() === ''){
            passwordValidation ={
                message:'Password can not be left empty',
                isValid:false
            }
        } else if (confirmPassword.trim() === ''){
            confirmPasswordValidation={
                message:'Password can not be left empty',
                isValid:false
            }
        } else if (password !== confirmPassword) {
            confirmPasswordValidation={
                message:'Passwords do not match',
                isValid:false
            }
        } else {
            passwordValidation = checkPassword(password);
        }
        setState({
            errors: {
                name:nameValidation,
                emailOrPhone: emailPhoneValidation,
                password: passwordValidation,
                confirmPassword: confirmPasswordValidation,
            },
        });

        try{
            if (nameValidation?.isValid && passwordValidation?.isValid && emailPhoneValidation?.isValid && confirmPasswordValidation?.isValid) {
                const singUpAuthError = await signUpWithUsernamePassword(name, emailOrPhone, password);
                // console.log(authError);
                if (singUpAuthError === '') {
                    modalClose();
                } else {
                    setState({errors:{
                            ...errors , 
                            ...{emailOrPhone: {message:firebaseCodeToMessage(singUpAuthError), isValid: false}},
                    }});
                }
            }
        } catch(error){
            console.log(error);
        }
    };

    const modalClose = () => {
        setLoginModalOpen(false);
    };

    const changeHandler = e => {
        setState({ [e.target.name]: e.target.value });
    };

    return (
        <form>
            <Box sx={{ display: 'flex', justifyContent: 'center', paddingBottom: {xs:0} }}>
                <Typography>Sign up manually</Typography>
            </Box>
            <Box>
                <TextField
                    margin="normal"
                    autoComplete={'name'}
                    required
                    fullWidth
                    name={'name'}
                    onChange={changeHandler}
                    error={!errors.name?.isValid}
                    variant="outlined"
                    label="Name"
                    helperText={errors.name?.message}
                />
            </Box>
            <Box>
                <TextField
                    margin="normal"
                    autoComplete={'email'}
                    required
                    fullWidth
                    name={'emailOrPhone'}
                    onChange={changeHandler}
                    error={!errors.emailOrPhone?.isValid}
                    variant="outlined"
                    label="Email Address"
                    helperText={!errors.emailOrPhone?.isValid 
                        ? errors.emailOrPhone?.message 
                        : 'Only include letters (a-z A-Z), numbers (0-9), underscore (_), dot (.) and one @ symbol'
                    }
                />
            </Box>
            <Box>
                <TextField
                    margin="normal"
                    autoComplete={'password'}
                    required
                    fullWidth
                    onChange={changeHandler}
                    type={showPassword ? 'text' : 'password'}
                    name={'password'}
                    variant="outlined"
                    error={!errors.password?.isValid}
                    label="Password"
                    helperText={!errors.password?.isValid ? errors.password?.message : `Must contain at least 8 characters, including upper/lowercase, numbers and a special character`}
                    InputProps={{
                        endAdornment:(
                                <InputAdornment position="end" sx={{bgcolor: 'background.default'}}>
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={()=>setState({showPassword:!showPassword})}
                                        onMouseDown={()=>setState({showPassword:!showPassword})}
                                        edge="end"
                                        >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                        )
                    }}
                />
            </Box>
            <Box>
                <TextField
                    margin="normal"
                    autoComplete={'password'}
                    required
                    fullWidth
                    onChange={changeHandler}
                    type={showPassword ? 'text' : 'password'}
                    name={'confirmPassword'}
                    variant="outlined"
                    error={!errors.confirmPassword?.isValid}
                    label="Confirm Password"
                    helperText={errors.confirmPassword?.message}
                    InputProps={{
                        endAdornment:(
                                <InputAdornment position="end" sx={{bgcolor: 'background.default'}}>
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={()=>setState({showPassword:!showPassword})}
                                        onMouseDown={()=>setState({showPassword:!showPassword})}
                                        edge="end"
                                        >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                        )
                    }}
                />
            </Box>

            <Button
                margin="normal"
                variant="contained"
                size={'large'}
                color={'primary'}
                onClick={onSubmit}
                disabled={isAuthInProgress}
                sx={{
                    mt: 2,
                }}
            >
                Sign Up{' '}
                {isAuthInProgress && (
                    <Box sx={{ paddingLeft: '16px', display: 'flex', justifyContent: 'middle' }}>
                        <CircularProgress size={'1.2rem'} color={'secondary'} />
                    </Box>
                )}
            </Button>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection:'row',
                    variant:"body2",
                    mb:2,
                    mt:2,
                    justifyContent: 'space-between',
                }}
            >
                <Typography sx={{display:'flex',flex:1}}>
                    <Link
                        href={'#'}
                        variant="button"
                        onClick={e => {
                            e.preventDefault();
                            switchToForgotPasswordForm();
                        }}
                    >
                        Forgot password?
                    </Link>
                </Typography>
                <Typography sx={{display:'flex',flex:1}}>
                    <Link
                        href={'#'}
                        variant="button"
                        onClick={e => {
                            e.preventDefault();
                            switchToLoginForm();
                        }}
                    >
                        Already have an account? Sign in
                    </Link>
                </Typography>
            </Box>
        </form>
    );
}

export default SignUpForm;
