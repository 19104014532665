import React, { createContext, useState } from 'react';

const GoogleMapViewContext = createContext();

const { Provider } = GoogleMapViewContext;

function GoogleMapViewProvider({ children }) {
    const [mapLoading, setMapLoading] = useState(false);
    const [viewMap, setViewMap] = useState(false);

    const [fromSelectedLocation, setFromSelectedLocation] = useState(
        () => localStorage.getItem('fromSelectedLocation') || '',
    );
    const [toSelectedLocation, setToSelectedLocation] = useState(
        () => localStorage.getItem('toSelectedLocation') || '',
    );

    const [fromLatLng, setFromLatLng] = useState(() => JSON.parse(localStorage.getItem('fromLatLng')) || {});
    const [toLatLng, setToLatLng] = useState(() => JSON.parse(localStorage.getItem('toLatLng')) || {});

    const [fromFieldFocussed, setFromFieldFocussed] = useState(false);
    const [toFieldFocussed, setToFieldFocussed] = useState(false);

    const value = {
        mapLoading,
        viewMap,
        mapLoadingCompleted: status => setMapLoading(status),
        setViewMap,
        fromSelectedLocation,
        toSelectedLocation,
        updateToSelectedLocation: location => {
            setToSelectedLocation(location);
            localStorage.setItem('toSelectedLocation', location);
        },
        updateFromSelectedLocation: location => {
            setFromSelectedLocation(location);
            localStorage.setItem('fromSelectedLocation', location);
        },
        fromLatLng,
        toLatLng,
        setFromLatLng: latlng => {
            setFromLatLng(latlng);
            localStorage.setItem('fromLatLng', JSON.stringify(latlng) || {});
        },
        setToLatLng: latlng => {
            setToLatLng(latlng);
            localStorage.setItem('toLatLng', JSON.stringify(latlng) || {});
        },
        fromFieldFocussed,
        setFromFieldFocussed: status => {
            setFromFieldFocussed(status);
            setToFieldFocussed(false);
        },
        toFieldFocussed,
        setToFieldFocussed: status => {
            setFromFieldFocussed(false);
            setToFieldFocussed(status);
        },
    };

    return <Provider value={value}>{children}</Provider>;
}

export { GoogleMapViewContext, GoogleMapViewProvider };
