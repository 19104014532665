import React, { useContext, useReducer, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import { useLocation } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
// import Alert from '@mui/material/Alert';

import { TrackParcelContext } from '../../context/TrackParcelContext';
import { parseURL } from '../../utils';
import ThemedLoadingButton from '../../component/Buttons/ThemedLoadingButton';
import ParcelStatusHeader from './ParcelStatusHeader';

/* 
    component to show parcel status
    - Start with a loading screen
    - If the parcel id from context is present then the user landed from the home page so show the current status
    - If not, then the user has landed directly via the URL. In that case
        - Validate the URL and if invalid then show the screen to enter the parcel id
        - If valid then show the current parcel status
        - If something does not work then show the screen to enter the parcel id
*/

function useQuery() {
    return new useLocation().search;
}

const TrackParcel = () => {

    const { parcelId, setParcelId, trackingResult, trackParcel } = useContext(TrackParcelContext);
    const incomingUrl = useQuery().trim();
    const [{ loading, buttonLoading, askForParcelId, error, errorMessgae, showCurrentStatus}, setState] = useReducer((prev, next) => ({ ...prev, ...next }), {
        loading : true,
        askForParcelId : false,
        error:  false,
        errorMessgae: '',
        buttonLoading: false,
        showCurrentStatus: false,
    });

    useEffect(async () => {
        if (trackingResult?.status === 'OK') {
            setState({
                loading: false,
                showCurrentStatus: true, // show the parcel status
            });
        } else if (incomingUrl.trim() === '') {
            setState({
                loading: false,
                askForParcelId:true, // ask parcelId to be entered manually
            });
        } else {
            const query = parseURL(incomingUrl);
            if (query.hasOwnProperty('parcelId')) {
                if (query?.parcelId === '') {
                    setState({
                        loading: false,
                        askForParcelId:true,
                    });
                } else {
                    setState({
                        loading: true,
                        askForParcelId:false,
                        error: false,
                        errorMessgae: '',
                        showCurrentStatus: false,
                    });
                    try{
                        setParcelId(query.parcelId);
                        await trackPackageHandler(query.parcelId);
                    } catch(error){
                        console.log(error);
                        setState({
                            loading: false,
                            askForParcelId:true,
                            error: true,
                            errorMessgae: 'Oh Snap! Something went wrong. Please try again.',
                            showCurrentStatus: false,
                        });
                    }
                }
            } else {
                setState({
                    loading: false,
                    askForParcelId:true,
                });
            }
        }
    }, []);

    const trackPackageHandler = async (parcelId, calledByButton=false) =>{
        if (calledByButton){
            setState({
                buttonLoading:true
            });
        }
        try{
            const res = await trackParcel(parcelId);
            if (res?.status !== 'OK'){
                setState({
                    askForParcelId:true,
                    error: res?.error || false,
                    errorMessgae: res?.message || '',
                    showCurrentStatus: false,
                });
            } else{
                setState({
                    askForParcelId:false,
                    error: false,
                    errorMessgae: '',
                    showCurrentStatus: true,
                });
            }
        } catch (error){
            console.log(error);
            setState({
                askForParcelId:true,
                error: true,
                errorMessgae: 'Oh Snap! Something went wrong. Please try again.',
                showCurrentStatus: false,
            });
        } finally{
            setState({
                loading: false,
                buttonLoading: false
            });
        }

    }
    
    // console.log(incomingUrl)
    if (loading) {
        return (
            <>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%',}}>
                    <CircularProgress size={40} color="primary" />
                </div>
            </>
        );
    }

    if (showCurrentStatus) {
        return (
           <ParcelStatusHeader />
        );
    }

    if (askForParcelId) {
        return (
            <Stack
                direction="column"
                spacing={2}
                sx={{
                    padding: {
                        xs: '32px 12px',
                        sm: '32px 12px',
                        md: '41px',
                        lg: '41px 20%',
                        xl: '41px 20%',
                    },
                    minHeight:'60%'
                }}
            >
                <Typography variant="h5">
                    Sorry, we are unable to find a match for the number your provided.
                </Typography>
                <Typography variant="p">
                    Your retailer may not have provided the parcel to us yet. We will update the tracking details as
                    soon as we receive the parcel and send an update to the contact details provided by the retailer. 
                    Please check and try later or contact your retailer.
                </Typography>
                <Typography variant="p">
                    <strong>To track another parcel please enter the details below:</strong>
                </Typography>
                <FormControl sx={{ m: 1 }} variant="outlined">
                    <TextField
                        label={'Parcel number'}
                        // required
                        fullWidth={true} 
                        variant="outlined"
                        id="tracking-parcel-id" 
                        value={parcelId}
                        onChange={(e)=>setParcelId(e.target.value)}
                        error={error}
                        helperText={error ? errorMessgae : 'You can find the tracking number in your order confirmation email'}
                    />
                </FormControl>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    <ThemedLoadingButton 
                        onClick={()=>trackPackageHandler(parcelId,true)}
                        loading={buttonLoading}
                        sx={{
                            width:{
                                // xl:'100%'
                                xs: '100%',
                                sm: '100%',
                                md: 'inherit',
                                lg: 'inherit',
                                xl: 'inherit',
                            },
                        }}
                    >
                        Track Parcel
                    </ThemedLoadingButton>
                </Box>
                {/* {error && <Alert onClose={() => setState({error:false, errorMessgae:''})} severity="error">{errorMessgae}</Alert>} */}
            </Stack>
        )
    }
    
    return (
        <>
            <div>
                <Typography component="p">
                    Oh Snap! Something went wrong. Please try again.
                </Typography>
            </div>
        </>
    );
};
export default TrackParcel;