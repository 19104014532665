import React, { useContext } from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Stop from '@mui/icons-material/Stop';
import ArrowDownward from '@mui/icons-material/ArrowDownward';
import ChevronRight from '@mui/icons-material/ChevronRight';
import { useTheme } from '@mui/material/styles';
import { useHistory } from 'react-router-dom';

import { convertParcelStatus } from "../../utils";
import { TrackParcelContext } from '../../context/TrackParcelContext';

const ParcelList = ({item}) => {
    const date = new Date(item.date._seconds * 1000).toDateString();
    const theme = useTheme();
    const history = useHistory();
    const { resetTracking } = useContext(TrackParcelContext);
    return (
        <Paper
            key={item.id}
            sx={{
                maxWidth:600,
                justifyContent: 'space-around',
                marginTop: theme.spacing(2),
                border: `1px solid ${theme.palette.divider}`,
                marginLeft: {
                    xs:'8px',
                    sm:'auto',
                },
                marginRight: {
                    xs:'8px',
                    sm:'auto',
                },
            }}
        >
            <Grid container spacing={0} 
                sx={{ 
                    paddingBottom: '8px',
                    '&:hover': {
                        background: theme.palette.secondary.light,
                        color: 'white',
                        cursor: 'pointer',
                    }
                }}
                onClick={() => {
                    resetTracking();
                    history.push(`/track?parcelId=${item.id}`);
                }}
            >
                <Grid item xs={8} sm={8} md={8} lg={8} sx={{
                    padding:{
                        xs:'8px',
                        md:'16px'
                    },
                }}>
                    <Typography component="p"> {date}</Typography>
                    <Typography component="p">Status: {convertParcelStatus(item.status).status||''}</Typography>
                    <div style={{display: 'flex', flexDirection: 'row',alignItems: 'center'}}>
                        <Stop style={{fontSize: 10, color: 'inherit'}}></Stop>
                        <Typography style={{marginLeft: theme.spacing(1)}}>{item.from}</Typography>
                    </div>
                    <ArrowDownward style={{fontSize: 15, color: 'inherit', marginLeft: theme.spacing(8)}}></ArrowDownward>
                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                        <Stop style={{fontSize: 10, color: 'inherit'}}></Stop>
                        <Typography style={{marginLeft: theme.spacing(1)}}>{item.to}</Typography>
                    </div>
                </Grid>
                <Grid item xs={2} sm={2} md={2} lg={2} sx={{
                    padding:{
                        xs:'8px',
                        md:'16px'
                    },
                }}>
                    <Typography component="p">{item.cost}</Typography>
                </Grid>
                <Grid item xs={2} sm={2} md={2} lg={2} sx={{
                    display:'flex',
                    alignItems:'center',
                    padding:{
                        xs:'8px',
                        md:'16px'
                    },
                }}>
                    <ChevronRight style={{fontSize: 20, color: 'inherit',}}></ChevronRight>
                </Grid>
            </Grid>
        </Paper>
        // </div>
    );
};
export default ParcelList;