const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
const months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];

export const date_ddmmyy_hhmmss_ms = (date) =>{
    return date.toISOString().split('Z')[0].replace(/-/g,'').replace('T','_').replace(/:/g,'').replace('.','_');

    // toISOString - "2020-11-29T20:15:20.562Z" return 20201129_201520_562
}

export const convertDate = (date, noReturnCarriage = false) =>{
    let day = daysOfWeek[date.getDay()];
    let d = date.getDate();
    let m = months[date.getMonth()];
    let y = date.getFullYear();
    d = "0" + d.toString();
    d = d.substr(d.length-2,2);

    if (noReturnCarriage) return `${day} ${d} ${m} ${y}`;

    return `${day}\n ${d} ${m} ${y}`;

}

export const convertDateTime = (date) =>{
    let day = daysOfWeek[date.getDay()];
    let d = date.getDate();
    let m = months[date.getMonth()];
    let y = date.getFullYear();
    d = "0" + d.toString();
    d = d.substr(d.length-2,2);
    let time = date.toLocaleTimeString('en-UK');

    return `${day}, ${d} ${m} ${y}, ${time}`;

}

const checkLeapYear = (year) => {
    if ((0 === year % 4) && (0 !== year % 100) || (0 === year % 400)) {
        return true;
    } else {
        return false;
    }
}


export const dateIsValid = (year,month,date) => {
    const isLeapYear = checkLeapYear(year);
    if (['01','03','05','07','08','10','12'].findIndex(elm=> elm===month)>=0){
        if (date>=1 && date<=31) return true;
        else return false;
    } else if (['04','06','09','11'].findIndex(elm=> elm===month)>=0){
        if (date>=1 && date<=30) return true;
        else return false;
    } else if (month==='02'){
        if (isLeapYear){
            if (date>=1 && date<=29) return true;
            else return false;
        } 
        else {
            if (date>=1 && date<=28) return true;
            else return false;
        } 
    } else {
        return false;
    }
}

// export default convertDate;