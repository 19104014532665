import { GiRobotAntennas } from 'react-icons/gi';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

export default function Page404() {
    const theme = useTheme();
    return (
        <Box
            sx={{
                flex: 1,
                width: '100%',
                height: '100%',
                background: theme.palette.primary.main,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyItems: 'center',
                justifyContent: 'center',
                color: 'white',
            }}
        >
            <GiRobotAntennas color="white" size={150} style={{ marginBottom: theme.spacing(3) }} />
            <Typography variant="h3">Page Not Found</Typography>
        </Box>
    );
}
