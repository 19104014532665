import React, { useContext, useEffect, useState } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import NavButton from './NavButton';
import PersonIcon from '@mui/icons-material/Person';
import ZkootieLogo from '../../images/zkootielogo.svg';
// import MenuIcon from '@mui/icons-material/Menu';
import { Link, useHistory } from 'react-router-dom';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

import { AuthContext } from '../../context/AuthContext';
import { NavContext } from '../../context/NavContext';
import VerifyEmailNotification from '../VerifyEmailNotification';

const useStyles = makeStyles(theme => ({
    iconOnButton: {
        marginLeft: 12,
        marginRight: 12,
    },
}));

function NavBar() {
    const { /*burgerIconOpen, setBurgerIconOpen,*/ setLoginModalOpen } = useContext(NavContext);
    const { user, userAttributes, signOut } = useContext(AuthContext);
    const displayName = userAttributes?.profile?.displayName || 'Z';
    // const [disName, setDisName] = useState("")

    const theme = useTheme();
    const [anchorEl, setAnchorEl] = useState(null);

    const isMd = useMediaQuery(theme.breakpoints.down('md'));
    const history = useHistory();
    const classes = useStyles();
    const handleMenuOpen = e => {
        setAnchorEl(e.currentTarget);
    };

    const [notificationEnabled, setNotificationEnabled] = useState(false);

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        if (user !== null && !user?.emailVerified){
            const signinMethods=user.providerData.map(providers=>providers.providerId);
            if (signinMethods.indexOf('facebook.com') >=0|| signinMethods.indexOf('google.com')>=0){
                setNotificationEnabled(false);
            } else {
                setNotificationEnabled(true);
            }
        } else {
            setNotificationEnabled(false);
        }
    }, [user]);

    return (
        <>
            <AppBar position="static" elevation={0}>
                <Toolbar
                // sx={{
                //     maxHeight: "40px",
                //     paddingTop: "12px",
                //     paddingBottom: "12px",
                // }}
                >
                    <Container
                        disableGutters
                        sx={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'space-between' }}
                    >
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Link to={'/'}>
                                <img src={ZkootieLogo} alt={'zkootie logo'} />
                            </Link>
                            {!isMd && (
                                <>
                                    <NavButton sx={{ marginLeft: '10px' }} onClick={() => history.push('/about/us')}>
                                        About us
                                    </NavButton>
                                    <NavButton onClick={() => history.push('/help')}>Help</NavButton>
                                </>
                            )}
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            {!isMd && (
                                <>
                                    <NavButton onClick={() => history.push('/about/earn')}>
                                        <span>Earn with Zkootie</span>
                                    </NavButton>
                                </>
                            )}
                            {/*<NavButton><PersonIcon/>{!isMd && <span style={{marginLeft: 12}}>Login</span>}</NavButton>*/}
                            {user && (
                                <NavButton inverted={true} aria-controls={'menu'} onClick={handleMenuOpen} /*onMouseOver={handleMenuOpen}*/>
                                    {displayName || 'Z'}
                                    <ArrowDownwardIcon
                                        sx={{
                                            transition: 'transform .3s',
                                            '&:hover': {
                                                transform: 'rotate(90deg)',
                                            },
                                        }}
                                    />
                                </NavButton>
                            )}
                            {!user && (
                                <NavButton inverted={true} onClick={() => setLoginModalOpen(true)}>
                                    <PersonIcon />
                                    <span className={classes.iconOnButton}>Login</span>
                                </NavButton>
                            )}
                            {/* {isMd && (
                                <NavButton onClick={() => setBurgerIconOpen(!burgerIconOpen)}>
                                    <MenuIcon />
                                </NavButton>
                            )} */}
                        </Box>
                    </Container>
                </Toolbar>
            </AppBar>
            {user && (
                <Menu
                    id={'menu'}
                    sx={{ marginTop: '12px' }}
                    disableScrollLock={true}
                    onClick={handleMenuClose}
                    onClose={handleMenuClose}
                    open={Boolean(anchorEl)}
                    // MenuListProps={{ onMouseLeave: handleMenuClose }}
                    anchorEl={anchorEl}
                >
                    <MenuItem
                        onClick={() => {
                            handleMenuClose();
                            history.push('/settings');
                        }}
                    >
                        My Account
                    </MenuItem>
                    <MenuItem 
                        onClick={()=>{
                            handleMenuClose();
                            history.push('/myparcels');
                        }}
                    >
                        Orders
                    </MenuItem>
                    <Divider variant="middle" />
                    <MenuItem
                        onClick={e => {
                            handleMenuClose();
                            signOut();
                        }}
                    >
                        Logout
                    </MenuItem>
                </Menu>
            )}
            {/*{ user && !user.emailVerified && <VerifyEmailNotification />}*/}
            {notificationEnabled && <VerifyEmailNotification setNotificationEnabled={setNotificationEnabled} />}
        </>
    );
}

export default NavBar;
