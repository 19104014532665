import React, { useContext } from 'react';
import { PayPalButtons } from '@paypal/react-paypal-js';
import { getApp } from 'firebase/app';
import { getFunctions, httpsCallable } from 'firebase/functions';

import { PackageContext } from '../../context/PackageContext';
import { LOCALE_OPTIONS } from '../../AppConstants';
import firebaseConfig from '../../firebaseConfig';

function PayPalButtonWrapper({disabled, setError, setParcelId}){
    // const [orderId, setOrderId] = useState('');
    const { fromAddress, toAddress, weight, volumeKey, selectedDeliveryType, pickupDate, pickupTime, fromName,fromContactEmail,fromContactPhone,
    priceResult,deliveryMessage,totalPrice,toName,toContactEmail,toContactPhone } = useContext(PackageContext);
    const { priceId } = priceResult;
    const { currencySymbol } = priceResult.prices;
    const app = getApp();
    const { region } = firebaseConfig;
    
    const fromAddressLatLng=priceResult.fromAddress.filter(elm=>elm.address===fromAddress).map(elm=> {return {lat:elm.lat,long:elm.long}})[0];
    const toAddressLatLng=priceResult.toAddress.filter(elm=>elm.address===toAddress).map(elm=> {return {lat:elm.lat,long:elm.long}})[0];

 
    const createOrder = async () => {
        try{
            const functions = getFunctions(app,region);
            const createPayPalOrder = httpsCallable(functions, 'payment-createPayPalOrder');
            const result = await createPayPalOrder({ 
                priceId,
                volumeKey, 
                deliveryType:selectedDeliveryType, 
                weight,
                pickupTime,
                pickupDate,
                totalPrice,
                currency:LOCALE_OPTIONS.currency,
                isCalledbyApp:false
            });
            return result.data.result.id;
        } catch(error){
            console.log(error);
            setError({
                error:true,
                errorMessage:'Unable to create order'
            });
        }
    };

    const onApprove = async(data,actions) => {
        try{
            // console.log(data);
            const receiver ={toName, toContactEmail, toContactPhone};
            const sender={ fromName, fromContactEmail, fromContactPhone};
            const functions = getFunctions(app,region);
            const executePaymentWeb = httpsCallable(functions, 'payment-executePaymentWeb');
            // console.log(pickupDate);
            // const pdate=new Date(pickupDate);
            // console.log({ 
            //     currency:LOCALE_OPTIONS.currency,
            //     currencySymbol,
            //     deliveryType:selectedDeliveryType,
            //     deliveryMessage,
            //     volumeKey,
            //     weight,
            //     fromAddress,
            //     fromAddressLatLng,
            //     fromAddressType:priceResult.fromAddressType,
            //     orderId:data.orderID,
            //     pickupDate:pickupDate,
            //     pickupTime,
            //     priceId,
            //     receiver:receiver,
            //     sender:sender,
            //     toAddress,
            //     toAddressLatLng,
            //     toAddressType:priceResult.toAddressType,
            //     totalPrice,
            // });
            const result = await executePaymentWeb({ 
                currency:LOCALE_OPTIONS.currency,
                currencySymbol,
                deliveryType:selectedDeliveryType,
                deliveryMessage,
                volumeKey,
                weight,
                fromAddress,
                fromAddressLatLng,
                fromAddressType:priceResult.fromAddressType,
                orderId:data.orderID,
                pickupDate:pickupDate,
                pickupTime,
                priceId,
                receiver:receiver,
                sender:sender,
                toAddress,
                toAddressLatLng,
                toAddressType:priceResult.toAddressType,
                totalPrice,
            });
            // console.log(result);
            setParcelId(result.data.id.trim());
            setError({
                error:false,
                errorMessage:''
            });
        
        } catch(error){
            console.log(error);
            setError({
                error:true,
                errorMessage:'Unable to takae payment. Please try again'
            });
        }
    }

    return (
        <>  
            <PayPalButtons
                disabled={disabled}
                createOrder={createOrder}
                onApprove={onApprove}
                forceReRender={[toName, toContactEmail, toContactPhone,fromName, fromContactEmail, fromContactPhone,totalPrice,deliveryMessage]}
            />
        </>
    );
}

export default PayPalButtonWrapper;