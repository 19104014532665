import { useState, useContext } from 'react';
import Alert from '@mui/material/Alert';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
// import OutlinedInput from '@mui/material/OutlinedInput';
import TextField from '@mui/material/TextField';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import ThemedLoadingButton from "../Buttons/ThemedLoadingButton";
import { checkPassword } from '../../utils';
import { AuthContext } from '../../context/AuthContext';

const ResetPassword = () => {

    const [password, setPassword] = useState('');
    const [confirmPassword,setConfirmPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    const [errorPassword, setErrorPassword] = useState(false);
    const [passwordResponse, setPasswordResponse] = useState(null);  

    const [errorConPassword, setErrorConPassword] = useState(false);
    const [passwordConResponse, setPasswordConResponse] = useState(null); 

    const [loading, setLoading] = useState(false);
    const [isPasswordChanged, setIsPasswordChanged] = useState(false);
    const { updatePasswordError, updateUserPassword } = useContext(AuthContext);

    async function onResetPasswordSubmit(){
        setLoading(true);
        setErrorPassword(false);
        setErrorConPassword(false);
        setIsPasswordChanged(false);
    
        // check password
    
        var validPasswordReponse=checkPassword(password);
        setPasswordResponse(validPasswordReponse);
        setErrorPassword(!validPasswordReponse.isValid); 
        var validConPass=false;
    
        if (!confirmPassword){
            setPasswordConResponse({
                type:'password',
                isValid:false, 
                message:'The field cannot be left blank',  
                sanitisedValue:null,  
            });
            setErrorConPassword(true);       
        } else if (validPasswordReponse.isValid && password !== confirmPassword){
            setPasswordConResponse({
                type:'password',
                isValid:false, 
                message:'Passwords do not match',  
                sanitisedValue:null,  
            });
            setErrorConPassword(true); 
        } else {
            validConPass=true;
            setErrorConPassword(false);
            setPasswordConResponse({});
        }
    

        if (validPasswordReponse.isValid && validConPass){
            // try{
                const res = await updateUserPassword(validPasswordReponse.sanitisedValue);
                if (res){
                    setIsPasswordChanged(true);
                    setPassword('');
                    setConfirmPassword('');
                    setTimeout(() => {
                        setIsPasswordChanged(false);
                    }, 1500);
                }
                setLoading(false);
        } else{
            setLoading(false);
        }
    }

    return (
        <form>
        <FormControl fullWidth={true} variant="outlined">
            <FormLabel sx={{ fontWeight: 600, marginBottom:'8px' }}>Update password</FormLabel>
            <TextField
                label={'New Password'}
                name={'password'}
                type={showPassword ? 'text' : 'password'}
                autoComplete={'new-password'}
                InputProps={{
                    sx:{
                            bgcolor: 'background.default',
                        },
                    endAdornment:(
                            <InputAdornment position="end" sx={{bgcolor: 'background.default'}}>
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={()=>setShowPassword(!showPassword)}
                                    onMouseDown={()=>setShowPassword(!showPassword)}
                                    edge="end"
                                    >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                    )
                }}
                value={password}
                onChange={e=>setPassword(e.target.value)}
                error={errorPassword || updatePasswordError.message !== ''}
                helperText={errorPassword 
                    ? passwordResponse.message 
                    : updatePasswordError.message !== '' 
                        ? updatePasswordError.message
                        : 'At least 8 characters with a mix of upper and lowercase letters, numbers and special characters'}
            />
            <TextField
                label={'Confirm Password'}
                name={'confirmPassword'}
                type={showPassword ? 'text' : 'password'}
                autoComplete={'new-password'}
                InputProps={{
                    sx:{
                            bgcolor: 'background.default',
                        },
                    endAdornment:(
                        <InputAdornment position="end" sx={{bgcolor: 'background.default'}}>
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={()=>setShowPassword(!showPassword)}
                                onMouseDown={()=>setShowPassword(!showPassword)}
                                edge="end"
                                >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                    )
                }}
                style={{marginTop:'8px'}} 
                value={confirmPassword}
                onChange={e=>setConfirmPassword(e.target.value)}
                error={errorConPassword}
                helperText={errorConPassword ? passwordConResponse.message : 'At least 8 characters with a mix of upper and lowercase letters, numbers and special characters'}
            />
            <ThemedLoadingButton 
                style={{width:'200px',marginTop:'8px'}} 
                onClick={onResetPasswordSubmit} 
                loading={loading} 
                disabled={loading}
                // fullWidth
            >
                Save Password
            </ThemedLoadingButton>
            {isPasswordChanged && <Alert onClose={() => setIsPasswordChanged(false)} severity="success">Password updated</Alert>}
        </FormControl>
        </form>
    )
            
}

export default ResetPassword;
