import React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

import {SETTINGS_MENU_TEXT} from "../../AppConstants";
import ProfilePicture from "./ProfilePicture";

const SettingsList = ({selectedSetting,setSelectedSetting}) => {
    
    return (
        <List sx={{p:0}}>
            <ListItem 
                divider
                sx={{
                    mt:0,
                    p:'1px',
                    // maxWidth: 360,
                    bgcolor: 'background.default',
                    padding:'2px 8px 2px 8px',
                    justifyContent:'center',
                }}
            >
                <ProfilePicture />
            </ListItem>
            {SETTINGS_MENU_TEXT.map(item=>(
                <ListItem
                    key={item.index}
                    divider
                    button
                    sx={{
                        m:0,
                        // maxWidth: 360,
                        bgcolor: 'background.default',
                        padding:'4px 12px 4px 12px',
                        boxShadow: (item.index=== selectedSetting) ?  'inset 4px 0 0 #e00095' : 'none',  
                    }}
                    onClick={()=>setSelectedSetting(item.index)}
                >
                    <ListItemText 
                        primary={item.primary} 
                        secondary={item.secondary}  
                        sx={{
                            m:0,
                        }} 
                    />
                </ListItem>
            ))}
        </List>
    );
}

export default SettingsList;
