import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import parcelHandoverImage from '../../images/Who-We-Serve-Courier-Service-1080x675.jpeg';
import realTimeTrackingImage from '../../images/zkootie_tracking_1080x675.jpg';

import { LazyLoadImage } from 'react-lazy-load-image-component';
import CustomCard from '../../component/CustomCard';
import HoverUnderlineTypography from '../../component/HoverUnderlineTypography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useHistory } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { ReactComponent as UserJourneyImageLg } from '../../images/user_journey.svg';
import PricingTable from './PricingTable';

const useStyles = makeStyles(theme => ({
    media: {
        maxWidth: '100%',
        maxHeight: '100%',
    },
}));

function HomeBottom(props) {
    const classes = useStyles(props);
    const theme = useTheme();
    const isSm = useMediaQuery(theme.breakpoints.down('sm'));
    const history = useHistory();

    return (
        <section>
            <Container sx={{ paddingTop: '16px' }}>
                <Grid container spacing={2}  sx={{ marginBottom: {xs:'8px',sm:'8px',md:'16px',lg:'32px'} }}>
                    <Grid item xs={12} sm={12} md={6} lg={6} >
                        <CustomCard>
                            <Typography variant={!isSm ? 'h2' : 'h3'} sx={{ marginBottom: {xs:'8px',sm:'8px',md:'16px',lg:'32px'} }}>
                                Your Parcels. Delivered.
                            </Typography>
                            <Typography  sx={{ marginBottom: {xs:'8px',sm:'8px',md:'16px',lg:'32px'} }}>
                                Fast deliveries across the UK. All parcels are eligible for same or next day delivery 
                                without any extra cost. Give your customers peace of mind with our free returns promise policy. Express deliveries
                                include a cover of up to £100.
                            </Typography>
                            <HoverUnderlineTypography
                                onClick={() =>
                                    history.push({ pathname: '/about/business' })
                                }
                            >
                                Learn More
                            </HoverUnderlineTypography>
                        </CustomCard>
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <CustomCard>
                            {/* <LazyLoadImage
                                effect="blur"
                                src={UserJourneyImageLg}
                                className={classes.media}
                                alt={'User Journey'}
                            /> */}
                            <UserJourneyImageLg/>
                            {/* { !(isSm|| isMd) && <UserJourneyImageLg />}
                            { isMd && <UserJourneyImageMd />}
                            { isSm && <UserJourneyImageMSm />} */}
                        </CustomCard>
                    </Grid>
                </Grid>

                <Grid container spacing={2} 
                    sx={{ 
                        marginBottom: {xs:'8px',sm:'8px',md:'16px',lg:'32px'},
                        flexDirection:{ xs:'column-reverse', sm:'column-reverse', md:'row' } 
                    }}
                >
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <CustomCard>
                            <PricingTable />
                        </CustomCard>
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={6} >
                        <CustomCard>
                            <Typography variant={!isSm ? 'h2' : 'h3'} sx={{ marginBottom: {xs:'8px',sm:'8px',md:'16px',lg:'32px'} }}>
                                Cheaper than Royal Mail and Evri
                            </Typography>
                            <Typography  sx={{ marginBottom: {xs:'8px',sm:'8px',md:'16px',lg:'32px'} }}>
                                Affordable shipping solutions for both businesses and individuals. We pride ourselves on offering a range of delivery options 
                                without compromising on quality or reliability. Our user-friendly website and mobile apps allow you to easily track 
                                your parcels and get in touch with our friendly customer service team. So why pay more when you can 
                                save with Zkootie? Try us out today and see the difference for yourself.
                            </Typography>
                        </CustomCard>
                    </Grid>
                </Grid>

                <Grid container spacing={2}  sx={{ marginBottom: {xs:'8px',sm:'8px',md:'16px',lg:'32px'} }}>
                    <Grid item xs={12} sm={12} md={6} lg={6} >
                        <CustomCard>
                            <Typography variant={!isSm ? 'h2' : 'h3'} sx={{ marginBottom: {xs:'8px',sm:'8px',md:'16px',lg:'32px'} }}>
                                Real-time Tracking
                            </Typography>
                            <Typography  sx={{ marginBottom: {xs:'8px',sm:'8px',md:'16px',lg:'32px'} }}>
                                Our state-of-the-art tracking system allows you to easily track your parcel every step of the way, from the moment it leaves our 
                                facility to the moment it arrives at its destination. With real-time location updates, GPS tagged delivery location and picture proof 
                                of the delivery, you can rest assured that your package is in good hands.
                            </Typography>
                            <HoverUnderlineTypography
                                onClick={() =>
                                    history.push({ pathname: '/about/business',hash: '#faq', })
                                }
                            >
                                Learn More
                            </HoverUnderlineTypography>
                        </CustomCard>
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <CustomCard>
                            <LazyLoadImage
                                effect="blur"
                                src={realTimeTrackingImage}
                                className={classes.media}
                                alt={'Zkootie Real-time Tracking'}
                            />
                        </CustomCard>
                    </Grid>
                </Grid>

                <Grid container spacing={2} 
                    sx={{ 
                        marginBottom: {xs:'8px',sm:'8px',md:'16px',lg:'32px'},
                        flexDirection:{ xs:'column-reverse', sm:'column-reverse', md:'row' } 
                    }}
                >
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <CustomCard>
                            <LazyLoadImage
                                effect="blur"
                                src={parcelHandoverImage}
                                className={classes.media}
                                alt={'Delivery Care'}
                            />
                        </CustomCard>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} >
                        <CustomCard>
                            <Typography variant={!isSm ? 'h2' : 'h3'} sx={{ marginBottom: {xs:'8px',sm:'8px',md:'16px',lg:'32px'} }}>
                                Earn Money with Zkootie
                            </Typography>
                            <Typography  sx={{ marginBottom: {xs:'8px',sm:'8px',md:'16px',lg:'32px'} }}>
                                Deliver locally to earn money. Set up your own schedule and deliver as little or as much
                                as you want. Easy registration in simple steps.
                            </Typography>
                            <HoverUnderlineTypography
                                onClick={() =>
                                    history.push({ pathname: 'about/earn'})
                                }
                            >
                                Learn More
                            </HoverUnderlineTypography>
                        </CustomCard>
                    </Grid>
                </Grid>
            </Container>
        </section>
    );
}

export default HomeBottom;
