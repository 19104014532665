import React from 'react';
import { makeStyles } from '@mui/styles';
import { RiRefundLine, RiNavigationLine, RiFlashlightLine, RiTruckLine } from 'react-icons/ri';
import Grid from '@mui/material/Grid';
import BusinessDetailsIcons from "./BusinessDetailsIcons";

const useStyles = makeStyles(theme => ({
    bodyTitle: {
        fontSize: 28,
        fontWeight: 500,
        display: 'flex',
        justifyContent: 'center',
        marginTop: theme.spacing(4),
        marginLeft: '15%',
        marginRight: '15%',
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
        },
    },
    rodOverlay: {
        justifyContent: 'center',
        display: 'flex',
    },
    rod: {
        width: '50px',
        height: '2px',
        backgroundColor: theme.palette.secondary.main,
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(2),
    },
    headerText: {
        marginLeft: '15%',
        marginRight: '15%',
        // marginBottom: theme.spacing(2),
        textAlign:'justify',
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10%',
            marginRight: '10%',
        },
        [theme.breakpoints.down('xs')]: {
            marginLeft: '5%',
            marginRight: '5%',
        },
    },
    grid: {
        marginLeft: '15%',
        marginRight: '15%',
        marginBottom: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10%',
            marginRight: '10%',
        },
        [theme.breakpoints.down('xs')]: {
            marginLeft: '5%',
            marginRight: '5%',
        },
    },
}));

const BusinessDetails = () => {
    const classes = useStyles();
    return(
        <div>
            <div className={classes.bodyTitle}>
                How we can accelerate the growth of your business
            </div>
            <div className={classes.rodOverlay}>
                <div className={classes.rod}></div>
            </div>

            <p className={classes.headerText}>
                Getting your products to your customers better and faster.
            </p>

            <p className={classes.headerText} >
                Shipping is critical to any business and speed is one of the most important aspects of online shopping.{' '}
                <a
                    style={{ color: 'inherit' }}
                    href="https://www.hollingsworthllc.com/how-late-deliveries-impact-customer-retention/"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Research
                </a>{' '}
                shows that 69% of customers are unlikely to repeat purchase if an item they bought was not delivered within two days.
                <span>
                    {' '}
                    A misplaced, damaged or, late delivery can have a major impact on your future sales even when it is beyond your control.
                </span>
            </p>

            <p className={classes.headerText} >
                Here at Zkootie, we have speed and reliability built into our DNA. Our smart route planner and efficient distribution system
                ensures that your parcels are delivered faster and more securely. Moreover, our pricing tiers do not differentiate
                between delivery speeds. Just like a commercial flight all parcels, arrive at the destination at the same time, regardless
                of the price. Each individual parcel is tracked in real-time so that you and your customers know exactly where the parcel is and
                when it will be delivered.
            </p>
            <div className={classes.grid}>
            <Grid container spacing={2} >
                <Grid item xs={12} sm={6} md={3}>
                    <BusinessDetailsIcons
                        IconName={RiRefundLine}
                        heading={'Returns'}
                        body={`Qualified business partners can avail our free returns policy. We will take care of returns at no extra cost so 
                        that your customers can shop with confidence.`}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <BusinessDetailsIcons
                        IconName={RiTruckLine}
                        heading={'Smart Routing'}
                        body={`Our smart AI route planner ensures that the parcel is delivered within the shortest possible time. Route
                        optimisation allows us to minimise the distance our drivers have to drive, and we can pass on the savings to you.`}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <BusinessDetailsIcons
                        IconName={RiFlashlightLine}
                        heading={'Fast Deliveries'}
                        body={`All deliveries are eligible for same day or next day delivery as standard at no extra cost. Insurance and other add-ons are also available`}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <BusinessDetailsIcons
                        IconName={RiNavigationLine}
                        heading={'Parcel Tracking'}
                        body={`Say goodbye to delivery anxiety. Know exactly where your parcel is in real-time and when it will be delivered.
                        Customers can change the delivery slot or request a secure delivery to a collection point.`}
                    />
                </Grid>
            </Grid>
            </div>
        </div>
    )
}

export default BusinessDetails;
