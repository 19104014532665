import { PayPalScriptProvider } from "@paypal/react-paypal-js";

import { AuthProvider } from './AuthContext';
import { NavProvider } from './NavContext';
import { GoogleMapViewProvider } from './GoogleMapViewContext';
import { PackageProvider } from './PackageContext';
import { PaymentProvider } from './PaymentContext';
import { TrackParcelProvider } from './TrackParcelContext';
import { PAYPAL_OPTIONS } from '../AppConstants';

function RootContext(props) {
    return (
        <AuthProvider>
            <PayPalScriptProvider 
                options={PAYPAL_OPTIONS}
                deferLoading={true}
            >
                <NavProvider>
                    <GoogleMapViewProvider>
                        <PaymentProvider>
                            <PackageProvider>
                                <TrackParcelProvider>
                                    {props.children}
                                </TrackParcelProvider>
                            </PackageProvider>
                        </PaymentProvider>
                    </GoogleMapViewProvider>
                </NavProvider>
            </PayPalScriptProvider>
        </AuthProvider>
    );
}

export default RootContext;
