import React, {useEffect} from 'react';
import { useLocation } from 'react-router-dom';

import TopHeading from "./TopHeading";
import BusinessDetails from "./BusinessDetails";
import BusinessDetailsFAQ from "./BusinessDetailsFAQ";

const AboutBusiness = _ => {
    const { pathname, hash } = useLocation();

    useEffect(() => {
        if (!hash) window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <>
        <TopHeading />
        <BusinessDetails />
        <BusinessDetailsFAQ />
        </>
    );
};
export default AboutBusiness;
