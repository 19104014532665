import {FcGoogle as GoogleIcon} from 'react-icons/fc';
// import { BsTwitter as TwitterIcon } from 'react-icons/bs';
import {BsFacebook as FacebookIcon} from 'react-icons/bs';
// import {ReactComponent as CdPackageImage} from './images/CD.svg'
// import {ReactComponent as SmallPackageImage} from './images/Small.svg'
// import {ReactComponent as MediumPackageImage} from './images/medium.svg'
// import {ReactComponent as LargePackageImage} from './images/Large.svg'
import cdPackageImage from './images/CD.svg';
import smallPackageImage from './images/Small.svg';
import mediumPackageImage from './images/medium.svg';
import largePackageImage from './images/Large.svg';
import paypalImage from './images/PayPal.svg'

//@formatter:off
export const WEIGHT_OPTIONS = [
    {index: 0, key: "0 - 500 grams" },
    {index: 1, key: "0.5 - 2 kilos" },
    {index: 2, key: "2 - 5 kilos"   },
    {index: 3, key: "5 - 15 kilos"  }
]

export const PACKAGE_OPTIONS = [
    {index: 0, key: "Letter or DVD Box", packageImage: <img style={{height: "inherit", maxHeight: 'inherit'}} src={cdPackageImage}     alt="cdPackageImage" />     , dimension: "25cm x 35cm x 5cm" },
    {index: 1, key: "Small Box"        , packageImage: <img style={{height: "inherit", maxHeight: 'inherit'}} src={smallPackageImage}  alt="smallPackageImage" />  , dimension: "30cm x 40cm x 25cm"},
    {index: 2, key: "Medium Box"       , packageImage: <img style={{height: "inherit", maxHeight: 'inherit'}} src={mediumPackageImage} alt="mediumPackageImage" /> , dimension: "35cm x 45cm x 35cm"},
    {index: 3, key: "Large Box"        , packageImage: <img style={{height: "inherit", maxHeight: 'inherit'}} src={largePackageImage}  alt="largePackageImage" />  , dimension: "45cm x 60cm x 45cm"},
]

export const SOCIAL_LOGINS = [
    {id: 1, provider : 'GOOGLE'   , signInText: "Continue with Google"   , signUpText: "Continue with Google"   , color : '#176BEF' , icon:  <GoogleIcon/>},
    {id: 2, provider : 'FACEBOOK' , signInText: "Continue with Facebook" , signUpText: "Continue with Facebook" , color : '#4267B2', icon:  <FacebookIcon color={"#1877F2"}/>},
    // {id: 3, provider : 'TWITTER'  , signInText: "Sign in with Twitter"  , signUpText: "Sign up with Twitter"  , color : '#1D9BF0', icon:  <TwitterIcon color={"#1D9BF0"}/>}
]

export const DELIVERY_TYPE = [
    {id: 'standard'   , title:'Standard Delivery', text : [{ key:0, content:'Items are not insured' },{key:1,content:'Real time tracking not available'},{ key:2, content:'Standard picture proof'}]},
    {id: 'peaceOfMind', title:'Peace of mind'    , text : [{ key:0, content:'Insurance of upto £100'},{key:1,content:'Real time tracking included'     },{ key:2, content:'Detailed picture proof'}]},
]

export const FUNCTION_STATUS={
    ERROR:'ERROR',
    WARNING:'WARNING',
    OK:'OK'
}

export const PAYPAL_OPTIONS={
    "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
    "intent": "capture",
    "currency": "GBP",
}

export const LOCALE_OPTIONS={
    "currency": "GBP",
    "language": "en-uk",
}

export const PAYPAL_IMAGES=[
    {index: 0, key: "paypal-image", image: <img style={{height: "24px", maxHeight: 'inherit'}} src={paypalImage} alt="paypalImage" />},
]

export const SETTINGS_MENU_TEXT=[
    {index: 0, primary:"Profile"     , secondary:"Update your name, phone number and other personal information" },
    {index: 1, primary:"Account"     , secondary:"Change password, verify account and link social media accounts" },
    {index: 2, primary:"App Settings", secondary:"Change currency, local settings" },
]

export const MONTHS_OF_YEAR = [
    {label:'January'    ,value:'01'},
    {label:'February'   ,value:'02'},
    {label:'March'      ,value:'03'},
    {label:'April'      ,value:'04'},
    {label:'May'        ,value:'05'},
    {label:'June'       ,value:'06'},
    {label:'July'       ,value:'07'},
    {label:'August'     ,value:'08'},
    {label:'September'  ,value:'09'},
    {label:'October'    ,value:'10'},
    {label:'November'   ,value:'11'},
    {label:'December'   ,value:'12'},
];

export const ORDER_HISTORY_BUTTONS = [
    {index: 0, text: 'Last 6 months'             , period: 183},
    {index: 1, text: 'Last 1 year'               , period: 366},
    {index: 2, text: 'Last 2 years'              , period: 731},
    {index: 3, text: 'From the beginning of time', period: 0},
]

//@formatter:on
