import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';

function NavButton(props) {
    let { children, inverted, ...rest } = props;
    inverted = inverted || false;

    const theme = useTheme();
    const isSm = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Button
            {...rest}
            color={inverted ? 'secondary' : 'primary'}
            sx={{
                minWidth: {
                    sm: 0,
                },
                borderRadius: '20px',
                margin: '0 4px',
            }}
            size={isSm ? 'small' : 'large'}
            variant="contained"
            disableElevation
        >
            {children}
        </Button>
    );
}

export default NavButton;
