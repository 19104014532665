import { createTheme, responsiveFontSizes } from '@mui/material/styles';

const themeConfig = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1280,
            xl: 1920,
        },
    },
    typography: {
        fontFamily: 'Montserrat',
        button: {
            textTransform: 'none',
        },
    },
    palette: {
        text: { hint: 'rgba(0, 0, 0, 0.38)',
        primary:'rgb(40,40,41)' 
        },
        background: {
            default: '#FFFFFF',
        },
        primary: {
            main: '#0240AD',
        },
        secondary: {
            main: '#00BA4B',
            contrastText: 'white',
            light:'#757de8',
        },
        disabled:{
            main:'#E3E3E3',
            dark:'#BFBFBF'
        },
        facebook:{
            main:'#4267B2',
        },
        google:{
            main:'#176BEF',
        }
    },
    overrides: {
        MuiButton: {
            root: {
                borderRadius: 5,
            },
        },
    },
    themeName: 'zkootie theme',
});

export default responsiveFontSizes(themeConfig);
