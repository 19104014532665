export const actionCodeSettings = {
    handleCodeInApp: true,
    url: process.env.REACT_APP_FIREBASE_ACTION_CODE_URL,
    android: {
        installApp: true,
        packageName: 'com.zkootie',
    },
    iOS: {
        bundleId: 'com.zkootie.app',
    },
    dynamicLinkDomain: process.env.REACT_APP_FIREBASE_DYNAMIC_URL,
};

