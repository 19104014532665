import checkValidEmail from './checkValidEmail';
import checkPassword from './checkPassword';
import addOpacityToHex from './addOpacityToHex';
import parseURL from './parseURL';
import firebaseCodeToMessage from './firebaseCodeToMessage';
import {convertDate, convertDateTime, dateIsValid} from "./dates";
import {capitalise, titleCase} from "./strings";

const convertParcelStatus = (status) =>{
    let response;
    switch (status.toUpperCase()){
        case 'AWAITING_PICKUP':
            response={
                status:'Awaiting pickup',
                primaryText:`Your parcel is awaiting pickup`,
                // primaryText:`Your parcel is awaiting pickup${pickupDate ? ` and will be picked up on ${pickupDate}` : `` }${pickupTime ? ` between ${pickupTime}` :`` }`
            }
            break;
        case 'DELIVERED':
            response={
                status:'Delivered',
                primaryText:`Your parcel has been delivered`,
            }
            break;  
        case 'CANCELLED':
            response={
                status:'Cancelled',
                primaryText:`You cancelled your order`,
            }
            break;  
        default:
            response={
                status:'Awaiting pickup',
                primaryText:`Your parcel is awaiting pickup`,
            }
    }
    return response
}

export { 
    checkValidEmail, 
    checkPassword, 
    addOpacityToHex, 
    parseURL, 
    firebaseCodeToMessage, 
    convertDate, 
    convertDateTime,
    dateIsValid,
    capitalise, 
    titleCase,
    convertParcelStatus, 
};
