import React, { useContext, useEffect, useState } from 'react';

import Alert from '@mui/material/Alert';
import LinearProgress from '@mui/material/LinearProgress';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
// import Modal from '@mui/material/Modal';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
// import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import { NavContext } from '../../context/NavContext';
import { AuthContext } from '../../context/AuthContext';
import LoginForm from './LoginForm';
import SignUpForm from './SignUpForm';
import ForgotPasswordForm from './ForgotPasswordForm';
import { SOCIAL_LOGINS } from '../../AppConstants';
// import ZkootieLogo from '../../images/zkootielogo.svg';


function AuthModal(props) {
    const { loginWithGoogle, loginWithFacebook, socialAuthError, isSocialAuthInProgress, isAuthInProgress } =
        useContext(AuthContext);
    const { loginModalOpen, setLoginModalOpen } = useContext(NavContext);
    const [formType, setFormType] = useState('LOGIN');
    const [socialMediaButtonClicked, setSocialMediaButtonClicked] = useState('');
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        return () => switchToLoginForm();
    }, []);

    const socialLoginHandler = async (e, provider) => {
        e.preventDefault();
        setSocialMediaButtonClicked(provider);

        if (provider === 'GOOGLE') {
            await loginWithGoogle();
        }
        if (provider === 'FACEBOOK') {
            await loginWithFacebook();
        }

        if (socialAuthError.error === '') {
            modalClose();
        }
    };

    // let iSmallWindow = false;
    // let smallWindowSettings = {}

    // useEffect(() => {
    //     function handleResize() {
    //
    //         if (window.innerHeight < 500) {
    //             iSmallWindow = true;
    //             // layout = 'landscape';
    //             smallWindowSettings = {
    //                 fontSize: '0.8rem',
    //             };
    //         }
    //
    //         if (window.innerWidth < 500) {
    //             iSmallWindow = true;
    //             // layout = 'portrait';
    //             smallWindowSettings = {
    //                 fontSize: '0.8rem',
    //             };
    //         }
    //
    //     }
    //
    //     window.addEventListener('resize', handleResize)
    //
    //     return () => window.removeEventListener('resize', handleResize)
    // })

    const switchToSignupForm = () => {
        setFormType('SIGNUP');
    };
    const switchToLoginForm = () => {
        setFormType('LOGIN');
    };
    const switchToForgotPasswordForm = () => {
        setFormType('FORGOT');
    };

    const modalClose = () => {
        setLoginModalOpen(false);
    };

    return (
        <Dialog
            open={loginModalOpen}
            disableScrollLock={false}
            onClose={modalClose}
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                overflowY: 'scroll',
            }}
        >
            <Container component={'main'}  maxWidth={isXs ? 'xs' : 'xs'}>
                {/* <Paper
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        padding: isMd ? theme.spacing(2) : theme.spacing(4),
                        borderRadius: '5px',
                        height: 'auto',
                    }}
                > */}
                    <div>
                        {/* <img src={ZkootieLogo} alt={'zkootie logo'} style={{ textColor: 'blue' }}/> */}
                        <Typography 
                            align='center'
                            variant={isXs ? 'body1' : 'h6'}
                            sx={{
                                // paddingBottom: {
                                //     xs:1,
                                //     md:2,
                                //     lg:2,
                                // },
                                paddingTop: {
                                    xs:1,
                                    md:2,
                                    lg:2,
                                }
                            }}
                        >
                            Login to manage your parcels, see proof of delivery or earn money
                        </Typography>
                    </div>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: {
                                xs: 'column',
                                sm: 'column',
                                md: 'column',
                                lg: 'column',
                                xl: 'column',
                            },
                        }}
                    >
                        <>
                            <Box sx={{ flex: 8 }}>
                                {SOCIAL_LOGINS.map(logins => (
                                    <Box key={logins.provider}>
                                        <Button
                                            sx={{
                                                mt: isXs ? 1 : 2,
                                                borderBottomLeftRadius: `${
                                                    isSocialAuthInProgress === logins.provider ? '0' : '5px'
                                                }`,
                                                borderBottomRightRadius: `${
                                                    isSocialAuthInProgress === logins.provider ? '0' : '5px'
                                                }`,
                                            }}
                                            // type="submit"
                                            disabled={isSocialAuthInProgress !== '' || isAuthInProgress}
                                            fullWidth
                                            size={'large'}
                                            variant="outlined"
                                            startIcon={logins.icon}
                                            onClick={e => socialLoginHandler(e, logins.provider)}
                                            // className={clsx(classes.socialLoginButton, iSmallWindow && smallWindowSettings)}
                                        >
                                            {formType === 'LOGIN' ? logins.signInText : logins.signUpText}
                                        </Button>
                                        {isSocialAuthInProgress === logins.provider ? (
                                            <LinearProgress
                                                sx={{
                                                    borderBottomLeftRadius: `${
                                                        isSocialAuthInProgress === logins.provider ? '5px' : '0'
                                                    }`,
                                                    borderBottomRightRadius: `${
                                                        isSocialAuthInProgress === logins.provider ? '5px' : '0'
                                                    }`,
                                                }}
                                            />
                                        ) : ( socialMediaButtonClicked=== logins.provider && socialAuthError.error!== '' 
                                            ? <Alert sx={{mt:1}} onClose={() => setSocialMediaButtonClicked('')} severity="error">{socialAuthError.message}</Alert>
                                            : <Box style={{ marginTop: '4px' }} />
                                        )}
                                    </Box>
                                ))}
                            </Box>
                            <Box
                                sx={{
                                    flex: 1,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems:'center',
                                    justifyContent: 'center',
                                    width:'100%',
                                    paddingBottom:{
                                        md:'8px', 
                                        lg:'12px',
                                    },
                                    paddingTop:{
                                        md:'8px', 
                                        lg:'12px',
                                    }
                                }}>
                                    <Divider orientation="horizontal"  style={{marginRight:'8px', height:'3px', width:'50px'}}/>
                                    <Typography>OR</Typography>
                                    <Divider orientation="horizontal"  style={{marginLeft:'8px', height:'3px', width:'50px'}}/>
                                </Box>
                                

                                {/* {isXs ? <Divider orientation="horizontal" flexItem style={{marginTop:'8px', marginBottom:'8px'}}/> :  <Divider orientation="vertical" variant="middle" />} */}
                            </Box>
                        </>
                        <Box
                            sx={{
                                flex: 8,
                                paddingBottom:{
                                    xs:1,
                                    md:2,
                                    lg:2,
                                }
                                // marginLeft: {
                                //     xs: 0,
                                //     sm: 0,
                                //     md: '20px',
                                //     lg: '20px',
                                //     xl: '20px',
                                // },
                            }}
                        >
                            {formType === 'LOGIN' && (
                                <LoginForm
                                    switchToForgotPasswordForm={switchToForgotPasswordForm}
                                    switchToSignupForm={switchToSignupForm}
                                />
                            )}
                            {formType === 'SIGNUP' && (
                                <SignUpForm 
                                    switchToLoginForm={switchToLoginForm} 
                                    switchToForgotPasswordForm={switchToForgotPasswordForm}
                                />
                            )}
                            {formType === 'FORGOT' && (
                                <ForgotPasswordForm
                                    switchToLoginForm={switchToLoginForm}
                                    switchToSignupForm={switchToSignupForm}
                                />
                            )}
                        </Box>
                    </Box>
                {/* </Paper> */}
            </Container>
        </Dialog>
    );
}

export default AuthModal;
