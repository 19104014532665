import { createContext, useState } from 'react';

const NavContext = createContext();

const { Provider } = NavContext;

const NavProvider = ({ children }) => {
    const [burgerIconOpen, setBurgerIconOpen] = useState(false);
    const [loginModalOpen, setLoginModalOpen] = useState(false);

    return (
        <Provider value={{ burgerIconOpen, setBurgerIconOpen, loginModalOpen, setLoginModalOpen }}>{children}</Provider>
    );
};

export { NavContext, NavProvider };
