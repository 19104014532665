import React, { useRef } from 'react';
import TextField from '@mui/material/TextField';

function ParcelPostcodeInputField({
    label,
    errorText,
    selectedPostcode,
    setSelectedPostcode,
    setFieldBlur,
}) {
    const ref = useRef(null);

    const handleInput = event => {
        let postcode = event.target.value;
        setSelectedPostcode(postcode);
    };

    const transformPostCode = () =>{
        setSelectedPostcode(selectedPostcode.replace(/\s+/g, '').toUpperCase());
        setFieldBlur(true)
    }

    return (
        <TextField
            label={label}
            required
            fullWidth
            variant="outlined"
            margin="normal"
            error={errorText !== ''}
            helperText={errorText}
            ref={ref}
            onFocus={() => setFieldBlur(false)}
            aria-label={label}
            value={selectedPostcode}
            onChange={handleInput}
            InputProps={{
                onBlur:transformPostCode
            }}
        />
    );
}

export default React.memo(ParcelPostcodeInputField);
