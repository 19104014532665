import React, { useEffect, useReducer, useContext } from 'react';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';

import MuiPhoneNumber from 'material-ui-phone-number';
import { PackageContext } from '../../context/PackageContext';
import { AuthContext } from '../../context/AuthContext';
import { checkValidEmail } from '../../utils';

function ParcelAdditionalDetailsTab() {
    const {
        fromName, 
        setFromName,
        fromContactEmail, 
        setFromConactEmail,
        fromContactPhone,
        setFromConactPhone,
        toName, 
        setToName,
        toContactEmail,
        setToContactEmail,
        toContactPhone,
        setToContactPhone,
        deliveryMessage, 
        setDeliveryMessage,
    } = useContext(PackageContext);
    const {
        userAttributes
    } = useContext(AuthContext);
    
    const [{ fromNameError, fromContactEmailError, fromContactEmailMessage, toNameError, 
    toContactEmailError, toContactEmailMessage}, setState] = useReducer(
        (prev, next) => ({ ...prev, ...next }),
        {
            fromNameError:false,
            fromContactEmailError:false,
            fromContactEmailMessage:'',
            toNameError:false,
            toContactEmailError:false,
            toContactEmailMessage:'',
        },
    );

    const verifyName = (type) =>{
        if (type==='from'){
            if (fromName.trim().length<3){
                setState({fromNameError:true});
            }
        }
        if (type==='to'){
            if (toName.trim().length<3){
                setState({toNameError:true});
            }
        }
    }

    const verifyEmail = (type) =>{
        if (type==='from'){
            const res = checkValidEmail(fromContactEmail);
            if (res.type !== 'email') {
                setState({fromContactEmailError:true,fromContactEmailMessage:'Not a valid email'});
            } else if (!res.isValid) {
                setState({fromContactEmailError:true,fromContactEmailMessage:res.message});
            }
        }
        if (type==='to'){
            const res = checkValidEmail(toContactEmail);
            if (res.type !== 'email') {
                setState({toContactEmailError:true,toContactEmailMessage:'Not a valid email'});
            } else if (!res.isValid) {
                setState({toContactEmailError:true,toContactEmailMessage:res.message});
            }
        }
    }

    useEffect(()=>{
        setFromName(userAttributes?.profile?.displayName||'');
        setFromConactEmail(userAttributes?.profile?.email||'');
    },[]);
    
    return (
        <>
            
            {' '}
            <Typography style={{ marginBottom: '8px' }} fontWeight={600}>Your details</Typography>
            <Typography style={{ marginBottom: '16px' }}>
                This helps us to send you parcel updates and contact you if there are any issues.
            </Typography>
            <Stack
                spacing={2}
                direction={{
                    xs: 'column',
                    sm: 'column',
                    md: 'column',
                    lg: 'column',
                    xl: 'column',
                }}
                justifyContent={'space-between'}
                aria-label="package sender details"
                sx={{
                    height: '100%',
                    width: '100%',
                }}
            >
                <TextField 
                    id="sender-name" 
                    name="senderName" 
                    label="Your Name" 
                    variant="outlined" 
                    error={fromNameError}
                    helperText={fromNameError ? 'Name should be at least 3 characters long' : 'At least 3 characters'}
                    value={fromName}
                    onChange={(e)=>setFromName(e.target.value)}
                    onFocus={() => setState({fromNameError:false})}
                    InputProps={{
                        onBlur:()=>verifyName('from')
                    }}
                    fullWidth
                />
                <TextField 
                    id="sender-contact" 
                    name="senderContact" 
                    label="Your Email Address (required)" 
                    variant="outlined" 
                    error={fromContactEmailError}
                    helperText={fromContactEmailError ? fromContactEmailMessage : 'Only letters (a-z A-Z), numbers (0-9), underscore (_), dot (.) and one @ symbol'} 
                    value={fromContactEmail}
                    onChange={(e)=>setFromConactEmail(e.target.value)}
                    onFocus={() => setState({fromContactEmailError:false})}
                    InputProps={{
                        onBlur:()=>verifyEmail('from')
                    }}
                    fullWidth
                />
                <MuiPhoneNumber
                    defaultCountry={'gb'}
                    id="sender-phone" 
                    label="Your Phone Number (optional)" 
                    variant="outlined" 
                    value={fromContactPhone}
                    onChange={(value)=>setFromConactPhone(value)}
                    inputProps={{
                        name:"senderPhone" 
                    }}
                    fullWidth
                    disableAreaCodes={true}
                />
            </Stack>
            <Divider style={{ margin: '20px 0' }} />

            <Typography style={{ marginBottom: '8px' }} fontWeight={600}>Their details</Typography>
            <Typography style={{ marginBottom: '16px' }}>
               Name and contact details of the person receiving the parcel.
            </Typography>
            <Stack
                spacing={2}
                direction={{
                    xs: 'column',
                    sm: 'column',
                    md: 'column',
                    lg: 'column',
                    xl: 'column',
                }}
                justifyContent={'space-between'}
                aria-label="package sender details"
                sx={{
                    height: '100%',
                    width: '100%',
                }}
            >
                <TextField 
                    id="receiver-name" 
                    name="receiverName" 
                    label="Their Name" 
                    variant="outlined"
                    error={toNameError}
                    helperText={toNameError ? 'Name should be at least 3 characters long' : 'At least 3 characters'}
                    value={toName}
                    onChange={(e)=>setToName(e.target.value)}
                    onFocus={() => setState({toNameError:false})}
                    InputProps={{
                        onBlur:()=>verifyName('to')
                    }} 
                    fullWidth
                />
                <TextField 
                    id="receiver-contact"
                    name="receiverContact"  
                    label="Their Email Address (optional)"  
                    variant="outlined" 
                    error={toContactEmailError}
                    helperText={toContactEmailError ? toContactEmailMessage : 'Only letters (a-z A-Z), numbers (0-9), underscore (_), dot (.) and one @ symbol'} 
                    value={toContactEmail}
                    onChange={(e)=>setToContactEmail(e.target.value)}
                    onFocus={() => setState({toContactEmailError:false})}
                    InputProps={{
                        onBlur:()=>verifyEmail('to')
                    }}
                    fullWidth
                />
                <MuiPhoneNumber
                    defaultCountry={'gb'}
                    id="receiver-phone" 
                    label="Their Phone Number (optional)" 
                    variant="outlined" 
                    disableAreaCodes={true}
                    value={toContactPhone}
                    onChange={(value)=>setToContactPhone(value)}                    
                    inputProps={{
                        name:"receiverPhone" 
                    }}
                    fullWidth
                />
            </Stack>
            <Divider style={{ margin: '20px 0' }} />

            <Typography style={{ marginBottom: '16px' }} fontWeight={600}>Any instructions for the driver ?</Typography>
            <Paper
                sx={{
                    marginBottom:4
                }}
            >
                <FormControl fullWidth={true} variant="outlined">
                    <TextField 
                        id="driver-instructions" 
                        name="driverInstructions"
                        label="Driver Instructions" 
                        variant="outlined" 
                        onChange={(e)=>setDeliveryMessage(e.target.value)}
                        value={deliveryMessage}
                        multiline
                        rows={4}
                    />
                </FormControl>
            </Paper>            
        </>
    );
}

export default ParcelAdditionalDetailsTab;
