import { useContext, useEffect, useReducer } from 'react';
import { checkValidEmail } from '../../utils';
import { AuthContext } from '../../context/AuthContext';
// import { NavContext } from '../../context/NavContext';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import ThemedLoadingButton from "../Buttons/ThemedLoadingButton";

function ForgotPasswordForm({ switchToLoginForm, switchToSignupForm }) {
    const {
        forgotPassword,
        isAuthInProgress: forgotPasswordInProgress,
        authError: forgotPasswordError,
        setAuthError,
    } = useContext(AuthContext);

    const [{ emailOrPhone, errors,showAlert }, setState] = useReducer((prev, next) => ({ ...prev, ...next }), {
        emailOrPhone: '',
        showAlert:false,
        errors: {
            emailOrPhone: {isValid:true},
        },
    });

    useEffect(() => {
        setAuthError({error: '', message: ''})
    }, []);

    const onSubmit = async (e) => {
        e.preventDefault();
        setAuthError({error: '', message: ''})
        const emailPhoneValidation = checkValidEmail(emailOrPhone);
        // only allow email signups
        if (emailPhoneValidation?.type !== 'email'){
            emailPhoneValidation.message = 'Invalid email address';
            emailPhoneValidation.isValid = false;
        }
        setState({
            loading:true,
            showAlert:false,
            errors: {
                emailOrPhone: emailPhoneValidation,
            },
        });

        if (emailPhoneValidation.isValid) {
            try{
                const res = await forgotPassword(emailOrPhone);
                if (res){
                    setState({
                        showAlert:true
                    })
                    setTimeout(() => {
                        setState({
                            showAlert:false
                        })
                    }, 5000);
                }
            } catch (error){
                console.log(error);
            }
        }
    };
    const changeHandler = e => {
        setState({ [e.target.name]: e.target.value });
    };

    return (
        <form>
            <div style={{ display: 'flex', justifyContent: 'center', paddingBottom: '0px' }}>
                <Typography>Forgot Password</Typography>
            </div>
            <div>
                <TextField
                    margin="normal"
                    autoComplete={'email'}
                    required
                    fullWidth
                    name={'emailOrPhone'}
                    onChange={changeHandler}
                    error={!errors.emailOrPhone?.isValid || forgotPasswordError.error!=='' }
                    variant="outlined"
                    label="Email Address"
                    helperText={!errors.emailOrPhone?.isValid 
                        ? errors.emailOrPhone?.message 
                        : forgotPasswordError.error!=='' 
                            ? forgotPasswordError.message
                            : 'Only include letters (a-z A-Z), numbers (0-9), underscore (_), dot (.) and one @ symbol'
                    }
                />
            </div>
            <ThemedLoadingButton
            margin="normal"
                variant="contained"
                size={'large'}
                sx={{
                    mt: 1,
                }}
                disabled={forgotPasswordInProgress}
                loading={forgotPasswordInProgress}
                onClick={onSubmit}
            >
                Forgot Password
            </ThemedLoadingButton>
            {showAlert && <Alert onClose={() => setState({showAlert:false})} severity={"success"}>Password reset email sent. Please follow the instructions on the email</Alert>}
            <Box
                sx={{
                    display: 'flex',
                    flexDirection:'row',
                    variant:"body2",
                    mb:2,
                    mt:2,
                    justifyContent: 'space-between',
                }}
            >
                <Typography sx={{display:'flex',flex:1}}>
                    <Link
                        href={'#'}
                        variant="button"
                        onClick={e => {
                            e.preventDefault();
                            switchToLoginForm();
                        }}
                    >
                        Login instead
                    </Link>
                </Typography>
                <Typography sx={{display:'flex',flex:1}}>
                    <Link
                        href={'#'}
                        variant="button"
                        onClick={e => {
                            e.preventDefault();
                            switchToSignupForm();
                        }}
                    >
                        New to Zkootie? Create an account
                    </Link>
                </Typography>
            </Box>
        </form>
    );
}

export default ForgotPasswordForm;
