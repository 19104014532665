import React, { useContext } from 'react';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { TrackParcelContext } from "../../context/TrackParcelContext";
import ParcelStatusHeaderMessage from "./ParcelStatusHeaderMessage";
import { titleCase } from "../../utils";
import ParcelStatusBody from './ParcelStatusBody';

const ParcelStatusHeader = props => {
    const { trackingResult } = useContext(TrackParcelContext);
    const { data } = trackingResult;
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.down('sm'));
    return (
        <>
        <Divider sx={{height:'4px',background:'white'}}/>
        <Grid 
            container 
            direction="column"
            sx={{
                background: theme => theme.palette.primary.light,
                minHeight:'80%',
            }}
        >
            <Container maxWidth={isXs ? 'xs' : 'md'}>
                <Grid container direction="column" alignItems="flex-start" sx={{textAlign:'flex-start',paddingTop:theme=>theme.spacing(2),paddingBottom:theme=>theme.spacing(2)}}>
                    { !!data.fromName && <Typography variant="h4" sx={{color:'white'}}>
                        {`Your parcel from ${titleCase(data.fromName)}`}
                    </Typography>  }
                </Grid>
            </Container>

            <Grid container direction="column" alignItems="center">
                <ParcelStatusHeaderMessage />
            </Grid>

            <Grid container direction="column" alignItems="center">
                <ParcelStatusBody />
            </Grid>
        </Grid>
        </>
    );
}
export default ParcelStatusHeader;
