import React, { createContext, useState } from 'react';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { getApp } from 'firebase/app';

import firebaseConfig from '../firebaseConfig';

const TrackParcelContext = createContext();
const { Provider } = TrackParcelContext;

function TrackParcelProvider({ children }) {
    const [parcelId, setParcelId] = useState('');
    const [trackingResult, setTrackingResult] = useState({ status : 'LOADING',message : '',error : false,data:{} });

    const app = getApp();
    const { region } = firebaseConfig;
    const functions = getFunctions(app,region);
    const myParcelDetails = httpsCallable(functions, 'db-trackParcels');
    const cancelPayPalOrder = httpsCallable(functions, 'payment-cancelPayPalOrder');

    const resetTracking = () =>{
        setParcelId('');
        setTrackingResult({ status : 'LOADING',message : '',error : false,data:{} });
    }

    const trackParcel = async (parcelId) => {
        var res;
        try {
            const response = await myParcelDetails({
                parcelId,
            });

            if (response.data.status === 'NO_RECORD') {
                res={
                    status: 'NO_RECORD',
                    message : 'No records found for this parcel number',
                    error : true,
                    data : response.data
                }
            } else if (response.data.status !== 'OK') {
                res={
                        status: 'ERROR',
                        message : 'Oh Snap! Something went wrong. Please try again.',
                        error : true,
                        data : {}
                    }
            } else {
                res={
                    status: 'OK',
                    message : '',
                    error : false,
                    data : response.data
                };
            }
        } catch (error) {
            console.log(error);
            res={
                status: 'ERROR',
                message : 'Oh Snap! Something went wrong. Please try again.',
                error : true,
                data : {}
            };
        } finally{
            setTrackingResult(res);
        }
        return res;
    }

    const cancelOrder = async (parcelId) => {
        var res;
        try {
            const response = await cancelPayPalOrder({
                parcelId,
            });

            if (response.data.status === 'NOT OK') {
                res={
                    error : true,
                    message : response.data.message
                }
            } else {
                /* order cancelled - get the latest state */
                await trackParcel(parcelId)
                res={
                    message : 'Order cancelled',
                    error : false,
                };
            }
        } catch (error) {
            console.log(error);
            res={
                message : 'Oh Snap! Something went wrong. Please try again.',
                error : true,
            };
        }
        return res;
    }

    const value = {
        parcelId,
        setParcelId,
        trackingResult,
        setTrackingResult,
        trackParcel,
        resetTracking,
        cancelOrder,
    };

    return <Provider value={value}>{children}</Provider>;
}

export { TrackParcelContext, TrackParcelProvider };
