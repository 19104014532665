import React, { createContext, useState } from 'react';
import { PACKAGE_OPTIONS, WEIGHT_OPTIONS } from '../AppConstants';

const PackageContext = createContext();

const { Provider } = PackageContext;

function PackageProvider({ children }) {
    const [parcelId, setParcelId] = useState('');
    const [fromSelectedPostcode, setFromSelectedPostcode] = useState('');
    const [fromPostcodeBlur, setFromPostcodeBlur] = useState(null);
    const [toSelectedPostcode, setToSelectedPostcode] = useState('');
    const [toPostcodeBlur, setToPostcodeBlur] = useState(null);
    const [weight, setWeight] = useState(() => Number.parseInt(localStorage.getItem('packageWeight')) || WEIGHT_OPTIONS[0].index);

    const [priceResult, setPriceResult] = useState(() => localStorage.getItem('setPriceResult') || {});

    const [volumeKey, setVolumeKey] = useState(PACKAGE_OPTIONS[0].index);
    const [selectedVolumeCost, setSelectedVolumeCost] = useState(0);
    const [selectedDeliveryType, setSelectedDeliveryType] = useState('standard');
    const [pickupDate, setPickupDate] = useState('');
    const [pickupTime, setPickupTime] = useState('');
    const [totalPrice, setTotalPrice] = useState(0);
    const [fromAddress, setFromAddress] = useState('');
    const [fromName, setFromName] = useState('');
    const [fromContactEmail, setFromConactEmail] = useState('');
    const [fromContactPhone, setFromConactPhone] = useState('');
    const [toAddress, setToAddress] = useState('');
    const [toName, setToName] = useState('');
    const [toContactEmail, setToContactEmail] = useState('');
    const [toContactPhone, setToContactPhone] = useState('');
    const [deliveryMessage, setDeliveryMessage] = useState('');
    
    const value = {
        parcelId,
        setParcelId,
        fromSelectedPostcode,
        setFromSelectedPostcode,
        fromPostcodeBlur,
        setFromPostcodeBlur,
        toSelectedPostcode,
        setToSelectedPostcode,
        toPostcodeBlur,
        setToPostcodeBlur,
        weight,
        setWeight: e => {
            setWeight(e);
            localStorage.setItem('packageWeight', e);
        },
        priceResult,
        setPriceResult: e => {
            setPriceResult(e);
            localStorage.setItem('setPriceResult', e);
        },
        volumeKey,
        setVolumeKey,
        selectedVolumeCost,
        setSelectedVolumeCost,
        selectedDeliveryType,
        setSelectedDeliveryType,
        pickupDate,
        setPickupDate,
        pickupTime,
        setPickupTime,
        fromAddress, 
        setFromAddress,
        fromName, 
        setFromName,
        fromContactEmail, 
        setFromConactEmail,
        fromContactPhone,
        setFromConactPhone,
        toAddress, 
        setToAddress,
        toName, 
        setToName,
        toContactEmail,
        setToContactEmail,
        toContactPhone,
        setToContactPhone,
        totalPrice, 
        setTotalPrice,
        deliveryMessage, 
        setDeliveryMessage,
    };

    return <Provider value={value}>{children}</Provider>;
}

export { PackageContext, PackageProvider };
