import React, { useContext } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
// import Divider from '@mui/material/Divider';
import ImageGallery from 'react-image-gallery';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import { TrackParcelContext } from "../../context/TrackParcelContext";
import 'react-image-gallery/styles/css/image-gallery.css';

const ParcelStatusBody = props => {
    const { trackingResult } = useContext(TrackParcelContext);
    const { proofOfDeliveryURLs, taggedLocation } = trackingResult.data;
    const images = proofOfDeliveryURLs?.map(elm=>({original: elm,thumbnail:elm })) ||[];
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.down('sm'));

    if (proofOfDeliveryURLs || taggedLocation){

        return (
            <>
            <Container maxWidth={isXs ? 'xs' : 'md'}>
                <Grid container direction="column" alignItems="flex-start" sx={{textAlign:'flex-start',paddingTop:theme=>theme.spacing(2),paddingBottom:theme=>theme.spacing(2)}}>
                    <Typography variant="h4" sx={{color:'white'}}> Your delivery proof</Typography>  
                </Grid>
                <Box 
                    sx={{
                        display: 'flex',
                        flexDirection:'column',
                        // flexDirection: {
                        //     xs: 'column',
                        //     sm: 'row',
                        // },
                        background: 'white',
                        justifyContent: 'center',
                        borderRadius:1,
                        p:'8px',
                        mb:'16px',
                    }}
                >
                    { taggedLocation && <>
                        <Typography variant="h5" sx={{p:1,fontWeight:600}}>
                            {'Verified GPS delivery location'}
                        </Typography>
                        <img 
                            src={`https://maps.googleapis.com/maps/api/staticmap?size=600x300&markers=color:0x00BA4B|${taggedLocation.latitude},${taggedLocation.longitude}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`}
                            alt={'Delivery location'}
                            style={{
                                padding:`0 ${theme.spacing(1)}`
                            }}
                        />
                    </>}
                    { proofOfDeliveryURLs?.length>0 && <>
                        <Typography variant="h5" sx={{p:1,fontWeight:600}}>
                            {'Proof of delivery'}
                        </Typography>
                        <ImageGallery
                            items={images}
                            thumbnailPosition={'left'}
                            showPlayButton={false}
                            showBullets={true}
                        />
                    </>}
                    
                    {/* <Box sx={{ flex: 4 }}> */}
                        {/* <Typography variant="h5" sx={{p:1,fontWeight:600}}>
                            {parcelStatus}
                        </Typography>
                        <Typography variant="body1" sx={{paddingLeft:1,paddingRight:1,paddingBottom:1}}>
                            {parcelStatusText}
                        </Typography>
                        <Typography variant="body1" sx={{paddingLeft:1,paddingRight:1, fontWeight:600}}>
                            Tracking number
                        </Typography>
                        <Typography sx={{paddingLeft:1}}>{id}</Typography>
                        <Typography variant="body1" sx={{paddingLeft:1,paddingTop:1,paddingRight:1, fontWeight:600}}>
                            {parcelDates.label}
                        </Typography>
                        <Typography sx={{paddingLeft:1,paddingRight:1,paddingBottom:1}}>{convertDateTime(new Date(parcelDates.value._seconds * 1000))}</Typography> */}

                    {/* </Box>
                    <Box
                        sx={{
                            flex: 1,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        {isXs 
                        ? <Divider orientation="horizontal" flexItem /> 
                        :  <Divider orientation="vertical" variant="middle"/>}
                    </Box>
                    <Box sx={{ flex: 4 }}> */}
                        {/* <Typography variant="h5" sx={{p:1,fontWeight:600}}>Delivery Address</Typography>
                        <Typography variant="body1" sx={{paddingLeft:1,paddingRight:1, fontWeight:500}}>{titleCase(toName)}</Typography>
                        <Typography variant="body1" sx={{paddingLeft:1,paddingRight:1,paddingBottom:1}}>{titleCase(toAddress)}</Typography> */}
                        
                    {/* </Box> */}
                    
                    
                </Box>
            </Container>
            </>
        );
    }

    return(
        <></>
    )
}
export default ParcelStatusBody;
