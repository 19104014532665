function currentLocationLatLng(options) {
    return new Promise((resolve, reject) => navigator.geolocation.getCurrentPosition(resolve, reject, options));
}

const libraries = ['places'];
// ({
//     lat: position.coords.latitude,
//     lng: position.coords.longitude,
// })
export { libraries, currentLocationLatLng };
